import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Component({
  template: ''
})
export abstract class MultiselectBase {
  @Output() selectedOptions: EventEmitter<any[]> = new EventEmitter<any[]>();
  public values: SelectItem[];
  public _selectedValues: any[];

  private _selectAll = true;

  constructor() { }

  public set selectedValues(newValues: any[]) {
    this._selectedValues = newValues;
    this.selectedOptions.emit(newValues);
  }

  public get selectedValues(): any[] {
    return this._selectedValues;
  }

  @Input()
  public set options(newValues: any[]) {
    if (!newValues) {
      this.selectedValues = [];
      this.values = undefined;
    } else {
      this.values = newValues.map(item => ({
        value: item,
        label: item.toString()
      } as SelectItem));
      this.selectedValues = this._selectAll ? newValues : [];
    }
  }

  @Input()
  public set selectAll(newValue: boolean) {
    this._selectAll = newValue;
  }
}
