export enum HierarchyLevel {
  AllPlans = 'All Plans',
  SinglePlan = 'Single Plan',
  ContributionGroup = 'Contribution Group',
  AssetGroup = 'Asset Group'
}

export function toHierarchyLevelLabel(level: HierarchyLevel): string {
  switch (level) {
    case HierarchyLevel.AssetGroup:
      return 'Select Asset Group: ';
    case HierarchyLevel.ContributionGroup:
      return 'Select Contribution Group: ';
    case HierarchyLevel.SinglePlan:
      return 'Select Plan: ';
    case HierarchyLevel.AllPlans:
    default:
      return '';
  }
}
