import { Injectable } from '@angular/core';
import { FastTransaction } from './fast-transaction';

@Injectable({
  providedIn: 'root'
})
export class FastTransactionMapper {

  public toFastTransactionResponse(apiResponse: any): FastTransaction[] {
    const transactionList: FastTransaction[] = [];

    for (let i = 0; i < apiResponse.fastTransactionList.length; i++) {
        const fastTransaction = {
          transactionId: apiResponse.fastTransactionList[i].transactionId,
          amount: apiResponse.fastTransactionList[i].amount,
          transDate: apiResponse.fastTransactionList[i].transDate,
          createdDate: apiResponse.fastTransactionList[i].createdDate,
          modifiedDate: apiResponse.fastTransactionList[i].modifiedDate,
          transactionType: apiResponse.fastTransactionList[i].transactionType,
          author: apiResponse.fastTransactionList[i].author,
          status: apiResponse.fastTransactionList[i].status,
          approvalStatus: apiResponse.fastTransactionList[i].approvalStatus
        } as FastTransaction;

        transactionList.push(fastTransaction);
    }
    return transactionList;
  }
}
