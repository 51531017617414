<app-header></app-header>

<div [ngSwitch]="true">
    <div *ngSwitchCase="errorCode === 203 || errorCode === 401">
        <section class="svg-page svg-unauthorized-error util-padding-top-20 util-padding-bottom-10">
            <div class="text-center">
                <div>
                    <h1>Pardon me?</h1>
                </div>
                <div>
                    <p>It looks like you don't have permission to view this page. If you think you should have permission, contact
                        your primary administrator.</p>
                </div>
            </div>
        </section>
    </div>
    <div *ngSwitchCase="errorCode === 400 || errorCode === 404">
        <section class="svg-page svg-general-error util-padding-top-20 util-padding-bottom-10">
            <div class="text-center">
                <div>
                    <h1>We’re sorry.</h1>
                    <p>We've looked everywhere and can't seem to find the page you're looking for...</p>
                </div>
            </div>
        </section>
    </div>
    <div *ngSwitchCase="errorCode === 500">
        <section class="svg-page svg-server-error util-padding-top-20 util-padding-bottom-10">
            <div class="text-center">
                <div>
                    <h1>We’re sorry.</h1>
                    <p>It looks like something went wrong on our end. We’ll work on getting that fixed.</p>
                </div>
            </div>
        </section>
    </div>
    <div *ngSwitchDefault>
        <section class="svg-page svg-general-error util-padding-top-20 util-padding-bottom-10">
            <div class="text-center">
                <div>
                    <h1>We’re sorry.</h1>
                    <p>We've looked everywhere and can't seem to find the page you're looking for...</p>
                </div>
            </div>
        </section>
    </div>
  </div>
  <br/><br/><br/><br/><br/><br/><br/><br/>
<app-footer></app-footer>