import {Component} from '@angular/core';
import {MultiselectBase} from '../multiselect.base';

@Component({
  selector: 'app-multiselect-combo-box',
  templateUrl: './multiselect-combo-box.component.html',
  styleUrls: ['./multiselect-combo-box.component.css']
})
export class MultiselectComboBoxComponent extends MultiselectBase {

}
