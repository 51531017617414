import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RestService } from '../core/secured-api/services/rest.service';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';
import { QuietRecordStatus } from '../core/models/quietRecord.enum';

@Injectable({
  providedIn: 'root'
})
export class FastTransactionBreakdownService {

  constructor(private restService: RestService,
    private router: Router,
    private common: CommonService) {
  }

  public getFastTransactionBreakdownDetails(srceTrnsId: string, transDate: string, transType: string, approvalStatus: string): any {
    let approvalStatusEnum: QuietRecordStatus;
    switch (approvalStatus) {
      case 'Pending Approval':
        approvalStatusEnum = QuietRecordStatus.Pending
        break;
      case 'Approved':
        approvalStatusEnum = QuietRecordStatus.Approved
        break;
      case 'Denied':
        approvalStatusEnum = QuietRecordStatus.Denied
        break;
      default:
        approvalStatusEnum = QuietRecordStatus.NotApplicable
        break;
    }

    return this.restService.get<any>(`FAST/transactiondetails/${srceTrnsId}/${transDate}/type/${transType}/approvalStatus/${approvalStatusEnum}`);
  }
}
