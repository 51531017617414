<section class="text-muted footer-row" style="text-align: left;">
    <div>
        <small>
            <p><b>Note:- 1. Participant and Participant balances from Terminated plans and those with $0 balances will not be displayed.</b></p></small>
            <small>
            <p><b style="padding-left: 43px;">2. If you wish to apply Fund level or Money type level filters, it is recommended to apply these filters before entering any values in the table. 
               <br > &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Applying them afterward would result in the table losing the entered values.</b></p></small>
    </div>
</section>

