import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DropdownModule } from 'primeng/dropdown';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { ComboBoxComponent } from './combo-box/combo-box.component';
import { LoadingComponent } from './loading/loading.component';
import { MultiselectComboBoxComponent } from './multiselect/combo-box/multiselect-combo-box.component';
import { MultiselectListboxComponent } from './multiselect/listbox/multiselect-listbox.component';
import { SecuredApiModule } from './secured-api/secured-api.module';
import { RestService } from './secured-api/services/rest.service';
import { reducers } from './store/store';
import { StoreEffects } from './store/store.effects';
import { TransactionDateCalendarComponent } from './calendar/transaction-date-calendar/transaction-date-calendar.component';
import {CalendarModule} from 'primeng/calendar';
import { ModalComponent } from './modal/modal.component';

@NgModule({
  declarations: [
    LoadingComponent,
    ComboBoxComponent,
    MultiselectComboBoxComponent,
    MultiselectListboxComponent,
    TransactionDateCalendarComponent,
    ModalComponent
  ],
  exports: [
    LoadingComponent,
    ComboBoxComponent,
    MultiselectComboBoxComponent,
    MultiselectListboxComponent,
    TransactionDateCalendarComponent,
    ModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MultiSelectModule,
    BrowserAnimationsModule,
    DropdownModule,
    ListboxModule,
    SecuredApiModule,
    EffectsModule.forRoot([StoreEffects]),
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    CalendarModule,
  ],
  providers: [
    RestService
  ]
})
export class CoreModule { }
