<div *ngIf="loadPendingTranAlertComponent">
  <app-pending-tran-alert></app-pending-tran-alert>
</div>
<div *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</div>
<div class="table-responsive" *ngIf="transactionList.length > 0" xmlns="http://www.w3.org/1999/html">
  <div *ngIf="!isLoading" class="main-container">
    <div class="container-fluid">
      <div>
        <div class="previous-tran-search-bar">
          <div class="row">
            <div class="col-md-12 col-lg-3 util-padding-5" id="globalFilter">
              <div class="input-group input-group-sm">
                      <span class="input-group-addon">
                          <i class="fa fa-search util-margin-right-5"></i>
                      </span>
                <input (input)="transactionLedgerTable.filterGlobal($event.target.value, 'contains')"
                      class="form-control" pInputText placeholder="Search" size="50" type="text">
              </div>
            </div>
          </div>
        </div>
          <p-table  #transactionLedgerTable [value]="transactionList" [scrollable]="true"
                scrollHeight="500px" selectionMode="multiple"
                sortMode="multiple" styleClass="p-datatable-striped " [globalFilterFields]="['transactionType','transactionId','amount','transDate', 'createdDate','modifiedDate', 'author', 'status']">
        <ng-template pTemplate="header">
            <tr>
              <th *ngFor="let coll of pColumns  let i = index" [pSortableColumn]="coll.field" [ngStyle]="{'border':'none'}">
                {{coll.header}}
                <i class="fa fa-info-circle" title="Distribution transactions are grouped by TransGroupId. Distributions dated prior to 06-24-2022 are not able to be deleted." *ngIf="i ==0"></i>
                <p-sortIcon [field]="coll.field" ariaLabel="Activate to sort"
                            ariaLabelAsc="Activate to sort in ascending order"
                            ariaLabelDesc="Activate to sort in descending order">
                </p-sortIcon>
              </th>
              <th [ngStyle]="{'border':'none'}"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-transaction>
            <tr>
              <td class="text-left">{{transaction.transactionId}}</td>
              <td class="text-left">{{transaction.amount | currency}}</td>
              <td class="text-left">{{transaction.transDate | date: 'MM/dd/yy'}}</td>
              <td class="text-left">{{transaction.createdDate | date: 'MM/dd/yy&nbsp;&nbsp;hh:mm:ss a'}}</td>
              <td class="text-left">{{transaction.modifiedDate | date: 'MM/dd/yy&nbsp;&nbsp;hh:mm:ss a'}}</td>
              <td class="text-left">{{transaction.transactionType}}</td>
              <td class="text-left">{{transaction.author}}</td>
              <td class="text-left">{{transaction.status}}</td>
              <td class="text-left">{{transaction.approvalStatus}}</td>

              <td [ngStyle]="{'width':'10px', 'alignItems': 'center'}">
                <a class="util-margin-right-20" (click)="populateParticipantData(transaction.transDate, transaction.transactionType, transaction.transactionId, transaction.status, true, transaction.approvalStatus);" *ngIf="!navigateToDetailsPage(transaction.status, transaction.transDate)">
                    <i class="fa fa-2x fa-eye"></i>
                </a>
                <a class="cursor-pointer util-margin-right-20" (click)="populateParticipantData(transaction.transDate, transaction.transactionType, transaction.transactionId, transaction.status, false, transaction.approvalStatus);" routerLink = "details" *ngIf="navigateToDetailsPage(transaction.status, transaction.transDate)">
                    <i class="fa fa-2x fa-eye"></i>
                </a>
                <a class="cursor-pointer util-margin-right-20" id="trashCanIcon" data-toggle="modal" data-target="#deleteModal" (click)="setCurrentTransaction(transaction.transactionId, transaction.transactionType)"  *ngIf="deleteCheck(transaction.status, transaction.transactionType, transaction.transDate)">
                  <i class="fa fa-2x fa-trash "></i>
                </a>
                <a class=" cursor-pointer util-margin-right-20" (click)="storeTransactionInfo(transaction.transDate, transaction.transactionType, transaction.transactionId, transaction.status, false);" *ngIf="deleteCheck(transaction.status, transaction.transactionType, transaction.transDate)" routerLink = "edit">
                  <i class="fa fa-2x fa-pencil-square-o"></i>
              </a>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
<app-modal name="deleteModal" [showClose]="true">
  <h2 header class="modal-title" id="my-modal-label">Delete Confirmation</h2>
  <div body>Are you sure you want to delete the following transaction: {{currentTransaction}}</div>
  <div footer>
    <button type="button" class="btn btn-danger" data-dismiss="modal">NO</button>
    <button type="button" class="btn btn-primary"  data-dismiss="modal" (click)="deleteTransaction()">YES</button>
  </div>
</app-modal>

<app-modal name="saveTransactionFailure" [showClose]="true" [showBody]="false" [showFooter]="false" >
  <div header>          
    <h4><b>Transaction insertion failed. </b></h4>
  </div>
</app-modal>

<app-modal name="saveTransactionEmail" [showClose]="true" [showFooter]="false" >
  <div header>          
    <h4><b> The deductions have been submitted successfully! </b></h4>
  </div>
  <div body>
    <p> An email would be shortly sent to you which will inform you about the final status of the transaction. </p>
  </div>
</app-modal>

<button id="hiddenButtonFailure" data-toggle="modal" data-target="#saveTransactionFailure" style="display: none"></button>
<button id="hiddenButtonEmail" data-toggle="modal" data-target="#saveTransactionEmail" style="display: none"></button>