import { ActionReducerMap, createSelector } from '@ngrx/store';
import { AccountDetail, FundDetail, MoneyTypeDetail } from '../models/company-details';
import { coreReducer } from './core-state/core.reducers';
import { CoreState } from './core-state/core.state';
import { detailsReducer } from './details-state/details.reducer';
import { DetailsState } from './details-state/details.state';

export interface StoreState {
    core: CoreState;
    details: DetailsState;
}

export const reducers: ActionReducerMap<StoreState> = {
    core: coreReducer,
    details: detailsReducer
};

export const selectCore = (state: StoreState) => state.core;
export const selectDetails = (state: StoreState) => state.details;

export const getCompanyName = createSelector(selectCore, (state: CoreState) => state.companyName);
export const getDetails = createSelector(selectDetails, (state: DetailsState) => state.details);
export const getFunds = createSelector(selectDetails, (state: DetailsState) => {
    if (state.details.participants) {
        const funds = state.details.participants
            .reduce((acc, curr) => acc.concat(curr.accounts), [] as AccountDetail[])
            .reduce((acc, curr) => acc.concat(curr.moneyTypes), [] as MoneyTypeDetail[])
            .reduce((acc, curr) => acc.concat(curr.funds), [] as FundDetail[])
            .reduce((acc, curr) => acc.set(curr.id, curr), new Map<number, FundDetail>());

        const uniqueIds = Array.from(new Set(funds.keys()));

        return uniqueIds.map(i => funds.get(i));
    } else {
        return [];
    }
});
