import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
//import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { environment } from '../environments/environment';
import {RunMode} from '../environments/run-modes.enum';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SelectionCriteriaComponent } from './selection-criteria/selection-criteria.component';
import { HeaderComponent } from './themes/header/header.component';
import { FooterComponent } from './themes/footer/footer.component';
import { ErrorComponent } from './core/error/error.component';
import { FastSelectionComponent } from './fast-selection/fast-selection.component';
import { FastDataComponent } from './fast-data/fast-data.component';
import {HttpClientModule} from '@angular/common/http';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { DatePipe } from '@angular/common';
import { BalancesServiceService } from './core/services/balances-service.service';
import { DisclaimerComponent } from './themes/disclaimer/disclaimer.component';
import {CalendarModule} from 'primeng/calendar';
import { LoadingSpinnerComponent } from './core/loading-spinner/loading-spinner.component';
import { TwoDigitDecimaNumberDirective } from './fast-data/TwoDigitDecimaNumberDirective.directive';
import { FiveDigitDecimaNumberDirective } from './fast-data/FiveDecimalNumberDirective.directive';
import {MatSelectModule} from '@angular/material/select';
import { FastTransactionTableComponent} from './fast-transaction-table/fast-transaction-table.component';
import {FastTransactionBreakdown } from './fast-transaction-breakdown/fast-transaction-breakdown.component';
import {TableModule} from 'primeng/table';
import {PendingTranAlertComponent} from './fast-transaction-table/pending-tran-alert.component';
import {LoadDeductionModalComponent} from './fast-data/load-deduction-modal.component';
import {WarningAlertModalComponent} from './fast-data/warning-alert-modal.component';
import { FastDataUpdateComponent } from './fast-data-update/fast-data-update.component';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';

@NgModule({
    declarations: [
        AppComponent,
        SelectionCriteriaComponent,
        HeaderComponent,
        FooterComponent,
        ErrorComponent,
        FastSelectionComponent,
        FastDataComponent,
        DisclaimerComponent,
        LoadingSpinnerComponent,
        TwoDigitDecimaNumberDirective,
        FiveDigitDecimaNumberDirective,
        FastTransactionTableComponent,
        FastTransactionBreakdown,
        PendingTranAlertComponent,
        LoadDeductionModalComponent,
        WarningAlertModalComponent,
        FastDataUpdateComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CalendarModule,
    FormsModule,
    CoreModule,
    RadioButtonModule,
    InputTextModule,
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    NgxWebstorageModule.forRoot(),
    HttpClientModule,
    MatSelectModule,
    TableModule,
    AuthModule.forRoot({
      config: {
        authority: environment.authority,
        redirectUrl: window.location.origin,
        clientId: environment.clientId,
        scope: environment.scope,
        autoUserInfo: false,
        useRefreshToken: true,
        responseType: 'code',
        logLevel: LogLevel.Error,
        disableIatOffsetValidation: true
      },
    }),
  ],
  exports: [FastTransactionTableComponent],
  providers: [DatePipe, BalancesServiceService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
