import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appFiveDigitDecimaNumber]'
})
export class FiveDigitDecimaNumberDirective {
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,5}$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Alt', 'a'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // tslint:disable-next-line:deprecation
    if (((event.keyCode === 65 || event.keyCode === 86 || event.keyCode === 67 || event.keyCode === 88) &&
    (event.ctrlKey === true || event.metaKey === true)) || (this.specialKeys.indexOf(event.key) !== -1)) {
      return;
    }
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(position), event.key === 'Decimal' ? '.' : event.key, current.slice(0, position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
