
export interface CoreState {
    companyId: string;
    companyName: string;
}

export const initialState: CoreState = {
    companyId: null,
    companyName: null
};
