<div>
    <router-outlet></router-outlet>
</div>

<!--<div class="modal fade" id="timeoutModal" >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary modal-alert-action-btn" data-dismiss="modal"> Ok </button>
        </div>
      </div>
    </div>
  </div>
<button id="timeoutModalButton" data-toggle="modal" data-target="#timeoutModal" style="display: none"></button>

-->