import {Component, Input, OnInit} from '@angular/core';
const THREE_MB_FILE = 3145728;
import { Output, EventEmitter } from '@angular/core';
import {read, WorkBook} from 'xlsx';
import {ParticipantData} from './ParticipantData.module';

const HEADER_ROW = 1;
const EMP_ID_COLUMN = 'A';
const ACCOUNT_ID_COLUMN = 'B';
const FUND_ID_COLUMN = 'C';
const ACNT_INFO_COLUMNS: string[] = ['A', 'B', 'C', 'D', 'E', 'F'];
const SHEET_NAME = 'Load Distribution Template';

const BASE_10 = 10;
const ONE_HUNDRED = 100;

const COLUMN_PATTERN: RegExp = /[A-Z][A-Z]*/;
const ROW_PATTERN: RegExp = /[1-9][0-9]*/;
const NUMBER_PATTERN: RegExp = /[0-9]+/;
const MONEY_PATTERN: RegExp = /[0-9]+(\.[0-9][0-9]?)?/;

@Component({
    selector: 'app-load-deduction-modal',
    templateUrl: './load-deduction-modal.component.html',
    styleUrls: [
        './load-deduction-modal.component.scss'
    ]
})
export class LoadDeductionModalComponent  implements OnInit {
  localStore: Storage;
  @Input() effectiveDate: Date;
  @Input() transactionType: string;
  @Input() masterParticipantDataList: ParticipantData[];
  public errors: string[] = [];
  isPosting: boolean;
  isLoading: boolean;
  public file: any;
  private workbook: WorkBook;
  private moneyTypeColumns: string[] = [];
  @Output() notify = new EventEmitter();
  @Output() valueChange = new EventEmitter<ParticipantData[]>();
  private participantData: object = {};
  private validFunds: number[] = [];
  private validMoneyTypeIndexes: number[] = [];
  private loadBalanceData: object;
  private spreadSheetRows: object[] = [];

  ngOnInit(): void {
    this.localStore = window.localStorage;
    this.isPosting = false;
    this.isLoading = false;
  }

  public incomingFile(files: File[]): void {
    this.isLoading = true;
    // this.isPosting = true;
    if (files.length > 0) {
      this.file = files[0];
      if (this.file.size > THREE_MB_FILE) {
        this.errors.push('This file is too large.  The maximum file size is 3MB.');
      } else {
        const fileReader: FileReader = new FileReader();
        fileReader.onload = () => {
          try {
            this.loadBalanceData = this.parse(fileReader.result);
            // @ts-ignore
            this.errors = this.loadBalanceData.errors;
          } catch {
            this.errors.push('There was an error parsing the selected file.  ' +
              'Please make sure you are using the provided template.');
          }
         // this.isLoading = false;
        };
        fileReader.readAsBinaryString(this.file);
      }
    } else {
      this.file = null;
      this.errors = [];
    }
  }

  // tslint:disable-next-line:max-line-length
  public populateDeductionAtFundLevel(row: object): void {
    // this.isPosting = true;
    const keys: string[] = Object.keys(row);
    const empIdKey: string = keys.filter((e) => e.match(COLUMN_PATTERN)[0] === EMP_ID_COLUMN)[0];
    const accountIdKey: string = keys.filter((e) => e.match(COLUMN_PATTERN)[0] === ACCOUNT_ID_COLUMN)[0];
    const fundIdKey: string = keys.filter((e) => e.match(COLUMN_PATTERN)[0] === FUND_ID_COLUMN)[0];
    const moneyTypeKeys: string[] = this.getMoneyTypeKeys(row);

    const empId: number = parseInt(row[empIdKey].v, BASE_10);
    const accountId: number = parseInt(row[accountIdKey].v, BASE_10);
    const fundId: number = parseInt(row[fundIdKey].v, BASE_10);

    this.masterParticipantDataList.forEach(participantData => {
      if (participantData.empId === empId) {
        participantData.accountBalances.forEach(accountData => {
          if (accountData.accountId === accountId) {
             moneyTypeKeys.forEach((moneyTypeKey) => {
               const moneyTypeIndex = this.moneyTypeColumns.indexOf(moneyTypeKey.match(COLUMN_PATTERN)[0]);
               const dollarAmount: number = parseFloat(row[moneyTypeKey].v);
               accountData.moneyTypeBalances.forEach(moneyTypeData => {
                 if (moneyTypeData.moneyTypeId === moneyTypeIndex + 1) {
                   moneyTypeData.fundBalances.forEach(fundBalanceData => {
                     if (fundBalanceData.fundId ===  fundId) {
                       if (parseFloat(fundBalanceData.endCashBal) >= dollarAmount) {
                         fundBalanceData.deduction = dollarAmount;
                       } else {
                         fundBalanceData.deduction = fundBalanceData.endCashBal;
                       }
                     }
                   });
                 }
               });
             });
          }
        });
      }
    });
  }

  private getParticipantData(): void {
    this.masterParticipantDataList.forEach(participantData => {
      this.participantData[participantData.empId] = participantData.accountBalances.map((e) => e.accountId);
      participantData.accountBalances.forEach(accountData => {
        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          moneyTypeData.fundBalances.forEach(fundBalanceData => {
            this.validFunds.push(fundBalanceData.fundId);
          });
          this.validMoneyTypeIndexes.push(moneyTypeData.moneyTypeId);
        });
      });
    });
  }

  public parse(file: string | ArrayBuffer): object {
    this.masterParticipantDataList = (this.masterParticipantDataList!== null) ?
      this.masterParticipantDataList : [];
    this.getParticipantData();

    const loadDeduction: { amount: number, errors: string[], participantDataList: ParticipantData[]; } = {
      amount: 0,
      errors: [],
       participantDataList: []
    };

    try {
      this.workbook = read(file, { type: 'binary' });
    } catch (e) {
      loadDeduction.errors.push('File could not be parsed as an excel document.');
      return loadDeduction;
    }
    const rows: object[] = this.groupBy(
      (obj, key) => key.match(ROW_PATTERN)[0],
      this.filterObjectKeys(
        this.workbook.Sheets[SHEET_NAME],
        (e) => parseInt(e.match(ROW_PATTERN)[0], BASE_10) >= (HEADER_ROW + 1)));
    if (rows.length === 0) {
      loadDeduction.errors.push('There was an error parsing the selected file.  Please make sure you are using the provided template.');
    }
    this.getMoneyTypeColumns();

    for (const row of rows) {
      this.processRow(loadDeduction, row);
      // this.populateDeductionAtFundLevel(loadDeduction, row);
    }

    this.spreadSheetRows = rows;
    return loadDeduction;
  }

  public populateDeductionsAtParticipantLevel() {
    this.isPosting = true;

    for (const row of this.spreadSheetRows) {
      this.populateDeductionAtFundLevel(row);
    }

    this.masterParticipantDataList.forEach(participantData => {
      let acctLevel = 0;
      participantData.accountBalances.forEach(accountData => {
          let mtLevel = 0;

          accountData.moneyTypeBalances.forEach(moneyTypeData => {
                let fundLevel = 0;

                moneyTypeData.fundBalances.forEach(fundBalanceData => {
                    fundLevel = fundLevel + fundBalanceData.deduction;
                  });
                moneyTypeData.deduction = fundLevel;

                mtLevel = mtLevel + moneyTypeData.deduction;
              });
          accountData.deduction = mtLevel;
          acctLevel = acctLevel + accountData.deduction;
        });
      if (acctLevel > 0) {
        participantData.deduction = acctLevel;
      } else {
        participantData.deduction = null;
      }
    });
    this.masterParticipantDataList = this.masterParticipantDataList;
    this.spreadSheetRows = [];
    this.valueChange.emit(this.masterParticipantDataList);
  }

  private getMoneyTypeColumns(): void {
    this.moneyTypeColumns = Object.keys(this.filterObjectKeys(
      this.workbook.Sheets[SHEET_NAME],
      ((e) => parseInt(e.match(ROW_PATTERN)[0], BASE_10) === HEADER_ROW &&
        !ACNT_INFO_COLUMNS.includes(e.match(COLUMN_PATTERN)[0])))).map((e) => e.match(COLUMN_PATTERN)[0]);
  }

  private getMoneyTypeKeys(row: object): string[] {
    return Object.keys(row).filter((e) => this.moneyTypeColumns.includes(e.match(COLUMN_PATTERN)[0]));
  }

  private processRow(loadBalance: { amount: number, errors: string[], participantDataList: ParticipantData[]; }, row: object): void {
    const keys: string[] = Object.keys(row);
    const empIdKey: string = keys.filter((e) => e.match(COLUMN_PATTERN)[0] === EMP_ID_COLUMN)[0];
    const accountIdKey: string = keys.filter((e) => e.match(COLUMN_PATTERN)[0] === ACCOUNT_ID_COLUMN)[0];
    const fundIdKey: string = keys.filter((e) => e.match(COLUMN_PATTERN)[0] === FUND_ID_COLUMN)[0];
    const moneyTypeKeys: string[] = this.getMoneyTypeKeys(row);

    const empId: number = parseInt(row[empIdKey].v, BASE_10);
    const accountId: number = parseInt(row[accountIdKey].v, BASE_10);
    const fundId: number = parseInt(row[fundIdKey].v, BASE_10);

    this.checkEmpId(empId, empIdKey, row, loadBalance);

    if (isNaN(accountId) || !this.matchRegex(NUMBER_PATTERN, row[accountIdKey].v)) {
      loadBalance.errors.push('Error in cell ' + accountIdKey + '.  Expected a number.');
    } else if (!(this.participantData.hasOwnProperty(empId.toString()) && this.participantData[empId.toString()].includes(accountId))) {
      loadBalance.errors.push('Error in cell ' + accountIdKey + '.  Account Id ' + accountId + ' does not belong to this participant.');
    }
    if (isNaN(fundId) || !this.matchRegex(NUMBER_PATTERN, row[fundIdKey].v)) {
      loadBalance.errors.push('Error in cell ' + fundIdKey + '.  Expected a number.');
    } else if (!this.validFunds.includes(fundId)) {
      loadBalance.errors.push('Error in cell ' + fundIdKey + '.  Fund id ' + fundId +
        ' is not available or is closed on the selected effective date.');
    }

    moneyTypeKeys.forEach((moneyTypeKey) => {
      const moneyTypeIndex: number = this.moneyTypeColumns.indexOf(moneyTypeKey.match(COLUMN_PATTERN)[0]);
      const dollarAmount: number = parseFloat(row[moneyTypeKey].v);
      if (isNaN(dollarAmount)) {
        loadBalance.errors.push('Error in cell ' + moneyTypeKey + '.  Dollar values must be a number with exactly two decimal places.');
     // } else if (!this.isContribTransType && !this.matchRegex(MONEY_PATTERN, row[moneyTypeKey].v)) {
      } else if (!this.matchRegex(MONEY_PATTERN, row[moneyTypeKey].v)) {
        loadBalance.errors.push('Error in cell ' + moneyTypeKey + '.  Negative deduction amounts are not allowed. ' +
          'Please correct the deduction amount and re-upload.');
      }

      if (dollarAmount > 0) {
        if (!this.validMoneyTypeIndexes.includes(moneyTypeIndex + 1)) {
          loadBalance.errors.push('Error in cell ' + moneyTypeKey + '.  ' +
            'Money type ' + (moneyTypeIndex + 1) + ' is not valid for this plan.');
        }
      }
    });
  }

  // tslint:disable-next-line:ban-types
  private groupBy(transformer: Function, obj: object): object[] {
    const ret: object = {};
    Object.keys(obj).forEach((key) => {
      try {
        const transformed: string = transformer(obj[key], key);
        if (!ret.hasOwnProperty(transformed)) {
          ret[transformed] = {};
        }
        ret[transformed][key] = obj[key];
      } catch (e) { }
    });
    return Object.values(ret);
  }

  // tslint:disable-next-line:ban-types
  private filterObjectKeys(obj: object, predicate: Function): object {
    const result: object = {};
    // tslint:disable-next-line:forin
    for (const key in obj) {
      try {
        if (predicate(key)) {
          result[key] = obj[key];
        }
      } catch (e) {
      }
    }
    return result;
  }

  private matchRegex(pattern: RegExp, value: any): boolean {
    const strValue: string = value.toString();
    const matched: string[] = strValue.match(pattern);
    return matched.length > 0 && matched[0].length === strValue.length;
  }

  // tslint:disable-next-line:max-line-length
  private checkEmpId(empId: number, empIdKey: string, row: object, loadBalance: { amount: number, errors: string[], participantDataList: ParticipantData[]; }): void {
    if (isNaN(empId) || !this.matchRegex(NUMBER_PATTERN, row[empIdKey].v)) {
      loadBalance.errors.push('Error in cell ' + empIdKey + '.  Expected a number.');
    } else if (!Object.keys(this.participantData).includes(empId.toString())) {
      loadBalance.errors.push('Error in cell ' + empIdKey + '.  Employee Id ' + empId + ' is not in this plan.');
    }
  }
}
