import { Injectable } from '@angular/core';
import { NameId } from '../core/models/name-id';
import { HierarchyLevelDetail } from './criteria-response';

@Injectable({
    providedIn: 'root'
})
export class SelectionCriteriaDetailMapper {
    public toSelectionCriteriaDetail(detail: any): HierarchyLevelDetail {
        return {
            hierarchyLevel: new NameId(detail.name, detail.id),
            years: detail.years,
            autoTradeFlag: detail.autoTradeFlag
        } as HierarchyLevelDetail;
    }
}
