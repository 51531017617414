import { DatePipe } from '@angular/common';
import { Component, OnInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { BalancesServiceService } from '../core/services/balances-service.service';
import { ParticipantData } from './ParticipantData.module';
import { AppComponent } from 'src/app/app.component';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { CommonService } from './../common.service';
import { FundDetails, UpperPanelData } from '../core/models/UpperPanelData.module';
import { UpperPanelService } from '../core/services/upper-panel.service';
import { MoneyType } from './MoneyType.module';
import { AccountData } from './AccountData.module';
import { FundType } from './FundType.module';
import { FastTransactionService } from '../core/services/fast-transaction.service';
import { transpileModule } from 'typescript';

export interface Transaction {
  value: String;
  viewValue: String;
  enabled: boolean;
}

export interface EntryMethod {
  value: String;
  viewValue: String;
  enabled: boolean;
}


@Component({
  selector: 'app-fast-data',
  templateUrl: './fast-data.component.html',
  styleUrls: ['./fast-data.component.css']
})
export class FastDataComponent implements OnInit, AfterViewChecked {
  localStore: Storage;
  transDate: string;
  oldTransDate: string; // this will hold the actual transDate passed from first page
  transDateString: Date; // Since transDate is string type and we get date type value, we can not use one variable. Hence transDateString.
  todaysDate: Date = new Date();
  futureDatedTransaction: boolean;
  selectedOption: string;
  companyName: string;
  error = 'noError';
  companyId: string;
  alertMessage: string;
  participantDatalist: ParticipantData[];
  public maxTransactionDate: Date = new Date();
  public minTransactionDate: Date = new Date();
  percentagePerParticipant: any;
  amountPerParticipant: any;
  amountPerAccount: any;
  totalDeductionAmount: Number = 0;
  totalDeductionWithInterestAmount: Number = 0;
  balanceUpdatedFlag: boolean;
  isValidTransaction = false;
  isValidDeduction = false;
  flagPageLoad = true;
  checkedRadioOption1: boolean;
  checkedRadioOption2: boolean;
  checkedRadioOption3: boolean;
  isDisable = false;
  fileName = 'Print Transaction Sheet.xlsx';
  selectedTransaction;
  oldSelectedEntryMethod: string;
  selectedEntryMethod;
  autoTradeFlag;
  showAutoTradeFlag: boolean;
  transactions: Transaction[] = [
    { value: 'fee-0', viewValue: 'Fee', enabled: true },
    { value: 'balancetransfer-1', viewValue: 'Balance Transfer', enabled: true },
    { value: 'distribution-2', viewValue: 'Distribution', enabled: true },
    { value: 'quietrecord-3', viewValue: 'Quiet Record', enabled: true },
    { value: 'forfeiture-4', viewValue: 'Forfeiture', enabled: true },
    { value: 'fundtransfer-5', viewValue: 'Fund Transfer', enabled: true }
  ];
  public transactionPlaceHolder = 'Select one...';

  public entryMethodPlaceHolder = 'Select one...';
  entryMethods: EntryMethod[] = [
    { value: 'dollar-0', viewValue: '$ Entry', enabled: true },
    { value: 'percent-1', viewValue: '% Entry', enabled: true },
    { value: 'upload-2', viewValue: 'Upload', enabled: true }
  ];


  excludeFundList: number[] = [];
  excludeFundListAfterButtonClick: number[] = [];
  excludeMoneyTypeList: number[] = [];
  excludeMoneyTypeListAfterButtonClick: number[] = [];
  excludingMoneyTypes_showLabel_notInputBox = false;
  excludingFunds_showLabel_notInputBox = false;
  masterParticipantDataList: ParticipantData[] = [];     // After data load, this will not be manipulated and stored for original data.
  filteredParticipantDataList: ParticipantData[] = [];   // All manipulations for filters to be done on this list.
  selectUndefinedOptionValuel: any;
  public _dataReady = false;
  planIds: string;
  sourceYear;
  showModal: string;
  flagFirstTime = true;
  isPlanNameRequired = false;
  isAssetGroupNameRequired = false;
  selectedPlan: string;
  selectedAssetGroup: string;
  flagReloadFilterValues = false;
  upperPanelData: UpperPanelData;
  fundDetailsMap: Record<number, string>;
  moneyTypeDetailsMap: Record<number, string>;
  flagShowNumberOfFundsExcluded = false;
  flagShowNumberOfMoneyExcluded = false;
  quietRecordIsSelected = false;
  numberOFFundsExcluded: number;
  numberOFMoneyExcluded: number;
  sourceYearFlag;
  isSourceYearRequired = false;
  dataListForSaveTransaction;
  isPartialDeductionChecked = false;
  exceptionList: any = [];
  propertyName;
  accountIdList: any = [];
  accountForScheduleDate: any = [];
  participantNameAndAccountID;
  amountToDivideBetweenAllparticipant: any;
  checkedRadioOption4: boolean;
  isTransactionTypeSelected = false;
  isCalculationPerformed = false;
  oldTransactionType1;
  oldTransactionType2;
  hasRecalculatedAfterDateChange = true;
  isViewMode: string;
  inViewMode: boolean;
  approvalStatus: string;
  approvalText: string;
  transType: string;
  transId: string;
  loadDistributionModalComponent: boolean;
  displayMissingTransactionTypeModal: boolean;
  alertMessageQRecord: string;
  selectedSourceFundForTransfer: FundDetails;
  selectedTargetFundForTransfer: FundDetails;
  errorMessageForFundTransfer: string = "";

  constructor(public datepipe: DatePipe, private router: Router,
    public balancesService: BalancesServiceService, public fastTransactionService: FastTransactionService, appComponent: AppComponent,
    private common: CommonService, public upperPanelDataService: UpperPanelService, public changeDetectorRef: ChangeDetectorRef
  ) { }

  set dataReady(value) {
    let callSetCompanyInfoPanelVariables: boolean;
    if (this._dataReady === false) {
      callSetCompanyInfoPanelVariables = true;
    } else {
      callSetCompanyInfoPanelVariables = false;
    }
    this._dataReady = value;
    if (this.flagPageLoad) {
      if (callSetCompanyInfoPanelVariables) {
        this.setCompanyInfoPanelVariables();
        this.flagPageLoad = false;
      }
    }
    if (this._dataReady === true) {
      this.showModalMessage();
    }
  }

  showModalMessage() {
    if (this.showModal === undefined) {
      return;
    }
    if (this.showModal === 'quietRecord') {
      document.getElementById('hiddenButtonQuietRecord').click();
    } else if (this.showModal === 'quietRecordFailure') {
      document.getElementById('hiddenButtonQuietRecordFailure').click();
    } else if (this.showModal === 'deduction') {
      document.getElementById('hiddenButtonValidation').click();
    } else if (this.showModal === 'success') {
      document.getElementById('hiddenButtonSuccess').click();
    } else if (this.showModal === 'failure') {
      document.getElementById('hiddenButtonFailure').click();
    } else if (this.showModal === 'email') {
      document.getElementById('hiddenButtonEmail').click();
    } else if (this.showModal === 'transType') {
      document.getElementById('hiddenButtonTransValidation').click();
    } else if (this.showModal === 'fundTransferError') {
      document.getElementById('hiddenButtonFundTransfer').click();
    }
    this.showModal = undefined;
  }

  ngAfterViewChecked() {
    if (this.flagReloadFilterValues) {
      this.reloadFilterValues();
      if (this.companyId === '7011') {
        (document.getElementById('fund#4005') as HTMLInputElement).checked = true;
        (document.getElementById('fund#3746') as HTMLInputElement).checked = true;
        (document.getElementById('fund#3476') as HTMLInputElement).checked = true;
        (document.getElementById('fund#3437') as HTMLInputElement).checked = true;
        (document.getElementById('fund#3732') as HTMLInputElement).checked = true;
        this.excludeFundList = [3746, 3476, 3437, 3732, 4005];
        this.showModalApplyFilterFund();
      }
    }
    this.getStep4Changes();
  }

  ngOnInit() {
    this.localStore = window.localStorage;
    // validate if user entered the page after page 1.
    if (this.validateUser()) {
      this.isViewMode = this.localStore.getItem('isViewMode');
      this.transId = this.localStore.getItem('transId');
      this.approvalStatus = this.localStore.getItem('approvalStatus');
      this.transType = this.localStore.getItem('transType');
      // transDateString is a date type variable bound to the input field in HTML.
      // we need to transform it in yyyy-MM-dd format to get the transDate.
      this.transDateString = new Date(this.localStore.getItem('transDate'));
      this.checkIfCurrentOrFutureDate();
      this.transDate = this.datepipe.transform(this.transDateString, 'yyyy-MM-dd');
      this.planIds = this.localStore.getItem('planIds');
      this.sourceYear = this.localStore.getItem('sourceYear');
      this.sourceYearFlag = this.localStore.getItem('sourceYearFlag');
      this.minTransactionDate = new Date(this.localStore.getItem('minTransactionDate'));
      if ((this.isViewMode === 'true')) {
        this.inViewMode = true;
      }
      this.getUpperPanelData(this.upperPanelDataService);
      this.checkBalanceUpdated(this.balancesService);
      this.checkedRadioOption1 = true;
    } else {
      this.cancelTransaction();
    }
  }

  validateUser() {
    if (this.localStore.getItem('companyName') === null ||
      this.localStore.getItem('transDate') === null ||
      this.localStore.getItem('filterType') === null) {
      const url = this.router.url.split('/');
      const len = url.length;
      this.companyId = url[len - 2];
      return false;
    }
    return true;
  }

  setCompanyInfoPanelVariables() {
    // Get evrything from local storage into the variables.
    this.oldTransDate = this.transDate;
    this.selectedOption = this.localStore.getItem('filterType');
    this.companyId = this.localStore.getItem('companyId');
    this.companyName = this.localStore.getItem('companyName');
    this.maxTransactionDate.setDate(new Date().getDate() - 1);
    this.checkedRadioOption1 = true;
    this.selectedAssetGroup = this.localStore.getItem('selectedAssetGroup');
    this.selectedPlan = this.localStore.getItem('selectedPlan');
    this.autoTradeFlag = this.localStore.getItem('autoTradeFlag');
    if (this.sourceYearFlag === 'false') {
      this.isSourceYearRequired = true;
    }
    if (this.selectedOption === 'All') {
      this.isPlanNameRequired = false;
      this.isAssetGroupNameRequired = false;
    } else if (this.selectedOption === 'Plan') {
      this.isPlanNameRequired = true;
      this.isAssetGroupNameRequired = false;
    } else if (this.selectedOption === 'Asset Group') {
      this.isPlanNameRequired = false;
      this.isAssetGroupNameRequired = true;
    }
    if (this.autoTradeFlag === 'true') {
      this.showAutoTradeFlag = true;
    }

    this.localStore.removeItem('companyName');
    this.localStore.removeItem('filterType');
    this.localStore.removeItem('transDate');
    this.localStore.removeItem('autoTradeFlag');
    this.localStore.removeItem('isViewMode');
  }

  checkIfCurrentOrFutureDate() {
    const today = new Date(this.todaysDate.getFullYear(), this.todaysDate.getMonth(), this.todaysDate.getDate());
    if (this.transDateString >= today && !this.futureDatedTransaction) { // second condition is used to prevent confirmation box from constantly popping up if user picks multiple future dates.
      this.futureDatedTransaction = true;
      this.entryMethods[1].enabled = true; // enable percent based entries
      this.selectedTransaction = this.transactions[1];
      this.transactions[1].enabled = false; // disable load balance
      this.transactions[2].enabled = false; // disable distributions
      this.transactions[3].enabled = false; // disable q records
      this.transactions[4].enabled = false; // disable forfeiture

      if (this.participantDatalist) {
        if (this.participantDatalist.length > 0) {
          if (this.participantDatalist[0].deduction != null) {
            this.getSelectedCalculateBalance();
          }
        }
      }
    } else if (this.transDateString < today) {
      this.futureDatedTransaction = false;
      this.entryMethods[1].enabled = false;
      this.transactions[1].enabled = true; // disable load balance
      this.transactions[2].enabled = true; // disable distributions
      this.transactions[3].enabled = true; // disable q records
      this.transactions[4].enabled = true; // disable forfeiture
      this.transactions[5].enabled = true; // disable forfeiture
      this.selectedEntryMethod = this.entryMethods[0]; // set entry method to $ based
    }
  }

  getUpperPanelData(upperPanelServices) {
    upperPanelServices.getUpperPanelData(this.planIds)
      .subscribe(data => {
        this.upperPanelData = data;
        this.fundDetailsMap = {};

        this.upperPanelData.fundDetailList.forEach(fund => {
          this.fundDetailsMap[fund.fundId] = fund.fundName;
        })

        this.moneyTypeDetailsMap = {};
        this.upperPanelData.moneyTypeDetailList.forEach(moneyType => {
          this.moneyTypeDetailsMap[moneyType.moneyTypeId] = moneyType.moneyName;
        })
      },
        err => {
          this.common.setErrorCode(err.status);
          this.router.navigate(['/error']);
        });
  }

  checkBalanceUpdated(balancesService) {
    const datetoUse = this.getLatestBusinessDay();

    balancesService.checkBalanceUpdated(this.localStore.getItem('planIds'), datetoUse)
      .subscribe(data => {
        this.balanceUpdatedFlag = data;
        if (this.balanceUpdatedFlag) {
          this.getBalances(this.balancesService);
        } else {
          this.dataReady = true;
        }
      }, err => {
        this.common.setErrorCode(err.status);
        this.router.navigate(['/error']);
      });
  }

  getBalances(balancesService) {
    const datetoUse = this.getLatestBusinessDay();

    balancesService.getBalances(this.planIds, datetoUse, this.sourceYear, this.sourceYearFlag)
      .subscribe((data: ParticipantData[]) => {
        this.participantDatalist = data;
        this.dataReady = true;
        this.masterParticipantDataList = JSON.parse(JSON.stringify(this.participantDatalist));
        this.filteredParticipantDataList = JSON.parse(JSON.stringify(this.masterParticipantDataList));
        if (this.flagPageLoad === false) {
          this.flagReloadFilterValues = true;
        }
      }, err => {
        this.common.setErrorCode(err.status);
        this.router.navigate(['/error']);
      });
  }

  getLatestBusinessDay() {
    const today = new Date(this.todaysDate.getFullYear(), this.todaysDate.getMonth(), this.todaysDate.getDate());
    if (this.transDateString >= today) {
      const newDate = new Date().setDate(today.getDate() - 1);
      const newDateStr = this.datepipe.transform(newDate, 'yyyy-MM-dd');
      return newDateStr;
    } else {
      return this.transDate;
    }

  }

  cancelTransaction() {
    this.localStore.clear();
    this.router.navigateByUrl('/company/' + this.companyId);
  }

  reloadPreviousTransDate() {
    this.transDate = this.oldTransDate;
    this.transDateString = new Date(this.transDate);
  }

  validateDeduction() {
    if (this.filteredParticipantDataList.length === 0) {
      this.showModal = 'deduction';
      this.isValidDeduction = false;
    } else {
      this.filteredParticipantDataList.forEach(participantData => {
        if (participantData.deduction === null) {
          this.showModal = 'deduction';
          this.isValidDeduction = false;
        } else {
          this.isValidDeduction = true;
        }
      });
    }
  }

  updateDecimalDifferences() {
    this.filteredParticipantDataList.forEach(participant => {
      let sumAtAccountLevel = 0;
      participant.accountBalances.forEach(accountBalance => {
        sumAtAccountLevel += Number(accountBalance.deduction);
      });
      sumAtAccountLevel = Number(sumAtAccountLevel.toFixed(2));
      if (sumAtAccountLevel !== Number(participant.deduction)) {
        let difference = Number((Number(participant.deduction) - sumAtAccountLevel).toFixed(2));
        participant.accountBalances.forEach(accountBalance => {
          if (difference !== 0) {
            let diffBetEndCashBalAndDedution;
            if (difference > 0) {
              diffBetEndCashBalAndDedution = Number(Number(Number(accountBalance.endCashBal).toFixed(2))
                - Number(Number(accountBalance.deduction).toFixed(2)));
            } else {
              diffBetEndCashBalAndDedution = Number(Number(Number(accountBalance.endCashBal).toFixed(2))
                - Number(Number(0).toFixed(2)));
            }
            const amountToChangeInDeduction = diffBetEndCashBalAndDedution < Math.abs(difference)
              ? difference < 0 ? -diffBetEndCashBalAndDedution : diffBetEndCashBalAndDedution : difference;
            if (diffBetEndCashBalAndDedution !== 0) {
              accountBalance.deduction = Number(Number(accountBalance.deduction) + amountToChangeInDeduction).toFixed(2);
              difference = difference - amountToChangeInDeduction;
            }
          }
        });
      }
      participant.accountBalances.forEach(account => {
        let sumAtMoneyTypeLevel = 0;
        account.moneyTypeBalances.forEach(moneyType => {
          sumAtMoneyTypeLevel += Number(Number(moneyType.deduction).toFixed(2));
        });
        sumAtMoneyTypeLevel = Number(sumAtMoneyTypeLevel.toFixed(2));
        if (sumAtMoneyTypeLevel !== Number(account.deduction)) {
          let difference = Number((Number(account.deduction) - sumAtMoneyTypeLevel).toFixed(2));
          account.moneyTypeBalances.forEach(moneyTypeBalance => {
            if (difference !== 0) {
              let diffBetEndCashBalAndDedution;
              if (difference > 0) {
                diffBetEndCashBalAndDedution = Number((Number(moneyTypeBalance.endCashBal)
                  - Number(moneyTypeBalance.deduction)).toFixed(2));
              } else {
                diffBetEndCashBalAndDedution = Number((Number(moneyTypeBalance.endCashBal)
                  - Number(0)).toFixed(2));
              }
              const amountToChangeInDeduction = diffBetEndCashBalAndDedution < Math.abs(difference)
                ? difference < 0 ? -diffBetEndCashBalAndDedution : diffBetEndCashBalAndDedution : difference;
              if (diffBetEndCashBalAndDedution !== 0) {
                moneyTypeBalance.deduction = Number(Number(moneyTypeBalance.deduction) + amountToChangeInDeduction).toFixed(2);
                difference = difference - amountToChangeInDeduction;
              }
            }
          });
        }
        account.moneyTypeBalances.forEach(moneyType => {
          let sumAtFundLevel = 0;
          moneyType.fundBalances.forEach(fund => {
            sumAtFundLevel += Number(Number(fund.deduction).toFixed(2));
          });
          sumAtFundLevel = Number(sumAtFundLevel.toFixed(2));
          if (sumAtFundLevel !== Number(moneyType.deduction)) {
            let difference = Number((Number(moneyType.deduction) - sumAtFundLevel).toFixed(2));
            moneyType.fundBalances.forEach(fund => {
              if (difference !== 0) {
                let diffBetEndCashBalAndDedution;
                if (difference > 0) {
                  diffBetEndCashBalAndDedution = Number((Number(fund.endCashBal) - Number(fund.deduction)).toFixed(2));
                } else {
                  diffBetEndCashBalAndDedution = Number((Number(fund.endCashBal) - Number(0)).toFixed(2));
                }
                const amountToChangeInDeduction = diffBetEndCashBalAndDedution < Math.abs(difference)
                  ? difference < 0 ? -diffBetEndCashBalAndDedution : diffBetEndCashBalAndDedution : difference;
                if (diffBetEndCashBalAndDedution !== 0) {
                  fund.deduction = Number(Number(fund.deduction) + amountToChangeInDeduction).toFixed(2);
                  difference = difference - amountToChangeInDeduction;
                }
              }
            });
          }
        });
      });
    });
    this.updateNegativeValueInDeductionToZero();
  }

  updateDeductionsWithInterest() {
    this.filteredParticipantDataList.forEach(participantData => {
      participantData.accountBalances.forEach(accountData => {
        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          moneyTypeData.fundBalances.forEach(fundData => {
            fundData.deductionWithInterest = Number(Number(this.getInterestByFund(fundData) + Number(fundData.deduction)).toFixed(2));
            // this.getFundElement(participantData, accountData, moneyTypeData, fundData).innerHTML =
            // (this.getInterestByFund(fundData) + Number(fundData.deduction)).toFixed(2);
          });
          moneyTypeData.deductionWithInterest = Number(Number(this.getInterestByMoneyType(moneyTypeData)) + Number(moneyTypeData.deduction)).toFixed(2);
          // this.getMoneyTypeElement(participantData, accountData, moneyTypeData).innerHTML =
          // (this.getInterestByMoneyType(moneyTypeData) + Number(moneyTypeData.deduction)).toFixed(2);
        });
        accountData.deductionWithInterest = Number(Number(this.getInterestByAccount(accountData)) + Number(accountData.deduction)).toFixed(2);
        // this.getAccountElement(participantData, accountData).innerHTML =
        // (this.getInterestByAccount(accountData) + Number(accountData.deduction)).toFixed(2);
      });
      participantData.deductionWithInterest = Number(Number(this.getInterestByParticipant(participantData)) + Number(participantData.deduction)).toFixed(2);
      // this.getParticipantElement(participantData).innerHTML =
      // (this.getInterestByParticipant(participantData) + Number(participantData.deduction)).toFixed(2);
    });
  }

  clearDeductionsWithInterest() {
    this.filteredParticipantDataList.forEach(participantData => {
      participantData.accountBalances.forEach(accountData => {
        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          moneyTypeData.fundBalances.forEach(fundData => {
            fundData.deductionWithInterest = 0;
          });
          moneyTypeData.deductionWithInterest = 0;
        });
        accountData.deductionWithInterest = 0;
      });
      participantData.deductionWithInterest = 0;
    });
  }

  updateNegativeValueInDeductionToZero() {
    let difference = 0;
    let flag = false;
    this.filteredParticipantDataList.forEach(participantData => {
      participantData.accountBalances.forEach(accountData => {
        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          difference = 0;
          flag = false;
          moneyTypeData.fundBalances.forEach(fundData => {
            if (fundData.deduction < 0) {
              difference = difference + Number(fundData.deduction);
              fundData.deduction = Number(0).toFixed(2);
              flag = true;
            }
          });

          if (difference !== 0) {
            let tempflag = false;
            moneyTypeData.fundBalances.forEach(fundData => {
              if (fundData.deduction >= Math.abs(difference) && tempflag === false) {
                tempflag = true;
                fundData.deduction = Number(Number(fundData.deduction) + Number(difference)).toFixed(2);
              }
            });
          }
        });
      });
    });
  }

  updateDecimalDifferences1() {

    let sumOfAccountLevel = 0;
    let sumOfMoneyLevel = 0;
    let sumOfFundLevel = 0;
    this.filteredParticipantDataList.forEach(participantData => {
      participantData.accountBalances.forEach(accountData => {
        sumOfAccountLevel = Number(Number(sumOfAccountLevel) + Number(accountData.deduction));
      });
      if (sumOfAccountLevel < Number(participantData.deduction)) {
        participantData.accountBalances[0].deduction = Number(Number(participantData.accountBalances[0].deduction)
          + Number(participantData.deduction) - sumOfAccountLevel).toFixed(2);
      } else if (sumOfAccountLevel > Number(participantData.deduction)) {
        participantData.accountBalances[0].deduction = Number(Number(participantData.accountBalances[0].deduction)
          + Number(participantData.deduction) - sumOfAccountLevel).toFixed(2);
      }
      sumOfAccountLevel = 0;
    });

    this.filteredParticipantDataList.forEach(participantData => {
      participantData.accountBalances.forEach(accountData => {
        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          sumOfMoneyLevel = Number(Number(sumOfMoneyLevel) + Number(moneyTypeData.deduction));
        });
        if (sumOfMoneyLevel < Number(accountData.deduction)) {
          accountData.moneyTypeBalances[0].deduction = Number(Number(accountData.moneyTypeBalances[0].deduction)
            + Number(accountData.deduction) - sumOfMoneyLevel).toFixed(2);
        } else if (sumOfMoneyLevel > Number(accountData.deduction)) {
          accountData.moneyTypeBalances[0].deduction = Number(Number(accountData.moneyTypeBalances[0].deduction)
            + Number(accountData.deduction) - sumOfMoneyLevel).toFixed(2);
        }
        sumOfMoneyLevel = 0;
      });
    });

    this.filteredParticipantDataList.forEach(participantData => {
      participantData.accountBalances.forEach(accountData => {
        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          moneyTypeData.fundBalances.forEach(fundData => {
            sumOfFundLevel = Number(Number(sumOfFundLevel) + Number(fundData.deduction));
          });

          if (sumOfFundLevel < Number(moneyTypeData.deduction)) {
            moneyTypeData.fundBalances[0].deduction = Number(Number(moneyTypeData.fundBalances[0].deduction)
              + Number(moneyTypeData.deduction) - sumOfFundLevel).toFixed(2);
          } else if (sumOfFundLevel > Number(moneyTypeData.deduction)) {
            moneyTypeData.fundBalances[0].deduction = Number(Number(moneyTypeData.fundBalances[0].deduction)
              + Number(moneyTypeData.deduction) - sumOfFundLevel).toFixed(2);
          }
          sumOfFundLevel = 0;
        });
      });
    });
  }

  saveTransaction() {
    this.alertMessage = '';
    this.updateFilteredParticipantListAfterManualDeduction();
    this.validateTransType();
    this.validateDeduction();
    if (this.isValidDeduction === false) {
      this.dataReady = true;
    }

    if (this.isValidTransaction === true && this.isValidDeduction === true &&
      (this.selectedTransaction === 'Fee' || this.selectedTransaction === 'Balance Transfer' ||
        this.selectedTransaction === 'Quiet Record' || this.selectedTransaction === 'Fund Transfer')) {
      const transaction = this.createTransactionJSON(this.companyId, this.transDate, this.filteredParticipantDataList);
      if (this.selectedEntryMethod === '% Entry') {
        this.convertWholePercentToDecimal(transaction);
      }

      // We needed deductionWithInterest on the UI but the service has no use of it.
      this.removeDeductionWithPercentField(transaction);

      this.dataReady = false;

      // this.selectedTargetFundForTransfer?.fundId evaluates to undefined instead of throwing an error
      this.balancesService.saveTransaction(JSON.stringify(transaction), this.selectedTransaction, this.selectedTargetFundForTransfer?.fundId).subscribe(
        res => {
          if (res) {
            this.showModal = 'success';
            this.isDisable = true; // will used to disabled the submit transaction button
            this.dataReady = true;
          }
        },
        err => {
          if (err.status === 504) {
            this.showModal = 'email';
            this.dataReady = true;
            if (this.flagPageLoad === false) {
              this.flagReloadFilterValues = true;
            }
          } else {
            this.showModal = 'failure';
            this.dataReady = true;
          }
          this.percentagePerParticipant = null;
          this.amountPerAccount = null;
          this.amountPerParticipant = null;

        });
    } else if (this.isValidTransaction === true && this.isValidDeduction === true
      && (this.selectedTransaction === 'Distribution')) {
      this.getAllAccountIdFromParticipantList();
      this.getScheduledateByAccountId();
    }
    else if (this.isValidTransaction === true && this.isValidDeduction === true
      && (this.selectedTransaction === 'Forfeiture')) {
      this.getAllAccountIdFromParticipantList();
      this.getScheduledateByAccountId();
    }
  }

  convertWholePercentToDecimal(transaction) {
    transaction.employeeBalanceList.forEach(participant => {
      participant.deduction = Number(participant.deduction) / 100.0;
      participant.deductionWithInterest = participant.deduction;
      participant.accountBalances.forEach(account => {
        account.deduction = Number(account.deduction) / 100.0;
        account.deductionWithInterest = account.deduction;
        account.moneyTypeBalances.forEach(moneyType => {
          moneyType.deduction = Number(moneyType.deduction) / 100.0;
          moneyType.deductionWithInterest = moneyType.deduction;
          moneyType.fundBalances.forEach(fund => {
            fund.deduction = Number(fund.deduction) / 100.0;
            fund.deductionWithInterest = fund.deduction;
          });
        });
      });
    });
  }

  removeDeductionWithPercentField(transaction) {
    transaction.employeeBalanceList.forEach(participant => {
      delete participant.deductionWithInterest;
      participant.accountBalances.forEach(account => {
        delete account.deductionWithInterest;
        account.moneyTypeBalances.forEach(moneyType => {
          delete moneyType.deductionWithInterest;
          moneyType.fundBalances.forEach(fund => {
            delete fund.deductionWithInterest;
          });
        });
      });
    });
  }


  createData() {

    this.participantNameAndAccountID = '';
    this.filteredParticipantDataList.forEach(participantData => {
      participantData.accountBalances.forEach(accountData => {
        if (this.accountForScheduleDate.indexOf(accountData.accountId) === -1) {
          this.participantNameAndAccountID = this.participantNameAndAccountID + (
            participantData.empName + ' ' + '(AccountName: ' + accountData.accountId + '), ');
        }
      });
    });
  }

  getAllAccountIdFromParticipantList() {
    this.accountIdList = [];
    this.filteredParticipantDataList.forEach(participantData => {
      participantData.accountBalances.forEach(accountData => {
        this.accountIdList.push(accountData.accountId);
      });
    });

  }

  getScheduledateByAccountId() {

    this.participantNameAndAccountID = '';
    this.balancesService.getScheduledateByAccountId(this.accountIdList, this.oldTransDate)
      .subscribe(data => {
        this.accountForScheduleDate = data;
        this.filteredParticipantDataList.forEach(participantData => {
          participantData.accountBalances.forEach(accountData => {
            if (data.indexOf(accountData.accountId) === -1) {
              this.participantNameAndAccountID = this.participantNameAndAccountID + (
                participantData.empName + ' ' + '(AccountName: ' + accountData.accountId + '), ');
            }
          });
        });
        if (this.participantNameAndAccountID.length > 0) {
          document.getElementById('hiddentButtonForAccountScheduleDate').click();
        }
        else {
          this.saveDistributionTransaction();
        }

      });
  }

  //also saves Forfeitures
  saveDistributionTransaction() {

    const transaction = this.createTransactionJSON(this.companyId, this.transDate, this.filteredParticipantDataList);
    if (this.selectedEntryMethod === '% Entry') {
      this.convertWholePercentToDecimal(transaction);
    }
    this.dataReady = false;
    this.balancesService.saveTransaction(JSON.stringify(transaction), this.selectedTransaction).subscribe(
      res => {
        if (res) {
          this.showModal = 'success';
          this.isDisable = true; // will used to disabled the submit transaction button
          this.dataReady = true;
        }
      },
      err => {
        if (err.status === 504) {
          this.showModal = 'email';
          this.dataReady = true;
          if (this.flagPageLoad === false) {
            this.flagReloadFilterValues = true;
          }
        } else {
          this.showModal = 'failure';
          this.dataReady = true;
        }
        this.percentagePerParticipant = null;
        this.amountPerAccount = null;
        this.amountPerParticipant = null;

      });
  }

  createTransactionJSON(companyId: string, transDate: string, participantDatalist: ParticipantData[]) {
    const employeeBalanceList = 'employeeBalanceList';
    const participantData = this.addInterestToDeduction(participantDatalist).filter(participant => participant.deduction > 0);
    let entryMethod: any;

    participantData.forEach((participant: ParticipantData) => {
      participant.accountBalances = participant.accountBalances.filter(account => account.deduction > 0);
      participant.accountBalances.forEach((account: AccountData) => {
        account.moneyTypeBalances = account.moneyTypeBalances.filter(moneyType => moneyType.deduction > 0);
        account.moneyTypeBalances.forEach((moneyType: MoneyType) => {
          if (this.selectedTransaction == 'Fund Transfer') {
            // for fund transfers, we only care about the funds with money going out so our filter function is looking only for negative deductions
            // the .map function is returning a new list with the deduction amounts turned to a positive amount because 
            // the transaction_service negates detail ENTR_AMT them on its own
            moneyType.fundBalances = moneyType.fundBalances.filter(fund => fund.deduction < 0).map((fund) => {
              return { ...fund, deduction: fund.deduction * -1 }
            });
          }
          else {
            moneyType.fundBalances = moneyType.fundBalances.filter(fund => fund.deduction > 0);
          }
        })
      })
    })

    if (this.selectedEntryMethod === 'Upload') {
      entryMethod = '$';
    } else {
      entryMethod = this.selectedEntryMethod[0];
    }

    return {
      transDate,
      entryMethod,
      companyId,
      employeeBalanceList: participantData
    };
  }

  addInterestToDeduction(participantDataList: ParticipantData[]): ParticipantData[] {
    const participantDataListCopy: ParticipantData[] = JSON.parse(JSON.stringify(participantDataList));
    const mapToDeduction = {};
    participantDataListCopy.forEach((participantData: ParticipantData) => {
      mapToDeduction[participantData.empId] = {};
      participantData.accountBalances.forEach((accountData: AccountData) => {
        mapToDeduction[participantData.empId][accountData.accountId] = {};
        accountData.moneyTypeBalances.forEach((moneyTypeData: MoneyType) => {
          mapToDeduction[participantData.empId][accountData.accountId][moneyTypeData.moneyTypeId] = {};
          moneyTypeData.fundBalances.forEach((fundData: FundType) => {
            mapToDeduction[participantData.empId][accountData.accountId][moneyTypeData.moneyTypeId][fundData.fundId] =
              (this.getInterestByFund(fundData) + Number(fundData.deduction)).toFixed(2);
          });
          mapToDeduction[participantData.empId][accountData.accountId][moneyTypeData.moneyTypeId].totalBal =
            (this.getInterestByMoneyType(moneyTypeData) + Number(moneyTypeData.deduction)).toFixed(2);
        });
        mapToDeduction[participantData.empId][accountData.accountId].totalBal =
          (this.getInterestByAccount(accountData) + Number(accountData.deduction)).toFixed(2);
      });
      mapToDeduction[participantData.empId].totalBal =
        (this.getInterestByParticipant(participantData) + Number(participantData.deduction)).toFixed(2);
    });
    participantDataListCopy.forEach((participantData: ParticipantData) => {
      participantData.accountBalances.forEach((accountData: AccountData) => {
        accountData.moneyTypeBalances.forEach((moneyTypeData: MoneyType) => {
          moneyTypeData.fundBalances.forEach((fundData: FundType) => {
            fundData.deduction = mapToDeduction[participantData.empId][accountData.accountId][moneyTypeData.moneyTypeId][fundData.fundId];
          });
          moneyTypeData.deduction = mapToDeduction[participantData.empId][accountData.accountId][moneyTypeData.moneyTypeId].totalBal;
        });
        accountData.deduction = mapToDeduction[participantData.empId][accountData.accountId].totalBal;
      });
      participantData.deduction = mapToDeduction[participantData.empId].totalBal;
    });
    return participantDataListCopy;
  }

  clearZeroDeductions(participantData: ParticipantData[]): ParticipantData[] {
    participantData = participantData.filter((participant: ParticipantData) => Number(participant.deduction) > 0);
    participantData.forEach((participant: ParticipantData) => {
      participant.accountBalances = participant.accountBalances.filter((account: AccountData) => Number(account.deduction) > 0);
      participant.accountBalances.forEach((account: AccountData) => {
        account.moneyTypeBalances = account.moneyTypeBalances.filter((moneyType: MoneyType) => Number(moneyType.deduction) > 0);
        account.moneyTypeBalances.forEach((moneyType: MoneyType) => {
          moneyType.fundBalances = moneyType.fundBalances.filter((fund: FundType) => Number(fund.deduction) > 0);
        });
      });
    });
    return participantData;
  }

  reloadBalances() {
    // need to store the current transDate value in oldTransDate since the transDate can again get modified
    // transDateString is a date type variable bound to the input field in HTML.
    // we need to transform it in yyyy-MM-dd format to get the transDate.
    this.balanceUpdatedFlag = undefined;
    this.isDisable = false;
    this.transDate = this.datepipe.transform(this.transDateString, 'yyyy-MM-dd');
    this._dataReady = false;
    this.showModal = undefined;
    this.percentagePerParticipant = null;
    this.amountPerAccount = null;
    this.amountPerParticipant = null;
    this.amountToDivideBetweenAllparticipant = null;
    this.isTransactionTypeSelected = false;
    this.isCalculationPerformed = false;
    this.oldTransDate = this.transDate;
    this.checkBalanceUpdated(this.balancesService);
    this.resetDeductions();
    this.hasRecalculatedAfterDateChange = true;
  }

  reloadFilterValues() {
    try {
      this.upperPanelData.fundDetailList.forEach(fund => {
        const fundId = 'fund#' + fund.fundId;
        if (this.excludeFundList.includes(fund.fundId)) {
          (document.getElementById(fundId) as any).checked = true;
        } else {
          (document.getElementById(fundId) as any).checked = false;
        }
      });
      this.upperPanelData.moneyTypeDetailList.forEach(moneyType => {
        const moneyTypeId = 'moneyType#' + moneyType.moneyTypeId;
        if (this.excludeMoneyTypeList.includes(moneyType.moneyTypeId)) {
          (document.getElementById(moneyTypeId) as any).checked = true;
        } else {
          (document.getElementById(moneyTypeId) as any).checked = false;
        }
      });
      this.filterMoneyTypes();
      this.filterFunds();
      this.flagReloadFilterValues = false;
    } catch (err) {
      this.flagReloadFilterValues = true;
    }
  }

  refresh() {
    this.alertMessage = '';
    this.isDisable = false;
    this.resetDeductions();
    this.selectedTransaction = this.selectUndefinedOptionValuel;
    document.getElementById('radioPercentParticipant').click();
    this.percentagePerParticipant = undefined;
    this.amountPerAccount = undefined;
    this.amountPerParticipant = undefined;
    this.amountToDivideBetweenAllparticipant = undefined;
    this.isTransactionTypeSelected = false;
    this.isCalculationPerformed = false;
    this.filteredParticipantDataList = JSON.parse(JSON.stringify(this.participantDatalist));
    this.excludeFundList = [];
    this.excludeMoneyTypeList = [];
    this.excludeFundListAfterButtonClick = [];
    this.excludeMoneyTypeListAfterButtonClick = [];
    this.numberOFFundsExcluded = 0;
    this.numberOFMoneyExcluded = 0;
    this.excludingFunds_showLabel_notInputBox = false;
    this.excludingMoneyTypes_showLabel_notInputBox = false;
    this.selectedSourceFundForTransfer = null;
    this.selectedTargetFundForTransfer = null;
    (document.getElementById('exceptionReport') as any).disabled = true;
    this.isPartialDeductionChecked = false;
    this.reloadFilterValues();
    if (this.companyId === '7011') {
      (document.getElementById('fund#4005') as HTMLInputElement).checked = true;
      (document.getElementById('fund#3746') as HTMLInputElement).checked = true;
      (document.getElementById('fund#3476') as HTMLInputElement).checked = true;
      (document.getElementById('fund#3437') as HTMLInputElement).checked = true;
      (document.getElementById('fund#3732') as HTMLInputElement).checked = true;
      this.excludeFundList = [3746, 3476, 3437, 3732, 4005];
      this.showModalApplyFilterFund();
    }
  }

  calculateBalances() {
    if (this.selectedEntryMethod == '% Entry') {
      this.calculateBalancesPercentEntry();
    } else {
      this.percentagePerParticipant = this.percentagePerParticipant.replace(/,/g, '');
      let allFundActualDeduction = 0;
      let allMoneyActualDeduction = 0;
      let allAccountActualDeduction = 0;
      this.isCalculationPerformed = true;
      this.isTransactionTypeSelected = true;
      this.filteredParticipantDataList.forEach(participantData => {
        const participantEndCashBal: number = Number(participantData[this.propertyName]);
        participantData.deduction = Number(Number(((participantEndCashBal * this.percentagePerParticipant) / 100)).toFixed(2));
        participantData.accountBalances.forEach(accountData => {
          const accountEndCashBal: number = Number(accountData[this.propertyName]);
          accountData.moneyTypeBalances.forEach(moneyTypeData => {
            const moneyTypeEndCashBal: number = Number(moneyTypeData[this.propertyName]);
            moneyTypeData.fundBalances.forEach(fundTypeData => {
              const fundEndCashBal: number = Number(fundTypeData[this.propertyName]);
              if (fundTypeData.deduction >= fundEndCashBal) {
                fundTypeData.deduction = Number(fundEndCashBal).toFixed(2);
                allFundActualDeduction += Number(fundEndCashBal);
              } else {
                fundTypeData.deduction = Number(((fundEndCashBal * this.percentagePerParticipant) / 100)).toFixed(2);
                allFundActualDeduction += Number(((fundEndCashBal * this.percentagePerParticipant) / 100));
              }
            });
            if (this.excludeFundListAfterButtonClick.length > 0 || this.excludeMoneyTypeListAfterButtonClick.length > 0) {
              moneyTypeData.deduction = Number(allFundActualDeduction).toFixed(2);
              allMoneyActualDeduction += Number(allFundActualDeduction);
            } else {
              moneyTypeData.deduction = Number(((moneyTypeEndCashBal * this.percentagePerParticipant) / 100)).toFixed(2);
              allMoneyActualDeduction += Number(((moneyTypeEndCashBal * this.percentagePerParticipant) / 100));
            }
            allFundActualDeduction = 0;
          });
          if (this.excludeFundListAfterButtonClick.length > 0 || this.excludeMoneyTypeListAfterButtonClick.length > 0) {
            accountData.deduction = Number(allMoneyActualDeduction).toFixed(2);
            allAccountActualDeduction += Number(allMoneyActualDeduction);
          } else {
            accountData.deduction = Number(((accountEndCashBal * this.percentagePerParticipant) / 100)).toFixed(2);
            allAccountActualDeduction += Number(((accountEndCashBal * this.percentagePerParticipant) / 100));
          }
          allMoneyActualDeduction = 0;
        });
        if (this.excludeFundListAfterButtonClick.length > 0 || this.excludeMoneyTypeListAfterButtonClick.length > 0) {
          participantData.deduction = Number(allAccountActualDeduction).toFixed(2);
        }
        allAccountActualDeduction = 0;
      });

      this.convertDeductionAmountToTillTwoDecimalForAutoCalculations();
      this.updateDecimalDifferences();
      this.updateDeductionsWithInterest();
      this.participantDatalist = this.filteredParticipantDataList;
    }
  }

  calculateBalancesPercentEntry() {
    this.percentagePerParticipant = this.percentagePerParticipant.replace(/,/g, '');
    this.isCalculationPerformed = true;
    this.isTransactionTypeSelected = true;
    this.filteredParticipantDataList.forEach(participantData => {
      participantData.deduction = this.percentagePerParticipant;
      participantData.accountBalances.forEach(accountData => {
        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          moneyTypeData.fundBalances.forEach(fundTypeData => {
            fundTypeData.deduction = this.percentagePerParticipant;
          });
          moneyTypeData.deduction = this.percentagePerParticipant;
        });
        accountData.deduction = this.percentagePerParticipant;
      });
      participantData.deduction = this.percentagePerParticipant;
    });

  }

  calculateAmountPerParticipant() {
    const amountPerParticipant = this.amountPerParticipant.replace(/,/g, '');
    this.isCalculationPerformed = true;
    this.isTransactionTypeSelected = true;
    this.filteredParticipantDataList.forEach(participantData => {
      let allFundBalancesSum = 0;
      participantData.accountBalances.forEach(account => {
        account.moneyTypeBalances.forEach(moneyType => {
          moneyType.fundBalances.forEach(fund => {
            allFundBalancesSum = allFundBalancesSum + Number(fund[this.propertyName]);
          });
        });
      });
      const participantEndCashBal = Number(allFundBalancesSum < Number(participantData[this.propertyName]) ?
        allFundBalancesSum : Number(participantData[this.propertyName])).toFixed(2);
      if (Number(participantEndCashBal) < Number(amountPerParticipant) &&
        this.isPartialDeductionChecked &&
        (this.selectedTransaction === 'Balance Transfer' || this.selectedTransaction === 'Fee')) {
        participantData.deduction = Number(0).toFixed(2);
        participantData.accountBalances.forEach(account => {
          account.deduction = Number(0).toFixed(2);
          account.moneyTypeBalances.forEach(moneyType => {
            moneyType.deduction = Number(0).toFixed(2);
            moneyType.fundBalances.forEach(fund => {
              fund.deduction = Number(0).toFixed(2);
            });
          });
        });
        this.exceptionList.push(participantData);
      } else {
        if (Number(participantEndCashBal) < Number(amountPerParticipant)) {
          participantData.deduction = Number(participantEndCashBal).toFixed(2);
        } else {
          participantData.deduction = Number(amountPerParticipant).toFixed(2);
        }
        participantData.accountBalances.forEach(account => {
          let actualAmountInAccount = 0;
          account.moneyTypeBalances.forEach(moneyType => {
            moneyType.fundBalances.forEach(fund => {
              actualAmountInAccount = actualAmountInAccount + Number(fund[this.propertyName]);
            });
          });
          account.deduction = Number(Number(actualAmountInAccount * Number(participantData.deduction)) / Number(allFundBalancesSum))
            .toFixed(2);

          if (account.deduction === 'NaN') {
            account.deduction = '0.00';
          }

          account.moneyTypeBalances.forEach(moneyType => {
            let actualAmountInMoneyType = 0;
            moneyType.fundBalances.forEach(fund => {
              actualAmountInMoneyType = actualAmountInMoneyType + Number(fund[this.propertyName]);
            });
            moneyType.deduction = Number(Number(actualAmountInMoneyType * Number(account.deduction)) / Number(actualAmountInAccount)).
              toFixed(2);
            if (moneyType.deduction === 'NaN') {
              moneyType.deduction = '0.00';
            }
            moneyType.fundBalances.forEach(fund => {
              fund.deduction = Number(Number(Number(fund[this.propertyName]) * Number(moneyType.deduction)) /
                Number(actualAmountInMoneyType)).toFixed(2);
              if (fund.deduction === 'NaN') {
                fund.deduction = '0.00';
              }
            });
          });
        });
      }
    });
    this.convertDeductionAmountToTillTwoDecimalForAutoCalculations();
    this.updateDecimalDifferences();
    this.updateDeductionsWithInterest();
    let idx = 0;
    if (this.isPartialDeductionChecked &&
      (this.selectedTransaction === 'Balance Transfer' || this.selectedTransaction === 'Fee')) {
      this.participantDatalist.forEach(participantData => {
        if (idx === this.filteredParticipantDataList.length) {
          idx--;
        }
        if (this.filteredParticipantDataList[idx].empId !== participantData.empId) {
          const participant = JSON.parse(JSON.stringify(participantData));
          participant.accountBalances.forEach(account => {
            account.moneyTypeBalances.forEach(moneyType => {
              moneyType.fundBalances.forEach(fund => {
                fund[this.propertyName] = 0;
              });
            });
          });
          this.exceptionList.push(participant);
        } else {
          idx++;
        }
      });
      if (this.exceptionList.length > 0) {
        document.getElementById('hiddenButtonException').click();
        (document.getElementById('exceptionReport') as any).disabled = false;
      } else {
        (document.getElementById('exceptionReport') as any).disabled = true;
      }
    } else {
      (document.getElementById('exceptionReport') as any).disabled = true;
    }
  }

  calculateFundTransferDeductions() {
    let sourceFund = this.selectedSourceFundForTransfer;
    let targetFund = this.selectedTargetFundForTransfer;
    let balanceInSourceFund: number = 0;
    let cashMovingFromSourceFund: number = 0;
    let moneyTypeLevelCashMoving: number = 0;
    let accountLevelCashMoving: number = 0;
    let participantLevelCashMoving: number = 0;
    // The following variable assignment is not found in the other 'Calculate' methods. The assignment fixes an issue where every fund 
    // in the account lineup was being updated to the first fund in the account's list.
    this.filteredParticipantDataList = this.participantDatalist;
    this.filteredParticipantDataList.forEach(participantData => {
      const participantEndCashBal: number = Number(participantData[this.propertyName]);
      participantData.accountBalances.forEach(accountData => {

        const accountEndCashBal: number = Number(accountData[this.propertyName]);
        accountData.moneyTypeBalances.forEach(moneyTypeData => {

          const moneyTypeEndCashBal: number = Number(moneyTypeData[this.propertyName]);
          let moneyTypeRequiresCalculating = this.moneyTypeContainsSourceFund(moneyTypeData.fundBalances, sourceFund, targetFund);
          if (moneyTypeRequiresCalculating && !this.excludeMoneyTypeList.includes(moneyTypeData.moneyTypeId)) {
            moneyTypeData.fundBalances.forEach(fundTypeData => {
              if (fundTypeData.fundId == sourceFund.fundId) {
                fundTypeData.deduction = this.percentagePerParticipant * -1;
                cashMovingFromSourceFund = (Number(fundTypeData.deduction / 100.0) * Number(fundTypeData.endCashBal)) * -1; //make it positive again so the value displayed in the table is correctly formatted
                balanceInSourceFund = Number(fundTypeData.endCashBal);
                moneyTypeLevelCashMoving += cashMovingFromSourceFund;
              }
              else if (fundTypeData.fundId == targetFund.fundId) {
                fundTypeData.deduction = 100;
              }
            })
            moneyTypeData.deduction = ((cashMovingFromSourceFund / moneyTypeEndCashBal) * 100).toFixed(3);
            accountLevelCashMoving += moneyTypeLevelCashMoving;
          }
        })
        accountData.deduction = ((moneyTypeLevelCashMoving / accountEndCashBal) * 100).toFixed(3);
        participantLevelCashMoving += accountLevelCashMoving;
        moneyTypeLevelCashMoving = 0;
      })
      participantData.deduction = ((participantLevelCashMoving / participantEndCashBal) * 100).toFixed(3);
      accountLevelCashMoving = 0;
      participantLevelCashMoving = 0;
    });
    this.participantDatalist = this.filteredParticipantDataList;
  }

  moneyTypeContainsSourceFund(fundBalances: FundType[], sourceFund: FundDetails, targetFund: FundDetails) {
    let containsSourceFund: boolean = false;
    let containsTargetFund: boolean = false;
    fundBalances.forEach(fund => {
      if (fund.fundId == sourceFund.fundId) {
        containsSourceFund = true;
      }
      else if (fund.fundId == targetFund.fundId) {
        containsTargetFund = true;
      }
    })

    if (containsSourceFund && containsTargetFund) {
      return true;
    }
    else if (!containsSourceFund && containsTargetFund) {
      return false;
    }
    else if (containsSourceFund && !containsTargetFund) {
      let tempFund = this.upperPanelData.fundDetailList.filter((fund) => { return fund.fundId === targetFund.fundId });
      let newFund: FundType = {
        planId: 0,
        empId: 0,
        empName: '',
        accountId: 0,
        accountName: '',
        moneyTypeId: 0,
        fundName: tempFund[0].fundName,
        fundId: tempFund[0].fundId,
        endCashBal: '0',
        vestedCasBal: '0',
        unvestedCashBal: '0',
        deduction: 100,
        deductionWithInterest: undefined,
        endShareBal: '',
        vestedShareBal: '',
        vestedPerct: '',
        interestAccrued: 0
      }
      fundBalances.push(newFund); //display target fund on participant table

      return true;
    }

    return false;
  }

  getStep4Changes() {
    if (this.selectedTransaction == null) {
      this.selectedEntryMethod = this.entryMethods[0]; // set entry method to $ based
    }
    //  If this is a valid transaction and we selected quiet record default to $ Entry method disable step 4
    if (this.selectedTransaction == 'Quiet Record') {
      this.validateTransType();
      this.selectedEntryMethod = this.entryMethods[0].viewValue;
      this.entryMethods[0].enabled = true; // enable $ based entries
      this.entryMethods[1].enabled = false;
      this.entryMethods[2].enabled = false;
      this.quietRecordIsSelected = true;
      this.changeDetectorRef.detectChanges();
    } else if (this.selectedTransaction == 'Fund Transfer') {
      //this.selectedEntryMethod = this.entryMethods[1].viewValue;
      this.entryMethods[0].enabled = false;
      this.entryMethods[1].enabled = true; // enable % based entries
      this.entryMethods[2].enabled = false;
      this.quietRecordIsSelected = false;
      this.changeDetectorRef.detectChanges();
    } else if (this.selectedTransaction == 'Forfeiture' || this.selectedTransaction == 'Distribution') {
      //this.selectedEntryMethod = this.entryMethods[0].viewValue;
      this.entryMethods[0].enabled = true; // enable $ based entries
      this.entryMethods[1].enabled = false;
      this.entryMethods[2].enabled = true;
      this.quietRecordIsSelected = false;
      this.changeDetectorRef.detectChanges();
    } else {
      //As of 11/30/2023, the remaining transaction types offered by this application are 
      //Fees and Balance Transfers which can support $ and % based entries. Therefore, enable everything.
      this.entryMethods[0].enabled = true;
      this.entryMethods[1].enabled = true;
      this.entryMethods[2].enabled = true;
      this.quietRecordIsSelected = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  getSelectedCalculateBalance() {
    this.alertMessage = '';
    this.validateTransType();
    this.zeroAllDeductions();
    if (this.isValidTransaction === true) {
      if (this.checkedRadioOption1) {
        if (this.validateStep4Inputs(1)) {
          if (this.selectedTransaction == 'Fund Transfer') {
            this.calculateFundTransferDeductions();
            return;
          }
          this.calculateBalances();
        } else {
          this.dataReady = true;
        }
      } else if (this.checkedRadioOption2) {
        if (this.validateStep4Inputs(2)) {
          this.exceptionList = [];
          this.calculateAmountPerAccount();
        } else {
          this.dataReady = true;
        }
      } else if (this.checkedRadioOption3) {
        if (this.validateStep4Inputs(3)) {
          this.exceptionList = [];
          this.calculateAmountPerParticipant();
        } else {
          this.dataReady = true;
        }
      } else if (this.checkedRadioOption4) {
        if (this.validateStep4Inputs(4)) {
          this.calculateBalancesForAmountDistributeToAllParticipants();
        } else {
          this.dataReady = true;
        }
      }
      this.updateTotalCells();
      this.syncFilteredAndParticipantDataList();
    } else {
      this.dataReady = true;
    }
  }

  validateTransType() {
    if (this.selectedTransaction === '' || this.selectedTransaction === undefined || this.selectedTransaction === 'Please Select') {
      this.alertMessage = 'Please select a valid transaction type.';
      this.showModal = 'transType';
      this.isValidTransaction = false;
    } else {
      this.isValidTransaction = true;
    }
    if (this.selectedTransaction === 'Quiet Record') {
      this.alertMessageQRecord = 'This exceeds $5.00, is this accurate?';
    } else {
      this.alertMessageQRecord = '';
    }

    if (this.selectedTransaction == 'Fund Transfer') {
      let validFundTransfer = this.validateFundTransferData();
      if (!validFundTransfer) {
        this.showModal = 'fundTransferError';
        return false;
      }
      return true;
    }
  }

  validateFundTransferData() {
    if (this.selectedSourceFundForTransfer == null || this.selectedTargetFundForTransfer == null) {
      this.errorMessageForFundTransfer = this.selectedSourceFundForTransfer == null ? 'Please select a source fund for your transaction.' : 'Please select a target fund for your transaction.';
      this.isValidTransaction = false;
      return false;
    }
    else if (this.selectedSourceFundForTransfer.fundId == this.selectedTargetFundForTransfer.fundId) {
      this.errorMessageForFundTransfer = "You can not transfer money into the same fund. \nPlease change either the source or target fund."
      this.isValidTransaction = false;
      return false;
    }
    this.isValidTransaction = true;
    this.errorMessageForFundTransfer = "";
    return true;
  }

  updateTotalCells() {
    let deductionsSum = 0;
    let deductionsWithInterestSum = 0;
    this.filteredParticipantDataList.forEach((participant: ParticipantData) => {
      deductionsSum += Number(participant.deduction);
      deductionsWithInterestSum += Number(participant.deductionWithInterest);
    });

    this.totalDeductionAmount = deductionsSum;
    this.totalDeductionWithInterestAmount = deductionsWithInterestSum;

  }

  zeroAllDeductions() {
    this.filteredParticipantDataList.forEach((participant: ParticipantData) => {
      participant.accountBalances.forEach((account: AccountData) => {
        account.moneyTypeBalances.forEach((moneyType: MoneyType) => {
          moneyType.fundBalances.forEach((fund: FundType) => {
            fund.deduction = '';
          });
          moneyType.deduction = '';
        });
        account.deduction = '';
      });
      participant.deduction = '';
    });
  }

  syncFilteredAndParticipantDataList() {
    let indexp = 0;
    this.participantDatalist.forEach(participant => {
      if (indexp < this.filteredParticipantDataList.length) {
        if (participant.empId === this.filteredParticipantDataList[indexp].empId) {
          this.setParticipantLevelDeductions(participant, indexp);
          indexp++;
        }
      }
    });
  }

  setStep4Panel() {
    if (!(this.percentagePerParticipant === undefined || this.percentagePerParticipant === null)) {
      (document.getElementById('radioPercentParticipant') as any).checked = true;
      (document.getElementById('radioDollarAccount') as any).checked = false;
      (document.getElementById('radioDollarParticipant') as any).checked = false;
    } else if (!(this.amountPerAccount === undefined || this.amountPerAccount === null)) {
      (document.getElementById('radioPercentParticipant') as any).checked = false;
      (document.getElementById('radioDollarAccount') as any).checked = true;
      (document.getElementById('radioDollarParticipant') as any).checked = false;
    } else if (!(this.amountPerParticipant === undefined || this.amountPerParticipant === null)) {
      (document.getElementById('radioPercentParticipant') as any).checked = false;
      (document.getElementById('radioDollarAccount') as any).checked = false;
      (document.getElementById('radioDollarParticipant') as any).checked = true;
    }
  }

  setParticipantLevelDeductions(participant, indexp) {
    participant.deduction = this.filteredParticipantDataList[indexp].deduction;
    let indexa = 0;
    participant.accountBalances.forEach(account => {
      if (indexa < this.filteredParticipantDataList[indexp].accountBalances.length) {
        if (account.accountId === this.filteredParticipantDataList[indexp].accountBalances[indexa].accountId) {
          this.setAccountLevelDeductions(account, indexp, indexa);
          indexa++;
        }
      }
    });
  }

  setAccountLevelDeductions(account, indexp, indexa) {
    account.deduction = this.filteredParticipantDataList[indexp].accountBalances[indexa].deduction;
    let indexm = 0;
    account.moneyTypeBalances.forEach(moneyType => {
      if (indexm < this.filteredParticipantDataList[indexp].accountBalances[indexa].
        moneyTypeBalances.length) {
        if (moneyType.moneyTypeId === this.filteredParticipantDataList[indexp].accountBalances[indexa].
          moneyTypeBalances[indexm].moneyTypeId) {
          this.setMoneyLevelDeductins(moneyType, indexa, indexm, indexp);
          indexm++;
        }
      }
    });
  }

  setMoneyLevelDeductins(moneyType, indexa, indexm, indexp) {
    moneyType.deduction = this.filteredParticipantDataList[indexp].accountBalances[indexa].moneyTypeBalances[indexm].deduction;
    let indexf = 0;
    moneyType.fundBalances.forEach(fund => {
      if (indexf < this.filteredParticipantDataList[indexp].accountBalances[indexa].
        moneyTypeBalances[indexm].fundBalances.length) {
        if (fund.fundId === this.filteredParticipantDataList[indexp].accountBalances[indexa].
          moneyTypeBalances[indexm].fundBalances[indexf].fundId) {
          fund.deduction = this.filteredParticipantDataList[indexp].accountBalances[indexa].
            moneyTypeBalances[indexm].fundBalances[indexf].deduction;
          indexf++;
        }
      }
    });
  }

  validateStep4Inputs(inputType: number) {
    this.alertMessage = ' ';
    if (inputType === 1) {
      if (this.percentagePerParticipant === null || this.percentagePerParticipant === undefined) {
        this.error = 'nullError';
        this.showModal = 'transType';
        this.alertMessage = 'Please enter some value.';
        return false;
      } else if (this.percentagePerParticipant > 100 || this.percentagePerParticipant < 0) {
        this.error = 'percentError';
        this.showModal = 'transType';
        this.alertMessage = 'Please enter percentage values between 0 and 100.';
        return false;
      } else {
        this.error = 'noError';
        this.percentagePerParticipant = this.percentagePerParticipant.replace(/,/g, '');
      }
    } else if (inputType === 2) {
      if (this.amountPerAccount === null || this.amountPerAccount === undefined) {
        this.error = 'nullError';
        this.showModal = 'transType';
        this.alertMessage = 'Please enter some value.';
        return false;
      } else if (this.amountPerAccount < 0) {
        this.error = 'dollarError';
        this.showModal = 'transType';
        this.alertMessage = 'Please enter positive dollar amount.';
        return false;
      } else {
        this.error = 'noError';
      }
    } else if (inputType === 3) {
      if (this.amountPerParticipant === null || this.amountPerParticipant === undefined) {
        this.error = 'nullError';
        this.showModal = 'transType';
        this.alertMessage = 'Please enter some value.';
        return false;
      } else if (this.amountPerParticipant < 0) {
        this.error = 'dollarError';
        this.showModal = 'transType';
        this.alertMessage = 'Please enter positive dollar amount.';
        return false;
      } else {
        this.error = 'noError';
      }
    } else if (inputType === 4) {
      if (this.amountToDivideBetweenAllparticipant === null || this.amountToDivideBetweenAllparticipant === undefined) {
        this.error = 'nullError';
        this.showModal = 'transType';
        this.alertMessage = 'Please enter some value.';
        return false;
      } else if (this.amountToDivideBetweenAllparticipant < 0) {
        this.error = 'dollarError';
        this.showModal = 'transType';
        this.alertMessage = 'Please enter positive dollar amount.';
        return false;
      } else {
        this.error = 'noError';
      }
    } else {
      this.error = 'noError';
    }
    this.error = 'noError';
    return true;
  }

  resetDeductions() {
    this.isTransactionTypeSelected = false;
    this.isCalculationPerformed = false;
    this.participantDatalist.forEach(participantData => {
      participantData.deduction = null;
      participantData.accountBalances.forEach(accountData => {
        accountData.deduction = null;
        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          moneyTypeData.deduction = null;
          moneyTypeData.fundBalances.forEach(fundTypeData => {
            fundTypeData.deduction = null;
          });
        });
      });
    });
    this.clearDeductionsWithInterest();
  }

  resetDeductionsForStep4() {
    this.isTransactionTypeSelected = false;
    this.isCalculationPerformed = false;
    this.percentagePerParticipant = null;
    this.amountToDivideBetweenAllparticipant = null;
    this.amountPerAccount = null;
    this.amountPerParticipant = null;
    (document.getElementById('percentParticipant') as any).disabled = false;
    (document.getElementById('radioPercentParticipant') as any).checked = true;
    (document.getElementById('dollarAccount') as any).disabled = true;
    (document.getElementById('dollarAccount') as any).value = null;
    (document.getElementById('dollarParticipant') as any).disabled = true;
    (document.getElementById('dollarParticipant') as any).value = null;
    (document.getElementById('amountForAllParticipant') as any).value = null;
    (document.getElementById('amountForAllParticipant') as any).disabled = true;
    this.participantDatalist.forEach(participantData => {
      participantData.deduction = null;
      participantData.accountBalances.forEach(accountData => {
        accountData.deduction = null;
        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          moneyTypeData.deduction = null;
          moneyTypeData.fundBalances.forEach(fundTypeData => {
            fundTypeData.deduction = null;
          });
        });
      });
    });
    this.clearDeductionsWithInterest();
  }

  getFundElement(participantData: ParticipantData,
    accountData: AccountData,
    moneyTypeData: MoneyType,
    fundTypeData: FundType): HTMLElement {
    return document.getElementById('fund' +
      participantData.empId + ',' +
      accountData.accountId + ',' +
      moneyTypeData.moneyTypeId + ',' +
      fundTypeData.fundId);
  }

  getMoneyTypeElement(participantData: ParticipantData,
    accountData: AccountData,
    moneyTypeData: MoneyType): HTMLElement {
    return document.getElementById('moneyType' + participantData.empId + ',' + accountData.accountId + ',' + moneyTypeData.moneyTypeId);
  }

  getAccountElement(participantData: ParticipantData, accountData: AccountData): HTMLElement {
    return document.getElementById('account' + participantData.empId + ',' + accountData.accountId);
  }

  getParticipantElement(participantData: ParticipantData): HTMLElement {
    return document.getElementById('participant' + participantData.empId);
  }

  checkedPercentagePerParticipant() {
    this.checkedRadioOption1 = true;
    this.checkedRadioOption2 = false;
    this.checkedRadioOption3 = false;
    this.checkedRadioOption4 = false;
    if (this.selectedTransaction === 'Distribution' || this.selectedTransaction === "Forfeiture") {
      (document.getElementById('exceptionReport') as any).disabled = true;
      this.isPartialDeductionChecked = false;
      (document.getElementById('partialDeductionCheckBox') as any).disabled = true;
    } else {
      (document.getElementById('partialDeductionCheckBox') as any).disabled = true;
      (document.getElementById('exceptionReport') as any).disabled = true;
    }
    this.resetDeductions();
  }

  checkedAmountPerAccount() {
    this.checkedRadioOption1 = false;
    this.checkedRadioOption2 = true;
    this.checkedRadioOption3 = false;
    this.checkedRadioOption4 = false;
    if (this.selectedTransaction === 'Distribution' || this.selectedTransaction === 'Forfeiture') {
      (document.getElementById('exceptionReport') as any).disabled = true;
      this.isPartialDeductionChecked = false;
      (document.getElementById('partialDeductionCheckBox') as any).disabled = true;
    } else {
      (document.getElementById('partialDeductionCheckBox') as any).disabled = false;
      (document.getElementById('exceptionReport') as any).disabled = true;
    }
    this.resetDeductions();
  }

  checkedAmountPerParticipant() {
    this.checkedRadioOption1 = false;
    this.checkedRadioOption2 = false;
    this.checkedRadioOption3 = true;
    this.checkedRadioOption4 = false;
    if (this.selectedTransaction === 'Distribution' || this.selectedTransaction === 'Forfeiture') {
      (document.getElementById('exceptionReport') as any).disabled = true;
      this.isPartialDeductionChecked = false;
      (document.getElementById('partialDeductionCheckBox') as any).disabled = true;
    } else {
      (document.getElementById('partialDeductionCheckBox') as any).disabled = false;
      (document.getElementById('exceptionReport') as any).disabled = true;
    }
    this.resetDeductions();
  }

  checkedAmountForAllParticipant() {
    this.checkedRadioOption1 = false;
    this.checkedRadioOption2 = false;
    this.checkedRadioOption3 = false;
    this.checkedRadioOption4 = true;
    (document.getElementById('exceptionReport') as any).disabled = true;
    (document.getElementById('partialDeductionCheckBox') as any).disabled = true;
    this.resetDeductions();
  }

  calculateAmountPerAccount() {
    const amountPerAccount = this.amountPerAccount.replace(/,/g, '');
    this.isCalculationPerformed = true;
    this.isTransactionTypeSelected = true;
    let allMoneyTypeBalanceSumByAccount = 0;
    let allFundBalanceSumByMoneyType = 0;
    let allFundActualDeduction = 0;
    let allMoneyActualDeduction = 0;
    let allAccountActualDeduction = 0;
    let flagFundBalanceChange = false;
    let flagAccountBalanceChange = false;
    let flagMoneyBalanceChange = false;
    let flagPartialDeduction = false;

    this.filteredParticipantDataList.forEach(participantData => {
      let isAccountSkippedDueToPartialDeductions = false;
      participantData.deduction = 0;
      participantData.accountBalances.forEach(accountData => {
        let sumOfFundByAccount = 0;
        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          moneyTypeData.fundBalances.forEach(fundData => {
            sumOfFundByAccount = sumOfFundByAccount + Number(fundData[this.propertyName]);
          });
        });

        let flagAmountPerAccountExceedsEndCashBal = false;
        // const accountEndCashBal: number = Number(accountData[this.propertyName]);
        if (sumOfFundByAccount >= amountPerAccount) {
          accountData.deduction = Number(amountPerAccount).toFixed(2);
          participantData.deduction = Number(participantData.deduction) + Number(amountPerAccount);
        } else if (sumOfFundByAccount > 0) {
          accountData.deduction = Number(sumOfFundByAccount).toFixed(2);
          participantData.deduction = participantData.deduction + sumOfFundByAccount;
          flagAmountPerAccountExceedsEndCashBal = true;
        } else {
          accountData.deduction = Number(0).toFixed(2);
        }
        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          moneyTypeData.fundBalances.forEach(fundData => {
            flagPartialDeduction = false;
            // const moneyTypeEndCashBal: number = Number(moneyTypeData[this.propertyName]);
            allMoneyTypeBalanceSumByAccount = allMoneyTypeBalanceSumByAccount + Number(fundData[this.propertyName]);
          });
        });

        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          let moneyTypeEndCashBal = 0;
          moneyTypeData.fundBalances.forEach(fundData => {
            moneyTypeEndCashBal = moneyTypeEndCashBal + Number(fundData[this.propertyName]);
          });
          if (moneyTypeEndCashBal > 0) {
            moneyTypeData.deduction = Number((moneyTypeEndCashBal * accountData.deduction) /
              allMoneyTypeBalanceSumByAccount).toFixed(4);
          } else {
            moneyTypeData.deduction = Number(0).toFixed(2);
          }
          if (moneyTypeData.deduction === 'NaN') {
            moneyTypeData.deduction = '0.00';
          }
          moneyTypeData.fundBalances.forEach(fundBalancesData => {
            const fundEndCashBal: number = Number(fundBalancesData[this.propertyName]);
            allFundBalanceSumByMoneyType = allFundBalanceSumByMoneyType + fundEndCashBal;
          });
          moneyTypeData.fundBalances.forEach(fundBalancesData => {
            const fundEndCashBal: number = Number(fundBalancesData[this.propertyName]);
            if (fundEndCashBal > 0) {
              fundBalancesData.deduction = Number((fundEndCashBal * moneyTypeData.deduction) /
                allFundBalanceSumByMoneyType).toFixed(4);
              if (fundBalancesData.deduction === 'NaN') {
                fundBalancesData.deduction = '0.00';
              }

              if (Number(fundBalancesData.deduction) >= fundEndCashBal) {
                if (this.isPartialDeductionChecked
                  && (this.selectedTransaction === 'Balance Transfer' || this.selectedTransaction === 'Fee')
                  && Number(fundBalancesData.deduction) > fundEndCashBal) {
                  fundBalancesData.deduction = Number(0).toFixed(2);
                  flagPartialDeduction = true;
                } else if (this.isPartialDeductionChecked
                  && (this.selectedTransaction === 'Balance Transfer' || this.selectedTransaction === 'Fee')
                  && Number(fundBalancesData.deduction) === fundEndCashBal) {
                  if (flagAmountPerAccountExceedsEndCashBal === false) {
                    fundBalancesData.deduction = Number(fundEndCashBal).toFixed(2);
                    flagFundBalanceChange = true;
                  } else {
                    fundBalancesData.deduction = Number(0).toFixed(2);
                    flagPartialDeduction = true;
                  }
                } else {
                  fundBalancesData.deduction = Number(fundEndCashBal).toFixed(2);
                  flagFundBalanceChange = true;
                }
              } else {
                fundBalancesData.deduction = Number(fundBalancesData.deduction).toFixed(2);
              }
              allFundActualDeduction += Number(Number(fundBalancesData.deduction).toFixed(2));
            } else {
              fundBalancesData.deduction = Number(0).toFixed(2);
            }
          });
          allFundBalanceSumByMoneyType = 0;
          if (flagFundBalanceChange) {
            moneyTypeData.deduction = Number(allFundActualDeduction).toFixed(2);
            flagMoneyBalanceChange = true;
          } else if (flagPartialDeduction &&
            (this.selectedTransaction === 'Balance Transfer' || this.selectedTransaction === 'Fee')) {
            moneyTypeData.deduction = Number(0).toFixed(2);
          } else {
            moneyTypeData.deduction = Number(moneyTypeData.deduction).toFixed(2);
          }
          allMoneyActualDeduction += Number(moneyTypeData.deduction);
          allFundActualDeduction = 0;
          flagFundBalanceChange = false;
        });
        allMoneyTypeBalanceSumByAccount = 0;
        if (flagMoneyBalanceChange) {
          accountData.deduction = Number(allMoneyActualDeduction).toFixed(2);
          flagAccountBalanceChange = true;
        } else if (flagPartialDeduction &&
          (this.selectedTransaction === 'Balance Transfer' || this.selectedTransaction === 'Fee')) {
          accountData.deduction = Number(0).toFixed(2);
          this.exceptionList.push(accountData);
          isAccountSkippedDueToPartialDeductions = true;
        }
        allAccountActualDeduction += Number(accountData.deduction);
        allMoneyActualDeduction = 0;
        flagMoneyBalanceChange = false;
      });
      if (flagAccountBalanceChange || isAccountSkippedDueToPartialDeductions) {
        participantData.deduction = Number(allAccountActualDeduction).toFixed(2);
      } else if (flagPartialDeduction &&
        (this.selectedTransaction === 'Balance Transfer' || this.selectedTransaction === 'Fee')) {
        participantData.deduction = Number(0).toFixed(2);
      } else {
        participantData.deduction = Number(participantData.deduction).toFixed(2);
      }
      allAccountActualDeduction = 0;
      flagAccountBalanceChange = false;
      flagPartialDeduction = false;
    });
    this.convertDeductionAmountToTillTwoDecimalForAutoCalculations();
    this.updateDecimalDifferences();
    this.updateDeductionsWithInterest();

    let idx = 0;
    if (this.isPartialDeductionChecked &&
      (this.selectedTransaction === 'Balance Transfer' || this.selectedTransaction === 'Fee')) {
      this.participantDatalist.forEach(participantData => {
        if (idx === this.filteredParticipantDataList.length) {
          idx = this.filteredParticipantDataList.length - 1;
        }
        if (this.filteredParticipantDataList[idx].empId !== participantData.empId) {
          const participant = JSON.parse(JSON.stringify(participantData));
          participant.accountBalances.forEach(account => {
            account.moneyTypeBalances.forEach(moneyType => {
              moneyType.fundBalances.forEach(fund => {
                fund[this.propertyName] = 0;
              });
            });
            this.exceptionList.push(account);
          });
        } else {
          const participant = JSON.parse(JSON.stringify(participantData));
          let idxA = 0;
          if (idx === this.filteredParticipantDataList.length) {
            idx--;
          }
          participant.accountBalances.forEach(account => {
            if (idxA === this.filteredParticipantDataList[idx].accountBalances.length) {
              idxA = this.filteredParticipantDataList[idx].accountBalances.length - 1;
            }
            if (account.accountId !== this.filteredParticipantDataList[idx].accountBalances[idxA].accountId) {
              account.moneyTypeBalances.forEach(moneyType => {
                moneyType.fundBalances.forEach(fund => {
                  fund[this.propertyName] = 0;
                });
              });
              this.exceptionList.push(account);
            } else {
              idxA++;
            }
          });
          idx++;
        }
      });
      if (this.exceptionList.length > 0) {
        document.getElementById('hiddenButtonException').click();
        (document.getElementById('exceptionReport') as any).disabled = false;
      } else {
        (document.getElementById('exceptionReport') as any).disabled = true;
      }
    } else {
      (document.getElementById('exceptionReport') as any).disabled = true;
    }
  }

  exportexcel() {

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    worksheet.getColumn(1).width = 110;
    const companyName = 'Company Name: ' + this.companyName;
    const transactionDate = 'Transaction Date : ' + this.datepipe.transform(this.transDate, 'MM/dd/yyyy');
    const transactionType = 'Transaction Type : ' + this.selectedTransaction;
    let deductions = 0.0;
    this.participantDatalist.forEach((participant) => {
      deductions += parseFloat(participant.deduction);
    })
    const totalDeduction = 'Total Deduction : ' + deductions.toFixed(2);

    const companyNameRow = worksheet.addRow([companyName]);
    companyNameRow.font = { size: 11, bold: true };

    const transactionDateRow = worksheet.addRow([transactionDate]);
    transactionDateRow.font = { size: 11, bold: true };

    const transactionTypeRow = worksheet.addRow([transactionType]);
    transactionTypeRow.font = { size: 11, bold: true };

    const totalDeductionRow = worksheet.addRow([totalDeduction]);
    totalDeductionRow.font = { size: 11, bold: true };

    // excluded money types
    worksheet.addRow([]);
    const excludedMoneyTypes = 'Excluded Money Types:';
    const excludedMoneyTypesRow = worksheet.addRow([excludedMoneyTypes]);
    excludedMoneyTypesRow.font = { size: 11, bold: true };
    if (!this.excludeMoneyTypeListAfterButtonClick.length) {
      worksheet.addRow(['None Selected'])
    } else {
      this.excludeMoneyTypeListAfterButtonClick.forEach(excludedMoneyType => {
        worksheet.addRow([this.moneyTypeDetailsMap[excludedMoneyType]]);
      })
    }

    // excluded funds
    worksheet.addRow([]);
    const excludedFunds = 'Excluded Funds:';
    const excludedFundsRow = worksheet.addRow([excludedFunds]);
    excludedFundsRow.font = { size: 11, bold: true };
    if (!this.excludeFundListAfterButtonClick.length) {
      worksheet.addRow(['None Selected'])
    } else {
      this.excludeFundListAfterButtonClick.forEach(excludedFund => {
        let excludedFundRow = `${this.fundDetailsMap[excludedFund]} (${excludedFund})`;
        worksheet.addRow([excludedFundRow]);
      })
    }

    // deduction entry method
    worksheet.addRow([]);
    const deductionEntryMethod = 'Deduction Entry Method:';
    const deductionEntryMethodRow = worksheet.addRow([deductionEntryMethod]);
    deductionEntryMethodRow.font = { size: 11, bold: true };
    if (this.selectedEntryMethod === 'Upload') {
      worksheet.addRow(['Upload'])
    } else if (this.checkedRadioOption1) {
      worksheet.addRow([`${this.percentagePerParticipant}% per Participant`])
    } else if (this.checkedRadioOption2) {
      worksheet.addRow([`$${this.amountPerAccount} per Account`])
    } else if (this.checkedRadioOption3) {
      worksheet.addRow([`$${this.amountPerParticipant} per Participant`])
    } else if (this.checkedRadioOption4) {
      worksheet.addRow([`$${this.amountToDivideBetweenAllparticipant} Pro Rated Amount`])
    }


    worksheet.addRow([]);
    const entryMethod = ((this.selectedEntryMethod === '$ Entry') || (this.selectedEntryMethod === 'Upload')) ? '$' : '%';
    const header = ['Participant / Account / Money Type / Fund', '', 'Total Balance', 'Interest Accrued', 'Deduction (' + entryMethod + ')'];
    const headerRow = worksheet.addRow(header);

    headerRow.font = { size: 11, bold: true };

    worksheet.getColumn(3).width = 13;
    worksheet.getColumn(3).alignment = { horizontal: 'right' };
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(4).alignment = { horizontal: 'right' };
    worksheet.getColumn(5).width = 13;
    worksheet.getColumn(5).alignment = { horizontal: 'right' };

    const percentagePerParticipantForFunds = this.percentagePerParticipant === '100';

    // Add Data and Conditional Formatting
    if (this.selectedEntryMethod == '% Entry' &&
      (this.excludingFunds_showLabel_notInputBox ||
        this.excludingMoneyTypes_showLabel_notInputBox)) {
      this.participantDatalist.forEach(participantData => {
        worksheet.addRow([]);

        const participantLevelInterest: number = this.getInterestByParticipant(participantData);
        const participantLevelRow = [participantData.empName,
          '',
        (participantData[this.propertyName]).toString(),
        (Number(participantData.interestAccrued).toFixed(2)),
          'See fund level'];
        worksheet.addRow(participantLevelRow);

        participantData.accountBalances.forEach(accountData => {
          const accountLevelInterest: number = this.getInterestByAccount(accountData);
          const accountLevelRow = ['                   ' + accountData.planName + '[' + accountData.contractId + ']' + '-' + accountData.accountName + '[' + accountData.accountId + ']',
            '',
          (accountData[this.propertyName]).toString(),
          (Number(accountData.interestAccrued).toFixed(2)),
            'See fund level'];
          worksheet.addRow(accountLevelRow);

          accountData.moneyTypeBalances.forEach(moneyTypeData => {
            const fundLevelInterest: number = this.getInterestByMoneyType(moneyTypeData);
            const moneyTypeLevelRow = ['                             ' + moneyTypeData.moneyTypeName, '',
            (moneyTypeData[this.propertyName]).toString(), (Number(moneyTypeData.interestAccrued).toFixed(2)),
              'See fund level'];
            worksheet.addRow(moneyTypeLevelRow);

            let totalIncludedMoneyTypeInterest = 0;
            moneyTypeData.fundBalances.forEach(fundBalanceData => {
              const includeFundInterest = fundBalanceData.deduction === fundBalanceData.endCashBal;
              let deductionValue = '';
              if (!percentagePerParticipantForFunds) {
                deductionValue = includeFundInterest ?
                  ((Number(fundBalanceData.deduction) + Number(fundBalanceData.interestAccrued)).toFixed(2)) :
                  (Number(fundBalanceData.deduction).toFixed(2)).toString();
              } else {
                deductionValue = ((Number(fundBalanceData.endCashBal) + Number(fundBalanceData.interestAccrued)).toFixed(2));
              }
              const fundBalanceLevelRow = ['                                         ' + `${fundBalanceData.fundName} (${fundBalanceData.fundId})`, '',
              (fundBalanceData[this.propertyName]).toString(), (Number(fundBalanceData.interestAccrued).toFixed(2)),
                deductionValue
              ];
              if (includeFundInterest) {
                totalIncludedMoneyTypeInterest += Number(fundBalanceData.interestAccrued);
              }
              worksheet.addRow(fundBalanceLevelRow);
            });
          });
        });
      });
    } else {
      this.participantDatalist.forEach(participantData => {
        if (participantData.deduction > 0) {

          worksheet.addRow([]);

          const participantLevelInterest: number = this.getInterestByParticipant(participantData);
          const participantLevelRow = [participantData.empName,
            '',
          (participantData[this.propertyName]).toString(),
          (Number(participantData.interestAccrued).toFixed(2)),
          ((Number(participantData.deduction) + participantLevelInterest).toFixed(2))];
          worksheet.addRow(participantLevelRow);

          participantData.accountBalances.forEach(accountData => {
            if (accountData.deduction === null || accountData.deduction === undefined || accountData.deduction === '') {
              accountData.deduction = '0.00';
            }
            const accountLevelInterest: number = this.getInterestByAccount(accountData);
            const accountLevelRow = ['                   ' + accountData.planName + '[' + accountData.contractId + ']' + '-' + accountData.accountName + '[' + accountData.accountId + ']',
              '',
            (accountData[this.propertyName]).toString(),
            (Number(accountData.interestAccrued).toFixed(2)),
            ((Number(accountData.deduction) + accountLevelInterest).toFixed(2))];
            worksheet.addRow(accountLevelRow);
            accountData.moneyTypeBalances.forEach(moneyTypeData => {
              if (moneyTypeData.deduction === null || moneyTypeData.deduction === undefined || moneyTypeData.deduction === '') {
                moneyTypeData.deduction = '0.00';
              }

              const fundLevelInterest: number = this.getInterestByMoneyType(moneyTypeData);
              const moneyTypeLevelRow = ['                             ' + moneyTypeData.moneyTypeName, '',
              (moneyTypeData[this.propertyName]).toString(), (Number(moneyTypeData.interestAccrued).toFixed(2)),
              ((Number(moneyTypeData.deduction) + fundLevelInterest).toFixed(2))];
              worksheet.addRow(moneyTypeLevelRow);

              let totalIncludedMoneyTypeInterest = 0;
              moneyTypeData.fundBalances.forEach(fundBalanceData => {
                const includeFundInterest = fundBalanceData.deduction === fundBalanceData.endCashBal;
                if (fundBalanceData.deduction === null || fundBalanceData.deduction === undefined || fundBalanceData.deduction === '') {
                  fundBalanceData.deduction = '0.00';
                }
                let deductionValue = '';
                if (!percentagePerParticipantForFunds) {
                  deductionValue = includeFundInterest ?
                    ((Number(fundBalanceData.deduction) + Number(fundBalanceData.interestAccrued)).toFixed(2)) :
                    (Number(fundBalanceData.deduction).toFixed(2)).toString();
                } else {
                  deductionValue = ((Number(fundBalanceData.endCashBal) + Number(fundBalanceData.interestAccrued)).toFixed(2));
                }
                const fundBalanceLevelRow = ['                                         ' + `${fundBalanceData.fundName} (${fundBalanceData.fundId})`, '',
                (fundBalanceData[this.propertyName]).toString(), (Number(fundBalanceData.interestAccrued).toFixed(2)),
                  deductionValue
                ];
                if (includeFundInterest) {
                  totalIncludedMoneyTypeInterest += Number(fundBalanceData.interestAccrued);
                }
                worksheet.addRow(fundBalanceLevelRow);

              });
            });
          });
        }
      });
    }

    workbook.xlsx.writeBuffer().then(data => {
      const fileFormat = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(fileFormat, this.companyName.trim() + ' ' + this.datepipe.transform(this.transDate, 'MM/dd/yyyy') + ' Transaction Sheet.xlsx');
    });
  }

  getInterestByParticipant(participantData: ParticipantData): number {
    let totalParticipantInterest = 0;
    participantData.accountBalances.forEach(accountData => {
      totalParticipantInterest += this.getInterestByAccount(accountData);
    });
    return totalParticipantInterest;
  }

  getInterestByAccount(accountData: AccountData): number {
    let totalAccountInterest = 0;
    accountData.moneyTypeBalances.forEach(moneyTypeData => {
      totalAccountInterest += this.getInterestByMoneyType(moneyTypeData);
    });
    return totalAccountInterest;
  }

  getInterestByMoneyType(moneyTypeData: MoneyType): number {
    let totalMoneyTypeInterest = 0;
    moneyTypeData.fundBalances.forEach(fundBalanceData => {
      totalMoneyTypeInterest += this.getInterestByFund(fundBalanceData);
    });
    return totalMoneyTypeInterest;
  }

  getInterestByFund(fundData: FundType): number {
    if (Number(fundData.endCashBal).toFixed(2) === Number(fundData.deduction).toFixed(2)) {
      return Number(fundData.interestAccrued);
    } else {
      return 0;
    }
  }

  exportExcelWithExceptionReport() {

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    worksheet.getColumn(1).width = 80;
    worksheet.addRow([]);

    const header = ['Participant name', '', 'Total balance', 'Balance without excluded funds'];
    const headerRow = worksheet.addRow(header);
    headerRow.font = { size: 11, bold: true };
    worksheet.getColumn(3).width = 13;
    worksheet.getColumn(3).alignment = { horizontal: 'right' };
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(4).alignment = { horizontal: 'right' };
    // Add Data and Conditional Formatting
    if (this.checkedRadioOption2 === true) {
      if (this.selectedTransaction === 'Distribution' || this.selectedTransaction === 'Forfeiture') {
        this.exceptionList.forEach(participantData => {
          const participantLevelRow = [participantData.empName, '', ('$' + participantData.vestedCasBal).toString()];
          worksheet.addRow(participantLevelRow);
          participantData.accountBalances.forEach(accountData => {
            let balanceWithoutFilteredFunds = 0;
            accountData.moneyTypeBalances.forEach(moneyTypeBalance => {
              moneyTypeBalance.fundBalances.forEach(fundBalance => {
                balanceWithoutFilteredFunds += Number(fundBalance[this.propertyName]);
              });
            });
            const accountLevelRow = ['          ' + accountData.accountName, '',
            ('$' + accountData.vestedCasBal).toString(), ('$' + balanceWithoutFilteredFunds.toFixed(2)).toString()];
            worksheet.addRow(accountLevelRow);
          });
        });
      } else {
        this.exceptionList.forEach(account => {
          // const participantLevelRow = [participantData.empName, '', ('$' + participantData[this.propertyName]).toString()];
          // worksheet.addRow(participantLevelRow);
          let empName = '';
          let balanceWithoutFilteredFunds = 0;
          account.moneyTypeBalances.forEach(moneyType => {
            moneyType.fundBalances.forEach(fundBalance => {
              balanceWithoutFilteredFunds += Number(fundBalance[this.propertyName]);
              empName = fundBalance.empName;
            });
          });
          const accountLevelRow = [empName + '/' + account.accountName, '',
          ('$' + account[this.propertyName]).toString(), ('$' + balanceWithoutFilteredFunds.toFixed(2)).toString()];
          worksheet.addRow(accountLevelRow);
        });
      }
    } else if (this.checkedRadioOption3 === true) {
      if (this.selectedTransaction === 'Distribution' || this.selectedTransaction === "Forfeiture") {
        this.exceptionList.forEach(participantData => {
          const participantLevelRow = [participantData.empName, '', ('$' + participantData.vestedCasBal).toString()];
          worksheet.addRow(participantLevelRow);
        });
      } else {
        this.exceptionList.forEach(participantData => {
          let balanceWithoutFilteredFunds = 0;
          participantData.accountBalances.forEach(accountBalance => {
            accountBalance.moneyTypeBalances.forEach(moneyTypeBalance => {
              moneyTypeBalance.fundBalances.forEach(fundBalance => {
                balanceWithoutFilteredFunds += Number(fundBalance[this.propertyName]);
              });
            });
          });
          const participantLevelRow = [participantData.empName, '', ('$' + participantData[this.propertyName]).toString(),
          ('$' + balanceWithoutFilteredFunds.toFixed(2)).toString()];
          worksheet.addRow(participantLevelRow);
        });
      }
    }
    workbook.xlsx.writeBuffer().then(data => {
      const fileFormat = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(fileFormat, 'Print Exception Report Sheet.xlsx');
    });
  }

  setFundForTransfer(event, type: string, fundName: string, fundId: number) {
    if (!event.target.checked) {
      this.clearFundSelection(type);
      return;
    }

    let newFund: FundDetails = {
      fundId: fundId,
      fundName: fundName,
      companyId: Number(this.companyId)
    };

    switch (type) {
      case 'source':
        this.selectedSourceFundForTransfer = newFund;
        break;
      case 'target':
        this.selectedTargetFundForTransfer = newFund;
        break;
    }
  }

  clearFundSelection(type: string) {
    switch (type) {
      case 'source':
        this.selectedSourceFundForTransfer = null;
        break;
      case 'target':
        this.selectedTargetFundForTransfer = null;
    }
  }

  setFundIds(event) {
    if (event.target.checked === true && this.excludeFundList.includes(parseInt(event.target.value, 10)) === false) {
      this.excludeFundList.push(parseInt(event.target.value, 10));
    } else {
      this.excludeFundList = this.excludeFundList.filter(fundId => fundId !== parseInt(event.target.value, 10));
    }
  }

  setMoneyTypeIds(event) {
    if (event.target.checked === true && this.excludeMoneyTypeList.includes(parseInt(event.target.value, 10)) === false) {
      this.excludeMoneyTypeList.push(parseInt(event.target.value, 10));
    } else {
      this.excludeMoneyTypeList = this.excludeMoneyTypeList.filter(moneyTypeId => moneyTypeId !== parseInt(event.target.value, 10));
    }
  }

  filterMoneyTypes() {
    if (this.excludeMoneyTypeList.length !== 0) {
      this.flagShowNumberOfMoneyExcluded = true;
      this.numberOFMoneyExcluded = this.excludeMoneyTypeListAfterButtonClick.length;
      this.excludingMoneyTypes_showLabel_notInputBox = true;

    } else {
      this.flagShowNumberOfMoneyExcluded = false;
      this.excludingMoneyTypes_showLabel_notInputBox = false;

    }
    if (this.excludeMoneyTypeList.length > 0) {
      this.filteredParticipantDataList.forEach(participantData => {
        participantData.accountBalances.forEach(accountBalance => {
          accountBalance.moneyTypeBalances = accountBalance.moneyTypeBalances.filter(moneyType =>
            this.excludeMoneyTypeList.includes(moneyType.moneyTypeId) !== true
          );
        });
        participantData.accountBalances = participantData.accountBalances.filter(accountBalance =>
          accountBalance.moneyTypeBalances.length !== 0
        );
      });
      this.filteredParticipantDataList = this.filteredParticipantDataList.filter(participant =>
        participant.accountBalances.length !== 0
      );
    }
    this.disableManualDeductionsField();
  }

  filterFunds() {
    if (this.excludeFundList.length !== 0) {
      this.flagShowNumberOfFundsExcluded = true;
      this.numberOFFundsExcluded = this.excludeFundListAfterButtonClick.length;
      this.excludingFunds_showLabel_notInputBox = true;
    } else {
      this.flagShowNumberOfFundsExcluded = false;
      this.excludingFunds_showLabel_notInputBox = false;
    }
    if (this.excludeFundList.length > 0) {
      this.filteredParticipantDataList.forEach(participantData => {
        participantData.accountBalances.forEach(accountBalance => {
          accountBalance.moneyTypeBalances.forEach(moneyType => {
            moneyType.fundBalances = moneyType.fundBalances.filter(fund =>
              this.excludeFundList.includes(fund.fundId) !== true
            );
          });
          accountBalance.moneyTypeBalances = accountBalance.moneyTypeBalances.filter(moneyType =>
            moneyType.fundBalances.length !== 0
          );
        });
        participantData.accountBalances = participantData.accountBalances.filter(accountBalance =>
          accountBalance.moneyTypeBalances.length !== 0
        );
      });
      this.filteredParticipantDataList = this.filteredParticipantDataList.filter(participant =>
        participant.accountBalances.length !== 0
      );
    }
    this.disableManualDeductionsField();
  }

  disableManualDeductionsField() {
    const listOfIds = [];
    this.participantDatalist.forEach(participantData => {
      const empId = participantData.empId;
      const totalNumberOFAccounts = participantData.accountBalances.length;
      let countOfAccountsExcluded = 0;
      participantData.accountBalances.forEach(accountBalance => {
        const accountId = accountBalance.accountId;
        let countOfMoneyTypesExcluded = 0;
        const totalNumberOFMoneyTypes = accountBalance.moneyTypeBalances.length;
        accountBalance.moneyTypeBalances.forEach(moneyType => {
          const moneyId = moneyType.moneyTypeId;
          let countOfFundsExcluded = 0;
          const totalNumberOfFunds = moneyType.fundBalances.length;
          let strId = empId + ',' + accountId + ',' + moneyId;
          if (this.excludeMoneyTypeList.includes(moneyType.moneyTypeId)) {
            listOfIds.push(strId);
            (document.getElementById(strId) as any).disabled = true;
            document.getElementById('name' + strId).classList.add('strikeThrough');
            document.getElementById(strId).setAttribute('title', 'You can\'t add deductions as money type is filtered out.');
            countOfMoneyTypesExcluded++;
            moneyType.fundBalances.forEach(fundBalance => {
              const fundId = fundBalance.fundId;
              strId = empId + ',' + accountId + ',' + moneyId + ',' + fundId;
              listOfIds.push(strId);
              (document.getElementById(strId) as any).disabled = true;
              document.getElementById('name' + strId).classList.add('strikeThrough');
              document.getElementById(strId).setAttribute('title', 'You can\'t add deductions as fund is filtered out.');
              countOfFundsExcluded++;
            });
          } else {
            (document.getElementById(strId) as any).disabled = false;
            document.getElementById('name' + strId).classList.remove('strikeThrough');
            document.getElementById(strId).removeAttribute('title');
            moneyType.fundBalances.forEach(fundBalance => {
              const fundId = fundBalance.fundId;
              strId = empId + ',' + accountId + ',' + moneyId + ',' + fundId;
              listOfIds.push(strId);
              if (this.excludeFundList.includes(fundBalance.fundId)) {
                (document.getElementById(strId) as any).disabled = true;
                document.getElementById('name' + strId).classList.add('strikeThrough');
                document.getElementById(strId).setAttribute('title', 'You can\'t add deductions as fund is filtered out.');
                countOfFundsExcluded++;
              } else {
                (document.getElementById(strId) as any).disabled = false;
                document.getElementById('name' + strId).classList.remove('strikeThrough');
                document.getElementById(strId).removeAttribute('title');
              }
            });
            if (countOfFundsExcluded === totalNumberOfFunds) {
              strId = empId + ',' + accountId + ',' + moneyType.moneyTypeId;
              (document.getElementById(strId) as any).disabled = true;
              document.getElementById('name' + strId).classList.add('strikeThrough');
              document.getElementById(strId).setAttribute('title', 'You can\'t add deductions as all funds are filtered out.');
              countOfMoneyTypesExcluded++;
            }
          }
        });
        if (countOfMoneyTypesExcluded === totalNumberOFMoneyTypes) {
          const strId = empId + ',' + accountId;
          (document.getElementById(strId) as any).disabled = true;
          document.getElementById(strId).setAttribute('title', 'You can\'t add deductions as all funds/money types are filtered out.');
          // document.getElementById('name' + strId).classList.add('strikeThrough');
          countOfAccountsExcluded++;
        } else {
          const strId = empId + ',' + accountId;
          (document.getElementById(strId) as any).disabled = false;
          document.getElementById(strId).removeAttribute('title');
          // document.getElementById('name' + strId).classList.remove('strikeThrough');
        }
      });
      if (totalNumberOFAccounts === countOfAccountsExcluded) {
        const strId = '' + empId;
        (document.getElementById(strId) as any).disabled = true;
        document.getElementById(strId).setAttribute('title', 'You can\'t add deductions as all funds/money types are filtered out.');
      } else {
        const strId = '' + empId;
        (document.getElementById(strId) as any).disabled = false;
        document.getElementById(strId).removeAttribute('title');
      }
    });
  }

  filterFundsEvent() {
    this.filteredParticipantDataList = JSON.parse(JSON.stringify(this.masterParticipantDataList));
    this.resetDeductions();
    this.excludeFundListAfterButtonClick = JSON.parse(JSON.stringify(this.excludeFundList));
    this.filterFunds();
    if (this.excludeMoneyTypeListAfterButtonClick.length !== 0) {
      this.filterMoneyTypes();
    }
  }

  filterMoneyTypesEvent() {
    this.filteredParticipantDataList = JSON.parse(JSON.stringify(this.masterParticipantDataList));
    this.resetDeductions();
    this.excludeMoneyTypeListAfterButtonClick = JSON.parse(JSON.stringify(this.excludeMoneyTypeList));
    this.filterMoneyTypes();
    if (this.excludeFundListAfterButtonClick.length !== 0) {
      this.filterFunds();
    }
  }

  showModalApplyFilterMoney() {
    let flagShowModal = false;
    this.participantDatalist.forEach(participant => {
      if (participant.deduction > 0) {
        flagShowModal = true;
      }
    });
    if (flagShowModal) {
      document.getElementById('hiddenButtonMoneyFilters').click();
    } else {
      this.filterMoneyTypesEvent();
    }
  }

  showModalApplyFilterFund() {
    let flagShowModal = false;
    this.participantDatalist.forEach(participant => {
      if (participant.deduction > 0) {
        flagShowModal = true;
      }
    });
    if (flagShowModal) {
      document.getElementById('hiddenButtonFundFilters').click();
    } else {
      this.filterFundsEvent();
    }
  }

  manualDeductionFromParticipanltLevel(id: any, value: any, key) {
    if (this.selectedTransaction == 'Fund Transfer') {
      return;
    }
    this.validateTransType();
    if (this.isValidTransaction === true) {
      document.getElementById('id' + id).style.display = 'none';
      if (key !== 'Tab') {
        this.isCalculationPerformed = true;
        this.isTransactionTypeSelected = true;
        let allAccountCashBalanceSum = 0;
        let allMoneyTypeBalanceSumByAccount = 0;
        let allFundBalanceSumByMoneyType = 0;
        let allFundActualDeductions = 0.00;
        let flagFundDeductionChange = false;
        let allMoneyActualDeductions = 0.00;
        let flagMoneyDeductionChange = false;
        let allAccountActualDeductions = 0.00;
        let flagAccountDeductionChange = false;
        let allFundBalancesSum = 0;

        const amountPerParticipant = value.replace(/,/g, '');
        const isPercentBased = this.selectedEntryMethod === '% Entry';

        this.filteredParticipantDataList.forEach(participantData => {

          if (participantData.empId.toString() === id) {
            if (isPercentBased) {
              participantData.deduction = value;
              participantData.accountBalances.forEach(accountData => {
                accountData.deduction = Number(value).toFixed(2);
                accountData.moneyTypeBalances.forEach(moneyType => {
                  moneyType.deduction = Number(value).toFixed(2);
                  moneyType.fundBalances.forEach(fund => {
                    fund.deduction = Number(value).toFixed(2);
                  });
                });
              });
            } else {
              participantData.accountBalances.forEach(account => {
                account.moneyTypeBalances.forEach(moneyType => {
                  moneyType.fundBalances.forEach(fund => {
                    allFundBalancesSum = allFundBalancesSum + Number(fund[this.propertyName]);
                  });
                });
              });

              if (allFundBalancesSum > Number(amountPerParticipant)) {
                participantData.deduction = amountPerParticipant;
              } else if (allFundBalancesSum <= Number(amountPerParticipant)) {
                participantData.deduction = Number(allFundBalancesSum).toFixed(2);
              } else if (amountPerParticipant === '.') {
                participantData.deduction = '0.';
              }

              participantData.accountBalances.forEach(accountData => {
                accountData.moneyTypeBalances.forEach(moneyTypeData => {
                  moneyTypeData.fundBalances.forEach(fund => {
                    allMoneyTypeBalanceSumByAccount = allMoneyTypeBalanceSumByAccount + Number(fund[this.propertyName]);
                  });
                });

                accountData.deduction = Number((allMoneyTypeBalanceSumByAccount * participantData.deduction) /
                  allFundBalancesSum).toFixed(2);
                if (accountData.deduction === 'NaN') {
                  accountData.deduction = '0.00';
                }
                accountData.moneyTypeBalances.forEach(moneyTypeData => {
                  moneyTypeData.fundBalances.forEach(fundBalancesData => {
                    const fundEndCashBal: number = Number(fundBalancesData[this.propertyName]);
                    allFundBalanceSumByMoneyType = allFundBalanceSumByMoneyType + fundEndCashBal;
                  });
                  moneyTypeData.deduction = Number((allFundBalanceSumByMoneyType * Number(accountData.deduction)) /
                    allMoneyTypeBalanceSumByAccount).toFixed(2);
                  if (moneyTypeData.deduction === 'NaN') {
                    moneyTypeData.deduction = '0.00';
                  }

                  moneyTypeData.fundBalances.forEach(fundBalancesData => {
                    const fundEndCashBal: number = Number(fundBalancesData[this.propertyName]);
                    fundBalancesData.deduction = Number((fundEndCashBal * Number(moneyTypeData.deduction)) /
                      allFundBalanceSumByMoneyType).toFixed(2);
                    if (fundBalancesData.deduction === 'NaN') {
                      fundBalancesData.deduction = '0.00';
                    }

                    if (Number(fundBalancesData.deduction) > fundEndCashBal) {

                      fundBalancesData.deduction = fundEndCashBal;
                      flagFundDeductionChange = true;
                    }
                    allFundActualDeductions += Number(fundBalancesData.deduction);
                  });
                  allFundBalanceSumByMoneyType = 0;
                  if (flagFundDeductionChange) {
                    moneyTypeData.deduction = Number(allFundActualDeductions).toFixed(2);
                    flagMoneyDeductionChange = true;
                  }
                  allMoneyActualDeductions += Number(moneyTypeData.deduction);
                  allFundActualDeductions = 0;
                });
                allMoneyTypeBalanceSumByAccount = 0;
                if (flagMoneyDeductionChange) {
                  accountData.deduction = Number(allMoneyActualDeductions).toFixed(2);
                  flagAccountDeductionChange = true;
                }
                allAccountActualDeductions += Number(accountData.deduction);
                allMoneyActualDeductions = 0;
              });
              allAccountCashBalanceSum = 0;
              // if (flagAccountDeductionChange) {
              // participantData.deduction = Number(allAccountActualDeductions).toFixed(2);
              // }
              allAccountActualDeductions = 0;

              this.updateDecimalDifferences();
              this.updateDeductionsWithInterest();
              this.updateTotalCells();
            }
          }
        });

        this.syncFilteredAndParticipantDataList();
      }
    } else {
      document.getElementById('id' + id).style.display = 'block';
    }
  }

  manualDeductionFromAccountLevel(id: string, value: any, key) {
    if (this.selectedTransaction == 'Fund Transfer') {
      return;
    }
    this.validateTransType();
    if (this.isValidTransaction === true) {
      document.getElementById('id' + id).style.display = 'none';
      if (key !== 'Tab') {
        this.isCalculationPerformed = true;
        this.isTransactionTypeSelected = true;

        let allMoneyTypeBalanceSumByAccount = 0;
        let allFundBalanceSumByMoneyType = 0;
        let allFundActualDeductions = 0;
        let flagFundDeductionChange = false;
        let allMoneyActualDeductions = 0.00;
        let flagMoneyDeductionChange = false;
        let allFundBalancesSum = 0;

        const amountPerAccount = value.replace(/,/g, '');
        const ids = id.split(',');
        const isPercentBased = this.selectedEntryMethod === '% Entry';

        this.filteredParticipantDataList.forEach(participantData => {

          participantData.accountBalances.forEach(accountData => {
            if (isPercentBased) {
              if (participantData.empId.toString() === ids[0] && accountData.accountId.toString() === ids[1]) {
                accountData.deduction = value;
                accountData.moneyTypeBalances.forEach(moneyType => {
                  moneyType.deduction = Number(value).toFixed(2);
                  moneyType.fundBalances.forEach(fund => {
                    fund.deduction = Number(value).toFixed(2);
                  });
                });
              }
            } else if (participantData.empId.toString() === ids[0] && accountData.accountId.toString() === ids[1]) {
              accountData.moneyTypeBalances.forEach(moneyType => {
                moneyType.fundBalances.forEach(fund => {
                  allFundBalancesSum = allFundBalancesSum + Number(fund[this.propertyName]);
                });
              });

              if (allFundBalancesSum > amountPerAccount) {
                accountData.deduction = amountPerAccount;
                participantData.deduction = amountPerAccount;
              } else if (allFundBalancesSum <= amountPerAccount) {
                accountData.deduction = Number(allFundBalancesSum).toFixed(2);
                participantData.deduction = allFundBalancesSum;
              } else if (amountPerAccount.toString() === '.') {
                accountData.deduction = '0.';
              }

              accountData.moneyTypeBalances.forEach(moneyTypeData => {
                let moneyTypeEndCashBal = 0;
                moneyTypeData.fundBalances.forEach(fund => {
                  moneyTypeEndCashBal = moneyTypeEndCashBal + Number(fund[this.propertyName]);
                });

                if (isPercentBased) {
                  moneyTypeData.deduction = accountData.deduction;
                } else {
                  moneyTypeData.deduction = Number((moneyTypeEndCashBal * accountData.deduction) /
                    allFundBalancesSum).toFixed(2);
                }
                if (moneyTypeData.deduction === 'NaN') {
                  moneyTypeData.deduction = '0.00';
                }

                moneyTypeData.fundBalances.forEach(fundBalancesData => {
                  const fundEndCashBal: number = Number(fundBalancesData[this.propertyName]);
                  allFundBalanceSumByMoneyType = allFundBalanceSumByMoneyType + fundEndCashBal;
                });
                moneyTypeData.fundBalances.forEach(fundBalancesData => {
                  const fundEndCashBal: number = Number(fundBalancesData[this.propertyName]);
                  if (isPercentBased) {
                    fundBalancesData.deduction = moneyTypeData.deduction;
                  } else {
                    fundBalancesData.deduction = Number((fundEndCashBal * moneyTypeData.deduction) /
                      allFundBalanceSumByMoneyType).toFixed(2);
                  }
                  if (fundBalancesData.deduction === 'NaN') {
                    fundBalancesData.deduction = '0.00';
                  }
                  if (Number(fundBalancesData.deduction) > fundEndCashBal) {
                    fundBalancesData.deduction = fundEndCashBal;
                    flagFundDeductionChange = true;
                  }
                  allFundActualDeductions += Number(fundBalancesData.deduction);
                });
                allFundBalanceSumByMoneyType = 0;
                if (flagFundDeductionChange) {
                  moneyTypeData.deduction = Number(allFundActualDeductions).toFixed(2);
                  flagMoneyDeductionChange = true;
                }
                allMoneyActualDeductions += Number(moneyTypeData.deduction);
                allFundActualDeductions = 0;
              });
              allMoneyTypeBalanceSumByAccount = 0;
              // if (flagMoneyDeductionChange) {
              // accountData.deduction = Number(allMoneyActualDeductions).toFixed(2);
              //  }
              allMoneyActualDeductions = 0;

              this.commonDeductionCalculationForAllLevelAccountType(ids);
              this.updateDecimalDifferences();
              this.updateDeductionsWithInterest();
              this.updateTotalCells();
            }
          });

          if (isPercentBased) {
            let totalAccountPercentsToCashBal = Number(0);
            participantData.accountBalances.forEach(accountBalance => {
              totalAccountPercentsToCashBal = totalAccountPercentsToCashBal
                + (Number(accountBalance.deduction) * Number(accountBalance.endCashBal));
            });
            participantData.deduction = Number(totalAccountPercentsToCashBal / Number(participantData.endCashBal)).toFixed(2);
          }
        });

        this.syncFilteredAndParticipantDataList();
      }
    } else {
      document.getElementById('id' + id).style.display = 'block';
    }
  }

  manualDeductionFromMoneyTypeLevel(id: string, value: any, key) {
    if (this.selectedTransaction == 'Fund Transfer') {
      return;
    }
    this.validateTransType();
    if (this.isValidTransaction === true) {
      document.getElementById('id' + id).style.display = 'none';
      if (key !== 'Tab') {
        this.isCalculationPerformed = true;
        this.isTransactionTypeSelected = true;

        let allFundActualDeductions = 0;
        let flagFundDeductionChange = false;

        const amountPerMoneyType = value.replace(/,/g, '');
        const ids = id.split(',');
        const isPercentBased = this.selectedEntryMethod === '% Entry';

        this.filteredParticipantDataList.forEach(participantData => {
          participantData.accountBalances.forEach(accountData => {
            accountData.moneyTypeBalances.forEach(moneyTypeData => {
              if (participantData.empId.toString() === ids[0]
                && accountData.accountId.toString() === ids[1]
                && moneyTypeData.moneyTypeId.toString() === ids[2]) {
                if (isPercentBased) {
                  moneyTypeData.deduction = value;
                  moneyTypeData.fundBalances.forEach(fund => {
                    fund.deduction = Number(value).toFixed(2);
                  });
                } else {
                  let allFundBalancesSum = 0;
                  moneyTypeData.fundBalances.forEach(fund => {
                    allFundBalancesSum = allFundBalancesSum + Number(fund[this.propertyName]);
                  });

                  if (Number(allFundBalancesSum) > amountPerMoneyType) {
                    moneyTypeData.deduction = amountPerMoneyType;
                    participantData.deduction = amountPerMoneyType;
                    accountData.deduction = amountPerMoneyType;
                  } else if (Number(allFundBalancesSum) <= amountPerMoneyType) {
                    moneyTypeData.deduction = Number(allFundBalancesSum).toFixed(2);
                    participantData.deduction = Number(allFundBalancesSum);
                    accountData.deduction = Number(allFundBalancesSum);
                  } else if (amountPerMoneyType.toString() === '.') {
                    moneyTypeData.deduction = '0.';
                  }
                  // moneyTypeData.fundBalances.forEach(fundBalancesData => {
                  //   const fundEndCashBal: number = Number(fundBalancesData[this.propertyName]);
                  //  allFundBalanceSumByMoneyType = allFundBalanceSumByMoneyType + fundEndCashBal;
                  // });
                  moneyTypeData.fundBalances.forEach(fundBalancesData => {
                    const fundEndCashBal: number = Number(fundBalancesData[this.propertyName]);
                    fundBalancesData.deduction = Number((fundEndCashBal * moneyTypeData.deduction) /
                      allFundBalancesSum).toFixed(2);
                    if (fundBalancesData.deduction === 'NaN') {
                      fundBalancesData.deduction = '0.00';
                    }
                    if (Number(fundBalancesData.deduction) > fundEndCashBal) {
                      fundBalancesData.deduction = Number(fundEndCashBal).toFixed(2);
                      flagFundDeductionChange = true;
                    }
                    allFundActualDeductions += Number(fundBalancesData.deduction);
                  });
                  if (flagFundDeductionChange) {
                    moneyTypeData.deduction = Number(allFundActualDeductions).toFixed(2);
                  }

                  this.commonDeductionCalculationForAllLevelMoneyType(ids);
                  this.updateDecimalDifferences();
                  this.updateDeductionsWithInterest();
                  this.updateTotalCells();
                }
              }
            });

            if (isPercentBased) {
              let totalMoneyTypePercentsToCashBal = Number(0);
              accountData.moneyTypeBalances.forEach(moneyTypeBalance => {
                totalMoneyTypePercentsToCashBal = totalMoneyTypePercentsToCashBal
                  + (Number(moneyTypeBalance.deduction) * Number(moneyTypeBalance.endCashBal));
              });
              accountData.deduction = Number(totalMoneyTypePercentsToCashBal / Number(accountData.endCashBal)).toFixed(2);
            }
          });

          if (isPercentBased) {
            let totalAccountPercentsToCashBal = Number(0);
            participantData.accountBalances.forEach(accountBalance => {
              totalAccountPercentsToCashBal = totalAccountPercentsToCashBal
                + (Number(accountBalance.deduction) * Number(accountBalance.endCashBal));
            });
            participantData.deduction = Number(totalAccountPercentsToCashBal / Number(participantData.endCashBal)).toFixed(2);
          }
        });

        this.syncFilteredAndParticipantDataList();
      }
    } else {
      document.getElementById('id' + id).style.display = 'block';
    }
  }

  manualDeductionFromFundTypeLevel(id: string, value: any, key) {
    if (this.selectedTransaction == 'Fund Transfer') {
      return;
    }
    this.validateTransType();

    if (this.isValidTransaction === true) {
      document.getElementById('id' + id).style.display = 'none';
      if (key !== 'Tab') {
        this.isCalculationPerformed = true;
        this.isTransactionTypeSelected = true;

        const amountPerFundType = value.replace(/,/g, '');
        const ids = id.split(',');
        const isPercentBased = this.selectedEntryMethod === '% Entry';

        this.filteredParticipantDataList.forEach(participantData => {
          if (participantData.empId.toString() === ids[0]) {
            participantData.accountBalances.forEach(accountData => {
              if (accountData.accountId.toString() === ids[1]) {
                accountData.moneyTypeBalances.forEach(moneyTypeData => {
                  if (moneyTypeData.moneyTypeId.toString() === ids[2]) {
                    moneyTypeData.fundBalances.forEach(fundTypeData => {
                      if (fundTypeData.fundId.toString() === ids[3]) {
                        if (isPercentBased) {
                          fundTypeData.deduction = value;
                        } else {
                          if (Number(fundTypeData[this.propertyName]) > amountPerFundType) {
                            fundTypeData.deduction = amountPerFundType;
                          } else if (Number(fundTypeData[this.propertyName]) <= amountPerFundType) {
                            fundTypeData.deduction = fundTypeData[this.propertyName];
                          } else if (amountPerFundType.toString() === '.') {
                            fundTypeData.deduction = '0.';
                          }

                          this.commonDeductionCalculationForAllLevelFundType(ids);
                          this.updateDecimalDifferences();
                          this.updateDeductionsWithInterest();
                          this.updateTotalCells();
                        }
                      }

                      if (isPercentBased) {
                        let totalFundTypePercentsToCashBal = Number(0);
                        moneyTypeData.fundBalances.forEach(fundTypeBalance => {
                          totalFundTypePercentsToCashBal = totalFundTypePercentsToCashBal
                            + (Number(fundTypeBalance.deduction) * Number(fundTypeBalance.endCashBal));
                        });
                        moneyTypeData.deduction = Number(totalFundTypePercentsToCashBal / Number(moneyTypeData.endCashBal)).toFixed(2);
                      }
                    });
                  }
                  if (isPercentBased) {
                    let totalMoneyTypePercentsToCashBal = Number(0);
                    accountData.moneyTypeBalances.forEach(moneyTypeBalance => {
                      totalMoneyTypePercentsToCashBal = totalMoneyTypePercentsToCashBal
                        + (Number(moneyTypeBalance.deduction) * Number(moneyTypeBalance.endCashBal));
                    });
                    accountData.deduction = Number(totalMoneyTypePercentsToCashBal / Number(accountData.endCashBal)).toFixed(2);
                  }
                });
                if (isPercentBased) {
                  let totalAccountPercentsToCashBal = Number(0);
                  participantData.accountBalances.forEach(accountBalance => {
                    totalAccountPercentsToCashBal = totalAccountPercentsToCashBal
                      + (Number(accountBalance.deduction) * Number(accountBalance.endCashBal));
                  });
                  participantData.deduction = Number(totalAccountPercentsToCashBal / Number(participantData.endCashBal)).toFixed(2);
                }
              }
            });
          }
        });

        this.syncFilteredAndParticipantDataList();
      }
    } else {
      document.getElementById('id' + id).style.display = 'block';
    }
  }


  commonDeductionCalculationForAllLevelMoneyType(id) {

    this.filteredParticipantDataList.forEach(participantData => {
      if (participantData.empId.toString() === id[0]) {
        participantData.accountBalances.forEach(accountData => {
          if (accountData.accountId.toString() === id[1]) {
            accountData.deduction = 0;
            accountData.moneyTypeBalances.forEach(moneyTypeData => {
              if (moneyTypeData.deduction > 0) {
                accountData.deduction = Number(Number(accountData.deduction) + Number(moneyTypeData.deduction)).toFixed(2);
              }
            });
          }
        });
      }
    });

    this.filteredParticipantDataList.forEach(participantData => {
      if (participantData.empId.toString() === id[0]) {
        participantData.deduction = 0;
        participantData.accountBalances.forEach(accountData => {
          if (accountData.deduction > 0) {
            participantData.deduction = Number(Number(participantData.deduction) + Number(accountData.deduction)).toFixed(2);
          }
        });
      }
    });

    this.syncFilteredAndParticipantDataList();
  }

  commonDeductionCalculationForAllLevelAccountType(id) {
    this.filteredParticipantDataList.forEach(participantData => {
      if (participantData.empId.toString() === id[0]) {
        participantData.deduction = 0;
        participantData.accountBalances.forEach(accountData => {
          if (accountData.deduction > 0) {
            participantData.deduction = Number(Number(participantData.deduction) + Number(accountData.deduction)).toFixed(2);
          }
        });
      }
    });
    this.syncFilteredAndParticipantDataList();
  }

  commonDeductionCalculationForAllLevelFundType(id) {
    this.filteredParticipantDataList.forEach(participantData => {
      if (participantData.empId.toString() === id[0]) {
        participantData.accountBalances.forEach(accountData => {
          if (accountData.accountId.toString() === id[1]) {
            accountData.moneyTypeBalances.forEach(moneyTypeData => {
              if (moneyTypeData.moneyTypeId.toString() === id[2]) {
                moneyTypeData.deduction = 0;
                moneyTypeData.fundBalances.forEach(fundTypeData => {
                  if (fundTypeData.deduction > 0) {
                    moneyTypeData.deduction = Number(Number(moneyTypeData.deduction) + Number(fundTypeData.deduction)).toFixed(2);
                  }
                });
              }
            });
          }
        });
      }
    });

    this.filteredParticipantDataList.forEach(participantData => {
      if (participantData.empId.toString() === id[0]) {
        participantData.accountBalances.forEach(accountData => {
          if (accountData.accountId.toString() === id[1]) {
            accountData.deduction = 0;
            accountData.moneyTypeBalances.forEach(moneyTypeData => {
              if (moneyTypeData.deduction > 0) {
                accountData.deduction = Number(Number(accountData.deduction) + Number(moneyTypeData.deduction)).toFixed(2);
              }
            });
          }
        });
      }
    });

    this.filteredParticipantDataList.forEach(participantData => {
      if (participantData.empId.toString() === id[0]) {
        participantData.deduction = 0;
        participantData.accountBalances.forEach(accountData => {
          if (accountData.deduction > 0) {
            participantData.deduction = Number(Number(participantData.deduction) + Number(accountData.deduction)).toFixed(2);
          }
        });
      }
    });
    this.syncFilteredAndParticipantDataList();
  }

  updateFilteredParticipantListAfterManualDeduction() {

    this.filteredParticipantDataList = JSON.parse(JSON.stringify(
      this.participantDatalist.filter(participantData => participantData.deduction != null)));

    this.filteredParticipantDataList.forEach(participantData => {
      participantData.accountBalances.forEach(accountData => {
        if (accountData.deduction === null) {
          accountData.deduction = '0.00';
        }
      });
    });

    this.filteredParticipantDataList.forEach(participantData => {
      participantData.accountBalances.forEach(accountData => {
        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          if (moneyTypeData.deduction === null) {
            moneyTypeData.deduction = '0.00';
          }
        });
      });
    });

    this.filteredParticipantDataList.forEach(participantData => {
      participantData.accountBalances.forEach(accountData => {
        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          moneyTypeData.fundBalances.forEach(fundData => {
            if (fundData.deduction === null) {
              fundData.deduction = '0.00';
            }
          });
        });
      });
    });
    this.syncFilteredAndParticipantDataList();
  }

  cancelButtonClickAndRestoreFunds() {
    this.upperPanelData.fundDetailList.forEach(fund => {
      const fundId = 'fund#' + fund.fundId;
      if (this.excludeFundListAfterButtonClick.includes(fund.fundId)) {
        (document.getElementById(fundId) as any).checked = true;
      } else {
        (document.getElementById(fundId) as any).checked = false;
      }
    });
    this.excludeFundList = JSON.parse(JSON.stringify(this.excludeFundListAfterButtonClick));
  }

  cancelButtonClickAndRestoreMoney() {
    this.upperPanelData.moneyTypeDetailList.forEach(moneyType => {
      const moneyTypeId = 'moneyType#' + moneyType.moneyTypeId;
      if (this.excludeMoneyTypeListAfterButtonClick.includes(moneyType.moneyTypeId)) {
        (document.getElementById(moneyTypeId) as any).checked = true;
      } else {
        (document.getElementById(moneyTypeId) as any).checked = false;
      }
    });
    this.excludeMoneyTypeList = JSON.parse(JSON.stringify(this.excludeMoneyTypeListAfterButtonClick));
  }

  setTransactionTypeProperties() {
    if (this.selectedTransaction === 'Distribution') {
      this.propertyName = 'vestedCasBal';
      this.isPartialDeductionChecked = false;
      try {
        (document.getElementById('exceptionReport') as any).disabled = true;
        (document.getElementById('partialDeductionCheckBox') as any).disabled = true;
      } catch (e) {

      }

    } else if (this.selectedTransaction === 'Forfeiture') {
      this.propertyName = 'unvestedCashBal';
      this.isPartialDeductionChecked = false;
      try {
        (document.getElementById('exceptionReport') as any).disabled = true;
        (document.getElementById('partialDeductionCheckBox') as any).disabled = true;
      } catch (e) {

      }
    } else {
      this.propertyName = 'endCashBal';
      const tempBool = this.isPartialDeductionChecked;
      if (this.checkedRadioOption1 === false) {
        (document.getElementById('partialDeductionCheckBox') as any).disabled = false;
        this.isPartialDeductionChecked = tempBool;
      }
    }
  }

  convertDeductionAmountToTillTwoDecimal(id) {

    const ids = id.split(',');
    this.filteredParticipantDataList.forEach(participantData => {
      if (participantData.empId.toString() === ids[0]) {
        participantData.deduction = Number(participantData.deduction).toFixed(2);
        participantData.accountBalances.forEach(accountData => {
          if (accountData.accountId.toString() === ids[1]) {
            accountData.deduction = Number(accountData.deduction).toFixed(2);
            accountData.moneyTypeBalances.forEach(moneyTypeData => {
              if (moneyTypeData.moneyTypeId.toString() === ids[2]) {
                moneyTypeData.deduction = Number(moneyTypeData.deduction).toFixed(2);
                moneyTypeData.fundBalances.forEach(fundData => {
                  if (fundData.fundId.toString() === ids[3]) {
                    fundData.deduction = Number(fundData.deduction).toFixed(2);
                  }
                });
              }
            });
          }
        });
      }
    });
    this.syncFilteredAndParticipantDataList();
  }
  convertDeductionAmountToTillTwoDecimalForAutoCalculations() {
    this.filteredParticipantDataList.forEach(participantData => {
      participantData.deduction = Number(participantData.deduction).toFixed(2);
      participantData.accountBalances.forEach(accountData => {
        accountData.deduction = Number(accountData.deduction).toFixed(2);
        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          moneyTypeData.deduction = Number(moneyTypeData.deduction).toFixed(2);
          moneyTypeData.fundBalances.forEach(fundData => {
            fundData.deduction = Number(fundData.deduction).toFixed(2);
          });
        });
      });
    });
  }

  calculateBalancesForAmountDistributeToAllParticipants() {

    let sumOfAllFundsForAllParticipant = 0;
    let sumOfAllFundsByParticipant = 0;
    let sumOfAllFundsByAccount = 0;
    let sumOfAllFundsByMoneyType = 0;
    const amount: number = this.amountToDivideBetweenAllparticipant;
    this.isCalculationPerformed = true;
    this.isTransactionTypeSelected = true;

    this.filteredParticipantDataList.forEach(participantData => {
      participantData.accountBalances.forEach(accountData => {
        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          moneyTypeData.fundBalances.forEach(fundData => {
            sumOfAllFundsForAllParticipant = sumOfAllFundsForAllParticipant + Number(fundData[this.propertyName]);
          });
        });
      });
    });


    this.filteredParticipantDataList.forEach(participantData => {
      sumOfAllFundsByParticipant = 0;
      participantData.accountBalances.forEach(accountData => {
        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          moneyTypeData.fundBalances.forEach(fundData => {
            sumOfAllFundsByParticipant = sumOfAllFundsByParticipant + Number(fundData[this.propertyName]);
          });
        });
      });
      if (sumOfAllFundsForAllParticipant <= amount) {
        participantData.deduction = Number(sumOfAllFundsByParticipant).toFixed(2);
      } else {
        participantData.deduction = Number((Number(sumOfAllFundsByParticipant) * Number(amount))
          / sumOfAllFundsForAllParticipant).toFixed(2);
      }
    });

    this.filteredParticipantDataList.forEach(participantData => {
      participantData.accountBalances.forEach(accountData => {
        sumOfAllFundsByAccount = 0;
        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          moneyTypeData.fundBalances.forEach(fundData => {
            sumOfAllFundsByAccount = sumOfAllFundsByAccount + Number(fundData[this.propertyName]);
          });
        });
        if (sumOfAllFundsForAllParticipant <= amount) {
          accountData.deduction = Number(sumOfAllFundsByAccount).toFixed(2);
        } else {
          accountData.deduction = Number((Number(sumOfAllFundsByAccount) * Number(amount))
            / sumOfAllFundsForAllParticipant).toFixed(2);
        }

      });
    });

    this.filteredParticipantDataList.forEach(participantData => {
      participantData.accountBalances.forEach(accountData => {
        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          sumOfAllFundsByMoneyType = 0;
          moneyTypeData.fundBalances.forEach(fundData => {
            sumOfAllFundsByMoneyType = sumOfAllFundsByMoneyType + Number(fundData[this.propertyName]);
          });
          if (sumOfAllFundsForAllParticipant <= amount) {
            moneyTypeData.deduction = Number(sumOfAllFundsByMoneyType).toFixed(2);
          } else {
            moneyTypeData.deduction = Number((Number(sumOfAllFundsByMoneyType) * Number(amount))
              / sumOfAllFundsForAllParticipant).toFixed(2);
          }
        });
      });
    });

    this.filteredParticipantDataList.forEach(participantData => {
      participantData.accountBalances.forEach(accountData => {
        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          moneyTypeData.fundBalances.forEach(fundData => {
            if (sumOfAllFundsForAllParticipant <= amount) {
              fundData.deduction = Number(fundData[this.propertyName]).toFixed(2);
            } else {
              fundData.deduction = Number((Number(fundData[this.propertyName]) * Number(amount))
                / sumOfAllFundsForAllParticipant).toFixed(2);
            }
          });
        });
      });

    });

    this.upadteDecimalDifferencesForParticipantLevel(amount);
    this.updateDecimalDifferences();
    this.syncFilteredAndParticipantDataList();

  }

  upadteDecimalDifferencesForParticipantLevel(amountToDivideBetweenAllparticipant) {

    let sumOfALLDeduction = 0;
    let sumOfAllCashBalances = 0;
    this.filteredParticipantDataList.forEach(participantData => {
      sumOfALLDeduction = sumOfALLDeduction + Number(participantData.deduction);
    });

    this.filteredParticipantDataList.forEach(participantData => {
      sumOfAllCashBalances = sumOfAllCashBalances + Number(participantData[this.propertyName]);
    });

    if (sumOfAllCashBalances >= amountToDivideBetweenAllparticipant) {
      let flag = false;
      this.filteredParticipantDataList.forEach(participantData => {
        if (participantData.deduction > Math.abs((sumOfALLDeduction -
          Number(amountToDivideBetweenAllparticipant))) && flag === false) {
          flag = true;
          participantData.deduction = Number(participantData.deduction - (sumOfALLDeduction -
            Number(amountToDivideBetweenAllparticipant))).toFixed(2);
        }
      });
    }
  }

  modalOptionNoButtonAction() {
    this.selectedTransaction = this.oldTransactionType1;
    if (this.selectedTransaction !== 'Fee') {
      (document.getElementById('amountForAllParticipant') as any).value = null;
      (document.getElementById('amountForAllParticipant') as any).disabled = true;
      (document.getElementById('radioAmountForAllParticipant') as any).disabled = true;
    } else {
      (document.getElementById('amountForAllParticipant') as any).disabled = false;
      (document.getElementById('radioAmountForAllParticipant') as any).disabled = false;
    }
  }

  setTransactionType() {
    this.isTransactionTypeSelected = true;
    this.oldTransactionType1 = this.oldTransactionType2;
    this.oldTransactionType2 = this.selectedTransaction;

    if (this.isTransactionTypeSelected && this.isCalculationPerformed) {
      document.getElementById('selectTransactionTypeAlertBox').click();
    }
    if (this.isTransactionTypeSelected && !this.isCalculationPerformed) {
      (document.getElementById('percentParticipant') as any).disabled = false;
      (document.getElementById('radioPercentParticipant') as any).checked = true;
      (document.getElementById('dollarAccount') as any).disabled = true;
      (document.getElementById('dollarAccount') as any).value = null;
      (document.getElementById('dollarParticipant') as any).disabled = true;
      (document.getElementById('dollarParticipant') as any).value = null;
      (document.getElementById('amountForAllParticipant') as any).value = null;
      (document.getElementById('amountForAllParticipant') as any).disabled = true;
    }
    if (this.selectedTransaction === 'Fee') {
      (document.getElementById('radioAmountForAllParticipant') as any).disabled = false;
      (document.getElementById('partialDeductionCheckBox') as any).disabled = true;
    } else {
      (document.getElementById('radioAmountForAllParticipant') as any).disabled = true;
      (document.getElementById('amountForAllParticipant') as any).disabled = true;
      (document.getElementById('partialDeductionCheckBox') as any).disabled = true;
      this.amountToDivideBetweenAllparticipant = null;
    }
    if (this.selectedTransaction === 'Fee' && this.checkedRadioOption4 === true) {
      (document.getElementById('radioAmountForAllParticipant') as any).disabled = false;
      (document.getElementById('partialDeductionCheckBox') as any).disabled = true;
    }
  }

  refreshTableOnEntryMethodChange() {
    if (this.oldSelectedEntryMethod == null) {
      this.oldSelectedEntryMethod = this.selectedEntryMethod;
    } else if (this.oldSelectedEntryMethod != null &&
      (this.percentagePerParticipant != null ||
        this.amountPerAccount != null ||
        this.amountPerParticipant != null)) {
      if (this.participantDatalist[0].deduction > 0) {
        this.refresh();
      }
    }
  }

  public popupLoadDistributionModal(): void {
    this.validateTransType();
    if (this.isValidTransaction === true) {
      this.loadDistributionModalComponent = true;
    } else {
      this.displayMissingTransactionTypeModal = true;
    }
  }

  closeModal() {
    this.loadDistributionModalComponent = false;
  }

  valueChange(newParticipantDataList: ParticipantData[]) {
    this.participantDatalist = newParticipantDataList;
    this.filteredParticipantDataList = newParticipantDataList;
    this.updateTotalCells();
    this.loadDistributionModalComponent = false;
  }


  closeWarningModal() {
    this.displayMissingTransactionTypeModal = false;
  }
}
