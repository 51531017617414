import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { selectionCriteriaSubmitted } from '../store.actions';
import { CoreState, initialState } from './core.state';

export function coreReducer(state: CoreState | undefined, action: Action): CoreState {
    return reducer(state, action);
}

const reducer: ActionReducer<any> = createReducer(
    initialState,
    on(selectionCriteriaSubmitted, (state, action) => ({ ...state, companyId: action.companyId, companyName: action.companyName })),
);
