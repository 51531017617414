import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { detailsReceived, moneyTypeSelected, transactionTypeSelected } from '../store.actions';
import { DetailsState, initialState } from './details.state';

export function detailsReducer(state: DetailsState | undefined, action: Action): DetailsState {
  return reducer(state, action);
}

const handleTransactionTypeSelected = (state, action) => {
  // todo: update datails to reflect that a transaction type was selected;
  return {
    ...state,
    transactionTypeSelected: action.transactionType
  };
};

const reducer: ActionReducer<any> = createReducer(
  initialState,
  on(moneyTypeSelected, (state, action) => {
    return {
      ...state,
      moneyTypes: action.moneyTypes
    };
  }),
  on(transactionTypeSelected, handleTransactionTypeSelected),
  on(detailsReceived, (state, action) => ({...state, details: action.details})),
);
