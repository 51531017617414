import {Injectable} from '@angular/core';
import {delay, map} from 'rxjs/operators';
import {RestService} from '../core/secured-api/services/rest.service';
import {CriteriaResponse, HierarchyLevelDetail} from './criteria-response';
import {SelectionCriteriaMapper} from './selection-criteria-mapper';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {RunMode} from '../../environments/run-modes.enum';
import {NameId} from '../core/models/name-id';

@Injectable({
  providedIn: 'root'
})
export class SelectionCriteriaService {
  constructor(private restService: RestService, private criteriaMapper: SelectionCriteriaMapper) {
  }

  public getCriteria(planId: string): Observable<CriteriaResponse> {
    /* istanbul ignore else */
    if (environment.runMode === RunMode.Production || environment.runMode === RunMode.LocalMode ) {
      return this.restService
        .get<any>(`companies/${planId}/selectionCriteria`)
        .pipe(map(i => this.criteriaMapper.toCriteriaResponse(i)));
    } else {
      return of({
          companyName: 'SGT Inc.',
          contributionGroups: [
            {
              hierarchyLevel:
                new NameId('Contribution Group 1', 1),
              years: [2009]
            } as HierarchyLevelDetail
          ],
          assetGroups: [{hierarchyLevel: new NameId('Asset Group 1', 1), years: [2001]}],
          plans: [{hierarchyLevel: new NameId('Plan 1', 1), years: [2010]}],
          years: [2001, 2009, 2010]
        } as CriteriaResponse
      ).pipe(delay(2000));
    }

  }

}
