import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RestService } from '../secured-api/services/rest.service';
import { ParticipantData } from '../../fast-data/ParticipantData.module';
import { map } from 'rxjs/operators';
import { AccountData } from '../../fast-data/AccountData.module';
import { MoneyTypeDetail } from '../models/company-details';
import { MoneyType } from '../../fast-data/MoneyType.module';
import { FundType } from '../../fast-data/FundType.module';


@Injectable({
  providedIn: 'root'
})
export class BalancesServiceService {

  constructor(private http: HttpClient, private restService: RestService) { }

  getBalances(planIds, transDate, sourceYear, sourceYearFlag) {
    const encodedTransDate = btoa(transDate);
    const encodedPlanIds = btoa(planIds);
    const encodedSourceYear = btoa(sourceYear);
    return this.restService.get<ParticipantData[]>
      (`balance/transDate/${encodedTransDate}/plans/${encodedPlanIds}/sourceYear/${encodedSourceYear}/sourceYearFlag/${sourceYearFlag}`)
      .pipe(
        map((record: ParticipantData[]) => {
          record.forEach((participantData: ParticipantData) => {
            participantData.deductionWithInterest = 0;
            participantData.accountBalances.forEach((accData: AccountData) => {
              accData.deductionWithInterest = 0;
              accData.moneyTypeBalances.forEach((mtData: MoneyType) => {
                mtData.deductionWithInterest = 0;
                mtData.fundBalances.forEach((fundData: FundType) => {
                  fundData.deductionWithInterest = 0;
                });
              });
            });
          });
          return record;
        })
      );
  }

  getRecords() {
    return this.http.get('https://restcountries.eu/rest/v2/all').pipe(
      map((res: any[]) => {
        const data = res.map(obj => ({
          name: obj.name,
          name1: obj.name,
          name2: obj.name
        }));
        return data;
      })
    );
  }

  checkBalanceUpdated(planIds, transDate) {
    const encodedTransDate = btoa(transDate);
    const encodedPlanIds = btoa(planIds);
    return this.restService.get<any>(`updatedBalance/transDate/${encodedTransDate}/plans/${encodedPlanIds}`);
  }

  saveTransaction(transactionJSON, selectedTransaction, targetFund?: Number) {
    switch (selectedTransaction) {
      case 'Fee':
        return this.restService.post<any>(`transaction/fees`, transactionJSON);
      case 'Balance Transfer':
        return this.restService.post<any>(`transaction/balanceTransfer`, transactionJSON);
      case 'Distribution':
        return this.restService.post<any>(`transaction/distribution`, transactionJSON);
      case 'Quiet Record':
        return this.restService.post<any>(`transaction/requestQuietRecord`, transactionJSON);
      case 'Forfeiture':
        return this.restService.post<any>(`transaction/forfeiture`, transactionJSON);
      case 'Fund Transfer':
        return this.restService.post<any>(`transaction/fundTransfer/targetFund/${targetFund}`, transactionJSON);
    }
  }

  //comment
  getPlanIdsByAssetGroupId(assetGroupId) {
    const encodedAssetGroupId = btoa(assetGroupId);
    return this.restService.get<any>(`assetGroupId/${encodedAssetGroupId}`);
  }

  getScheduledateByAccountId(accountIdList, selectedDate) {
    const encodedAccountIdList = btoa(accountIdList.toString());
    const encodedSelectedDate = btoa(selectedDate);
    return this.restService.post<any>(`accountIdList/${encodedSelectedDate}`, encodedAccountIdList);
  }
}
