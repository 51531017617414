import { Injectable } from '@angular/core';
import { CriteriaResponse } from './criteria-response';
import { SelectionCriteriaDetailMapper } from './selection-criteria-detail-mapper';

@Injectable({
    providedIn: 'root'
})
export class SelectionCriteriaMapper {
    constructor(private detailMapper: SelectionCriteriaDetailMapper) { }

    public toCriteriaResponse(apiResponse: any): CriteriaResponse {
        return {
            companyName: apiResponse.companyName,
            years: apiResponse.years,
            plans: apiResponse.plans.map(this.detailMapper.toSelectionCriteriaDetail),
            contributionGroups: apiResponse.contributionGroups.map(this.detailMapper.toSelectionCriteriaDetail),
            assetGroups: apiResponse.assetGroups.map(this.detailMapper.toSelectionCriteriaDetail),
            asOfDate: apiResponse.asOfDate,
            autoTradeFlag: apiResponse.autoTradeFlag
        } as CriteriaResponse;
    }
}
