import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {RestService} from '../secured-api/services/rest.service';
import {DeliveryDate} from '../models/DeliveryDate';


@Injectable({
  providedIn: 'root'
})
export class DeliveryDateService {

  constructor(private http: HttpClient, private restService: RestService) { }

  getDeiliveryDateByCompany(companyId) {
    return this.restService.get<DeliveryDate[]>(`/companies/${companyId}/deliveryDate`);
  }
}
