import { Component } from '@angular/core';

@Component({
    selector: 'app-pending-tran-alert',
    templateUrl: './pending-tran-alert.component.html',
    styleUrls: [
        './pending-tran-alert.component.scss'
    ]
})
export class PendingTranAlertComponent {

  close() {
    window.location.reload();
  }
}
