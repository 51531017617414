import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ErrorHandlerService } from '../errors/error-handler.service';
import { SessionStorageService } from 'ngx-webstorage';
import {RunMode} from '../../../../environments/run-modes.enum';
import { jwtDecode } from "jwt-decode";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    public token$: Observable<string>;
    public tokenResponse: ReplaySubject<string>;
    // private EXPIRY_TIME = 1180000;
    // private tokenTimer: Observable<number> = timer(0, this.EXPIRY_TIME);
    localStorage: Storage;

    public constructor(
        private httpClient: HttpClient,
        private sessionStorage: SessionStorageService,
        private errorHandlerService: ErrorHandlerService
    ) {
        this.tokenResponse = new ReplaySubject<string>(1);
        this.token$ = this.tokenResponse.asObservable();
        this.localStorage = window.localStorage;
  }

  // requestApigeeToken(): void {
  //   if (environment.runMode === RunMode.LocalMode) {
  //     this.tokenResponse.next(null);
  //     this.sessionStorage.store('tokenResponse', null);
  //     return;
  //   }
  //   const url: string = environment.apigeeTokenEndpointUrl;
  //   const userId: string = this.localStorage.getItem("userId")
  //   console.log("logging userId inside Auth.service.requestApigeeToken");
  //   const headers = {
  //     'Content-Type': 'application/json',
  //     'Authorization' : 'Basic ' + environment.basicToken,
  //     'business-context': `{"webId": "${userId}"}`,
  //     'Access-Control-Allow-Origin': '*',
  //   }
  //   const body = {
  //     grant_type: "client_credentials"
  //   };
  //   this.httpClient.post<{ access_token: string, headers }>(url, body, {headers})
  //     .pipe(
  //       map((response) => response.access_token),
  //       catchError((error) => {
  //         throw this.errorHandlerService.returnSecuredApiError('Error returning Apigee token.', error);
  //       })
  //     ).subscribe((response) => {
  //       this.tokenResponse.next(response);
  //       this.sessionStorage.store('tokenResponse', response);
  //     },
  //     (error: Error) => {
  //       // tslint:disable-next-line:no-console
  //       console.log(error);
  //     });
  // }

  // apigeeTokenRest() {
  //   console.log(environment.runMode);
  //   if (environment.runMode === RunMode.LocalMode) {
  //     this.sessionStorage.store('tokenResponse', null);
  //     return;
  //   }
  //   this.httpClient.get<{ access_token: string }>(environment.apigeeTokenEndpointUrl).pipe(
  //     map((res) => res.access_token)
  //   ).subscribe((res) => {
  //     this.sessionStorage.store('tokenResponse', res);
  //   });
  // }

  decodeJWT(token: string): any {
    return jwtDecode(token);
  }
}
