import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  // Used for error page display.
  errorCode: number;

    constructor(private router: Router) { }

   // Used to set error code for error component use.
   setErrorCode(errorCode: number) {
      this.errorCode = errorCode;
    }

    // Get the error code for use to display correct html.
    getErrorCode() {
        return this.errorCode;
    }

    showErrorPage(errorCode: number) {
      this.setErrorCode(errorCode);
      this.router.navigate(['/error']);
      // throw the error so we do not display any static content.
      // throw new Error();
    }
}
