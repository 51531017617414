export class Constants {

    WITHCREDENTIALS: string;
    APIGEE_END_POINT: string;
    OAUTH_END_POINT: string;
    LOGGING_END_POINT: string;
    HTTP_OK: number;
    HTTP_NOT_FOUND: number;
    HTTP_NOT_MODIFIED: number;
    HTTP_INTERNAL_ERROR: number;
    LOGGING_SERVICE_URL: string;
    BASE_URL_LIAB_SERVICE: string;
    HTTP_UNAVAILABLE: number;
    HTTP_BAD_REQUEST: number;
    HTTP_CONFLICT: number;
    HTTP_EXPECTATION_FAILED: number;
    HTTP_FOUND: number;
    HTTP_NO_CONTENT: number;
    COMPANY_URL: string;
    SELECTION_URL: string;

    constructor() {
        this.WITHCREDENTIALS = 'withCredentials';
        this.APIGEE_END_POINT = 'apigeeEndPoint';
        this.OAUTH_END_POINT = 'oAuthEndPoint';
        this.LOGGING_END_POINT = 'loggingServiceEndPoint';
        this.HTTP_NOT_FOUND = 404;
        this.HTTP_OK = 200;
        this.HTTP_NO_CONTENT = 204;
        this.HTTP_NOT_MODIFIED = 304;
        this.HTTP_INTERNAL_ERROR = 500;
        this.HTTP_UNAVAILABLE = 503;
        this.HTTP_BAD_REQUEST = 400;
        this.HTTP_CONFLICT = 409;
        this.HTTP_EXPECTATION_FAILED = 417;
        this.HTTP_FOUND = 302;
        this.COMPANY_URL = '/companies/';
        this.SELECTION_URL = '/selectionCriteria';
    }
}
