import { createAction, props } from '@ngrx/store';
import { DeductionType } from '../models/deduction.enum';
import { SelectionCriteria } from '../models/selection-criteria';

export const selectionCriteriaSubmitted: any = createAction(
  '[SelectionCriteria] Selection Criteria Submitted',
  props<SelectionCriteria>());

export const moneyTypeSelected: any = createAction(
  '[OnMoneyTypeSelected] Money types selected',
  props<{ moneyTypes: string[] }>()
);

export const detailsReceived: any = createAction(
  '[OnDetailsLoaded] Details have been loaded',
  props<{ details: string }>()
);

export const transactionTypeSelected: any = createAction(
  '[TransactionTypeSelected] A transaction type has be selected',
  props<{ transactionType: string }>()
);

export const transactionDateChanged: any = createAction(
  '[OnTransactionDateChange] Transaction Date has changed',
  props<{ transactionDate: Date }>()
);

export const excludedFundsChanged: any = createAction(
  '[OnExcludedFundsChanged] Excluded funds have changed',
  props<{ excludedFundIds: number[] }>()
);

export const calculateDeductions: any = createAction(
  '[OnCalculateDeductions] Calculate deductions requested',
  props<{ deductionType: DeductionType, deductionAmount: number, deductPartialAmounts: boolean }>()
);
