<footer class="footer">
    <section class="bg-gray-lighter text-center text-muted footer-row">

        <div>
            <small>© {{currentYear}} Principal Life Insurance Company.
                <p>Information classification: Customer Confidential. Information may only be transmitted through
                    compliance-approved formats.</p>
            </small>
        </div>
    </section>
</footer>
<!-- Horizon JS -->