import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestService } from '../secured-api/services/rest.service';

@Injectable({
  providedIn: 'root'
})
export class UpperPanelService {

  constructor(private http: HttpClient, private restService: RestService) { }

  getUpperPanelData(planIds) {
    const encodedPlanIds = btoa(planIds);
    return this.restService.get<any>(`plans/${encodedPlanIds}/fundMoneyTypeDetails`);
  }
}
