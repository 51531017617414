import { Injectable } from '@angular/core';
import { SecuredApiError } from './secured-api-error';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {
    public returnSecuredApiError(message: string, error: Error): SecuredApiError {
        const securedApiError: SecuredApiError = error as SecuredApiError;
        if (!securedApiError.customErrorMessagesStack) {
            securedApiError.customErrorMessagesStack = [];
        }
        securedApiError.customErrorMessagesStack.push(message);
        return securedApiError;
    }
}
