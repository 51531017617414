import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { SelectionCriteria } from '../models/selection-criteria';
import { DetailsService } from '../services/details.service';
import { detailsReceived, selectionCriteriaSubmitted } from './store.actions';

@Injectable()
export class StoreEffects {
    public loadDetails$ = createEffect(() => this.actions$.pipe(
        ofType(selectionCriteriaSubmitted),
        mergeMap((criteria: SelectionCriteria) => this.detailsService.getDetails(criteria)),
        map(details => detailsReceived({ details }))
    ));

    public constructor(
        private actions$: Actions,
        private detailsService: DetailsService
    ) { }
}
