import {RunMode} from './run-modes.enum';

export const environment = {
  production: false,
  apiBaseUrl: 'https://api-router.pilot.theprincipal.net:9003/transaction-service-ext',
  apigeeTokenEndpointUrl: 'https://api.pilot.principal.com/oauth-api/token/client',
  basicToken: 'NmhSMGpxanptUDNBT2pSbWpPM1U5TlNoaklMM0E5aW46QzhoYXpRaVJOQ0xwQXIzYQ==', 
  runMode: RunMode.Production,
  authority: 'https://login.microsoftonline.com/3bea478c-1684-4a8c-8e85-045ec54ba430/',
  clientId: 'bd36fdce-5d0b-4942-bc24-03a7ae24d3bd',
  scope: 'openid profile offline_access api://bd36fdce-5d0b-4942-bc24-03a7ae24d3bd/fast-dev-scope',
};
