import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NameId } from '../models/name-id';
import { SelectItem } from 'primeng/api';
import { isUndefined } from 'util';

@Component({
  selector: 'app-combo-box',
  templateUrl: './combo-box.component.html',
  styleUrls: ['./combo-box.component.css']
})
export class ComboBoxComponent {
  @Output() selectedOption: EventEmitter<any> = new EventEmitter<any>();
  public values: SelectItem[];

  private _selectedValue: any;

  constructor() { }

  @Input()
  public set options(newOptions: any[]) {
    if (newOptions === undefined) {
      this.values = undefined;
    } else {
      this.values = newOptions.map(item => ({
        value: item,
        label: item.toString()
      } as SelectItem));
    }

    this._selectedValue = undefined;
  }

  public set selectedValue(newValue: any) {
    this._selectedValue = newValue;
    this.selectedOption.emit(newValue);
  }

  public get selectedValue(): any {
    return this._selectedValue;
  }
}
