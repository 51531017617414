import {Component} from '@angular/core';
import {MultiselectBase} from '../multiselect.base';

@Component({
  selector: 'app-multiselect-listbox',
  templateUrl: './multiselect-listbox.component.html',
  styleUrls: ['./multiselect-listbox.component.css']
})
export class MultiselectListboxComponent extends MultiselectBase {

}


