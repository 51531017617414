
<app-header></app-header>
<div *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</div>
<div class="main-container" *ngIf="!isLoading">
    <div class="container-fluid">
        <h1 class="page-header util-padding-0 util-margin-0">
            <span>Fees and Special Transfers</span>
        </h1>
    </div>
    <!-- <div class="alert alert-info util-margin-top-10" role="alert">
        <i class="fa fa-info-circle"></i>
        This client is auto-traded
    </div> -->
    <div class="container util-margin-top-20">
      <div class="panel panel-default">
        <div class="panel-heading">
            <span> Transaction Information </span>
        </div>
        <div class="panel-body">
          <div class="row util-padding-top-15">
            <div class="col-md-1">
              <label> Company: </label>
            </div>
            <div class="col-md-3">
              <span> {{this.editInfo.companyName}} </span>
            </div>

            <div class="col-md-2">
              <label> Transaction Date: </label>
            </div>
            <div class="col-md-2">
                <span> {{this.editInfo.transDate  | date : 'MM/dd/yyyy'}}</span>
            </div>

            <div  class="col-md-2">
              <label> Transaction Type: </label>
            </div>
            <mat-form-field floatLabel='never' class="col-md-2">
              <mat-select [(ngModel)]="selectedType" name="transactions">
                <mat-option  *ngFor="let transaction of transactions" [value]="transaction.viewValue">
                  <span>{{ transaction.viewValue }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div id="transactionBreakdown">
        <app-fast-transaction-breakdown (submitUpdateTransaction)="saveTransaction($event)" [editFlag] = true [transactionToView] = "this.editInfo.transId" [companyName] = "this.editInfo.companyName" [transDate] = "this.editInfo.transDate" [transType] = "this.selectedType">
        </app-fast-transaction-breakdown>
      </div>
    </div>
</div>

<app-footer></app-footer>

<app-modal name="saveTransactionSuccess" [showClose]="true" [showFooter]="false" style="text-align:center;">
  <div header>          
    <h4> <b> Transaction saved successfully</b> </h4>
  </div>
  <div body>
    <button style="margin:10px" routerLink ="/company/{{this.editInfo.companyId}}" type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
  </div>
</app-modal>

<app-modal name="saveTransactionFailure" [showClose]="true" [showBody]="false" [showFooter]="false" >
  <div header>          
    <h4><b>Transaction insertion failed. </b></h4>
  </div>
</app-modal>

<app-modal name="alertTransactionDelete" [showClose]="true" [showFooter]="false" >
  <div header>          
    <h4><b>Transaction would be remove </b></h4>
  </div>
  <div body>
    <p> Please return to the homepage to perform the delete transaction </p>
    <button style="margin:10px" routerLink ="/company/{{this.editInfo.companyId}}" type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
  </div>
</app-modal>

<app-modal name="saveTransactionEmail" [showClose]="true" [showFooter]="false" >
  <div header>          
    <h4><b> The deductions have been submitted successfully! </b></h4>
  </div>
  <div body>
    <p> An email would be shortly sent to you which will inform you about the final status of the transaction. </p>
  </div>
</app-modal>

<button id="hiddenButtonFailure" data-toggle="modal" data-target="#saveTransactionFailure" style="display: none"></button>
<button id="hiddenButtonEmail" data-toggle="modal" data-target="#saveTransactionEmail" style="display: none"></button>
<button id="hiddenButtonSuccess" data-toggle="modal" data-target="#saveTransactionSuccess" style="display: none"></button>
<button id="hiddenButtonAlert" data-toggle="modal" data-target="#alertTransactionDelete" style="display: none"></button>