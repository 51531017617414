<div class="modal in" id="alert-modal" style="display:block;">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
          <span><h4> Please select a valid transaction type. </h4> </span>
      </div>
      <div class="modal-footer justify-content-center">
        <a (click)="notify.emit()" style="padding-left: 10px;">X</a>
      </div>
    </div>
  </div>
</div>

