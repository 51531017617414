<div *ngIf="!_dataReadytest">
  <app-loading-spinner></app-loading-spinner>
</div>
<div  *ngIf="participantDatalist.length > 0">
  <div *ngIf="_dataReadytest" class="main-container">
    <div class="panel" style="float: right;">
      <button *ngIf="editFlag" buttons class="btn btn-primary" id="saveTransaction" (click)="submitUpdate()"> Submit Transaction </button>
      <button *ngIf = "qrApproval == 'true' && transType == 'Quiet Record' &&
                      (approvalStatus !== 'Approved' && approvalStatus !== 'Denied')" (click)="approveQuietRecord()" class="btn btn-success" id="approveQRecordBtn">
            Approve Quiet Record 
      </button>
      <button *ngIf = "qrApproval == 'true' && transType == 'Quiet Record' &&
                      (approvalStatus !== 'Approved' && approvalStatus !== 'Denied')" class="btn btn-danger" id="denyQRecordBtn" 
                    (click)="denyQuietRecord()"
                    style="margin:10px" > 
            Deny Quiet Record 
      </button>
      <button class="btn btn-primary-alt" id="printTransaction" style="margin:10px" (click)="exportexcel()"> Export to Excel </button>
      <button routerLink ="/company/{{this.companyId}}" type="button" class="btn btn-primary">Back to previous page</button>
    </div>
    <div class="container-fluid">
      <div class="container">
      <br>
        <app-disclaimer></app-disclaimer>
        <div class="row">
          <div class="col-md-12">

            <div class="table-collapse">
              <div class="table-responsive">
                <table class="table" >
                  <thead>
                  <tr>
                    <th class="text-left" *ngFor="let col of columns">
                      {{col}}
                    </th>
                    <th id="button-column"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngFor="let participantData of participantDatalist; let i = index">
                    <tr>
                      <td >&nbsp; &nbsp;
                        <span class="collapse-button">
                          <!-- The input ID "caret-rotate-1" can be changed to any unique ID; just ensure that the label FOR is also updated to match! -->
                          <input type="checkbox" id="caret-rotate-{{participantData.empId}}"
                                  data-toggle="collapse" attr.data-target=".js-caret-rotate{{participantData.empId}}">
                          <label for="caret-rotate-{{participantData.empId}}"></label>
                        </span>
                      </td>

                      <td class="text-left" id="name{{participantData.empId}}">{{ participantData.empName }}</td>
                      <td class="text-left">{{ participantData.endCashBal | number : '1.2-2'}}</td>
                      <td class="text-left">{{ participantData.vestedCasBal | number : '1.2-2'}}</td>
                      <td class="text-left">{{ participantData.interestAccrued | number : '1.2-2'}}</td>
                      <td class="text-left">{{ participantData.deduction | number : '1.2-2'}} </td>
                      <td class="text-left" *ngIf="editFlag">{{participantDatalistModified[i].deduction | number : "1.2-2"}}</td>
                      <td class="text-left" *ngIf="editFlag"> 
                        <input type="number" (change)="changeDeductionInput([i], participantDatalistModified[i].deduction, $event.target.value)" >
                      </td>
                    </tr>


                    <ng-container *ngFor="let accountData of participantData.accountBalances; let j = index">
                      <tr class="  collapse-row js-caret-rotate{{participantData.empId}} collapse" aria-expanded="true">
                        <td class="text-left"></td>
                        <td class="text-left" id="name{{participantData.empId}},{{accountData.accountId}}" style="padding-left: 35px;">{{ accountData.accountName }} ({{ accountData.accountId}}) </td>
                        <td class="text-left">{{ accountData.endCashBal | number : '1.2-2'}}</td>
                        <td class="text-left">{{ accountData.vestedCasBal | number : '1.2-2'}}</td>
                        <td class="text-left">{{ accountData.interestAccrued | number : '1.2-2'}}</td>
                        <td class="text-left"> {{ accountData.deduction | number : '1.2-2' }}</td>
                        <td class="text-left" *ngIf="editFlag">{{participantDatalistModified[i].accountBalances[j].deduction | number : "1.2-2"}}</td>
                        <td class="text-left" *ngIf="editFlag"> 
                          <input type="number" (change)="changeDeductionInput([i, j], participantDatalistModified[i].accountBalances[j].deduction, $event.target.value)" >
                        </td>
                      </tr>

                      <ng-container *ngFor="let moneyData of accountData.moneyTypeBalances; let k = index">
                        <tr class="warning collapse-row js-caret-rotate{{participantData.empId}} collapse" aria-expanded="true">
                          <td class="text-left"></td>
                          <td id="name{{participantData.empId}},{{accountData.accountId}},{{moneyData.moneyTypeId}}" style="padding-left: 70px;">{{ moneyData.moneyTypeName }}</td>
                          <td >{{ moneyData.endCashBal | number : '1.2-2'}}</td>
                          <td >{{ moneyData.vestedCasBal | number : '1.2-2'}}</td>
                          <td >{{ moneyData.interestAccrued | number : '1.2-2'}}</td>
                          <td class="text-left">{{moneyData.deduction  | number : '1.2-2' }}</td>
                          <td class="text-left" *ngIf="editFlag">{{participantDatalistModified[i].accountBalances[j].moneyTypeBalances[k].deduction | number : "1.2-2"}}</td>
                          <td class="text-left" *ngIf="editFlag"> 
                            <input type="number" (change)="changeDeductionInput([i, j, k], participantDatalistModified[i].accountBalances[j].moneyTypeBalances[k].deduction, $event.target.value)">
                          </td>
                        </tr>

                        <ng-container *ngFor="let fundData of moneyData.fundBalances; let l = index">
                          <tr class=" bg-light-blue collapse-row js-caret-rotate{{participantData.empId}} collapse" aria-expanded="true">
                            <td></td>
                            <td id="name{{participantData.empId}},{{accountData.accountId}},{{moneyData.moneyTypeId}},{{fundData.fundId}}" style="padding-left: 105px;">{{ fundData.fundName }} ({{fundData.fundId}})</td>
                            <td >{{ fundData.endCashBal | number : '1.2-2'}}</td>
                            <td >{{ fundData.vestedCasBal | number : '1.2-2'}}</td>
                            <td >{{ fundData.interestAccrued | number : '1.2-2'}}</td>
                            <td class="text-left"> {{fundData.deduction | number : '1.2-2'}}</td>
                            <td class="text-left" *ngIf="editFlag">{{participantDatalistModified[i].accountBalances[j].moneyTypeBalances[k].fundBalances[l].deduction | number : "1.2-2"}}</td>
                            <td class="text-left" *ngIf="editFlag"> 
                              <input type="number" (change)="changeDeductionInput([i, j, k, l], participantDatalistModified[i].accountBalances[j].moneyTypeBalances[k].fundBalances[l].deduction, $event.target.value)" >
                            </td>
                          </tr>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <tr>
                    <td></td>
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-left" id="totalCompanyDeductions"> {{ totalDeductionsAmt | number : '1.2-2'}} </td>
                    <td class="text-left" id="totalCompanyDeductions" *ngIf="editFlag"> {{ totalNewDeductionsAmt | number : '1.2-2'}} </td>
                  </tr>
                </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade"  id="quietRecordApprovalModal"  style="text-align: center;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4> <b> {{approvalText}} </b> </h4>
      </div>
      <div class="modal-body" style="text-align: center;">
      <button style="margin:10px" routerLink ="/company/{{this.companyId}}" type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade"  id="quietRecordFailureModal"  style="text-align: center;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4> <b> The action taken for this quiet record has failed. Please contact IT. </b> </h4>
      </div>
      <div class="modal-body" style="text-align: center;">
      <button style="margin:10px" routerLink ="/company/{{this.companyId}}" type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
      </div>
    </div>
  </div>
</div>


<button id="hiddenButtonQuietRecord" data-toggle="modal" data-target="#quietRecordApprovalModal" style="display: none"></button>
<button id="hiddenButtonQuietRecordFailure" data-toggle="modal" data-target="#quietRecordFailureModal" style="display: none"></button>