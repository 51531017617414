export class NameId implements INameId {
    public name: string;
    public id: number;

    constructor(name: string, id: number) {
        this.name = name;
        this.id = id;
    }

    public toString(): string {
        return this.name;
    }
}

export interface INameId {
    name: string;
    id: number;
}
