<div class="modal fade" id="{{name}}" tabindex="-1" role="dialog" aria-labelledby="my-modal-label">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button *ngIf="showClose" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <ng-content select="[header]" *ngIf="showHeader"></ng-content>
      </div>
      <div class="modal-body" *ngIf="showBody">
          <ng-content select="[body]"></ng-content>
      </div>
      <div class="modal-footer" *ngIf="showFooter">
          <ng-content select="[footer]"></ng-content>
      </div>
    </div>
  </div>
</div>