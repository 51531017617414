<div *ngIf="criteria$ | async as selectionCriteria; else isLoading">
  <div class="row">
    <label class="col-xs-3 text-right" for="companyName">Company: </label>
    <span class="col-xs-9" id="companyName">{{selectionCriteria.companyName}}</span>
  </div>
  <div class="row">
    <label class="col-xs-3 text-right" for="hierarchyLevel">Select Hierarchy Level: </label>
    <span class="col-xs-9" id="hierarchyLevel">
      <app-combo-box (selectedOption)="onHierarchyLevelChanged($event)" [options]="hierarchyLevels"></app-combo-box>
    </span>
  </div>
  <div class="row" *ngIf="hierarchyLevelDetailLabel">
    <label class="col-xs-3 text-right" for="hierarchyLevelDetail">{{hierarchyLevelDetailLabel}}</label>
    <span class="col-xs-9" id="hierarchyLevelDetail">
      <app-combo-box (selectedOption)="onHierarchyLevelDetailChanged($event)" [options]="hierarchyLevelDetails">
      </app-combo-box>
    </span>
  </div>
  <div class="row" *ngIf="moneySourceYears.length">
    <label class="col-xs-3 text-right" for="moneySourceYear">Money Source Year: </label>
    <span class="col-xs-9" id="moneySourceYear">
      <app-multiselect-combo-box (selectedOptions)="onMoneySourceYearsChanged($event)" [options]="moneySourceYears">
      </app-multiselect-combo-box>
    </span>
  </div>
  <div *ngIf="canBeSubmitted" class="row">
    <div class="col-xs-5"></div>
    <button type="button" class="btn btn-primary col-xs-1 submit-button"
      (click)="submitSelectionCriteria()">Submit</button>
    <div class="col-xs-6"></div>
  </div>
</div>
<ng-template #isLoading>
  <app-loading></app-loading>
</ng-template>
