import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ErrorComponent} from './core/error/error.component';
import { FastSelectionComponent } from './fast-selection/fast-selection.component';
import { FastDataComponent } from './fast-data/fast-data.component';
import { FastDataUpdateComponent } from './fast-data-update/fast-data-update.component';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';

const routes: Routes = [
  { path: 'company/:companyId', component: FastSelectionComponent, canActivate: [AutoLoginPartialRoutesGuard]},
  { path: 'error', component: ErrorComponent },
  { path: 'company/:companyId/details', component: FastDataComponent },
  { path: 'company/:companyId/edit', component: FastDataUpdateComponent },
  { path: 'unauthorized', component: ErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
