<div class="modal in" id="alert-modal" style="display:block;">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <span><h4> Pending Transaction</h4> </span>
      </div>
      <div class="modal-body">
        <div id="alert-message" class="alert alert-info">
          We received your request and it is being processed. Please check back after a few minutes.
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-primary" (click)="close()">Close</button>
      </div>
    </div>
  </div>
</div>
