import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../common.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  errorCode: number;

  constructor(private common: CommonService) { }

  ngOnInit() {
    // Get global error code
    this.errorCode = this.common.getErrorCode();
  }

}
