import { Component, OnInit} from '@angular/core';
import { DatePipe } from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {FastTransaction} from './fast-transaction';
import { FastTransactionService } from '../core/services/fast-transaction.service';
import {User} from '../core/models/User'
// import {Store} from '@ngrx/store';
// import {CoreState} from '../core/store/core-state/core.state';

@Component ({
  selector: 'app-fast-transaction-table',
  templateUrl: 'fast-transaction-table.component.html',
  styleUrls: ['fast-transaction-table.component.css']
})

export class FastTransactionTableComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private fastTransactionService: FastTransactionService,
    private datePipe: DatePipe) { }
    iconToAdd: any;
    htmlToAdd: any;
    displayTransactionTable: boolean;
    transactionToView: any;
    public fastTran: FastTransaction;
    public companyId: string;
    coreModuleState: any;
    loadPendingTranAlertComponent: boolean;
    currentDate: string;
    trnDt: string;

  public pColumns : any[] = [
    { field: 'transactionId', header: 'Transaction ID'},
    { field: 'amount', header: 'Amount' },
    { field: 'transDate', header: 'Transaction Date' },
    { field: 'createdDate', header: 'Created Date' },
    { field: 'modifiedDate', header: 'Modified Date' },
    { field: 'transactionType', header: 'Transaction Type' },
    { field: 'author', header: 'Author' },
    { field: 'status', header: 'Status' },
    { field: 'approvalStatus', header: 'Approval Status' }
    //build kickoff
  ];

  public transactionList: FastTransaction[] = [];
  deleteEligible: boolean;
  qrApproval: boolean;
  currentTransaction: number;
  currentType: string;
  isLoading: boolean;

  ngOnInit() {
    this.displayTransactionTable = true;
    this.companyId = this.activatedRoute.snapshot.paramMap.get('companyId');
    this.fetchEligibility();
    this.fetchData();
  }

  fetchData() {
    this.isLoading = true;
    this.fastTransactionService.getFastTransactions(this.companyId)
      .subscribe((transactions: FastTransaction[]) => {
        this.transactionList = transactions;
        this.isLoading = false;
    });
  }

  fetchEligibility() {
    this.isLoading = true;
    this.fastTransactionService.getUserRecord().subscribe((response: User) => {
      this.deleteEligible = response.deleteTran;
      this.qrApproval = response.qrApproval;
      },
      error => {
        this.deleteEligible = false;
        this.qrApproval = false;
    })
  }

  populateParticipantData(transDate, transType, transId, status, alertFlag, approvalStatus) {
    if (status === 'Pending' && alertFlag) {
      this.loadPendingTranAlertComponent = true;
     } else {
      const localstore = window.localStorage;
      localstore.setItem('transDate', transDate);
      localstore.setItem('transType', transType);
      localstore.setItem('transId', transId);
      localstore.setItem('approvalStatus', approvalStatus);
      localstore.setItem('qrApproval', this.qrApproval ? "true" : "false");
      localstore.setItem('filterType', "All");
      localstore.setItem('minTransactionDate', transDate);
      localstore.setItem('isViewMode', 'true');
      localstore.setItem('planIds', transId);
      localstore.setItem('sourceYear', '0');
      localstore.setItem('sourceYearFlag', 'true');
    }
  }

  storeTransactionInfo(transDate, transType, transId, status, alertFlag) {
    if (status === 'Pending' && alertFlag) {
      this.loadPendingTranAlertComponent = true;
     } else {
      const localStore = window.localStorage
      const companyName = localStore.getItem('companyName')
      const companyId = localStore.getItem('companyId');
      const data = {
        "transId":transId,
        "transDate":this.datePipe.transform(new Date(transDate), 'yyyy-MM-dd'),
        "transType":transType,
        "companyName":companyName,
        "companyId":companyId
      }
      localStore.setItem('editInfo', JSON.stringify(data))
     }
  }

  navigateToDetailsPage(status, trnsDate): boolean {
    const currentDate = this.datePipe.transform(new Date(), 'yyyy/MM/dd');
    const trnDt = this.datePipe.transform(trnsDate, 'yyyy/MM/dd');

    return ((status === 'Processed') || (status === 'Pending' && trnDt >= currentDate)) 
  }

  deleteCheck(status, type, date){
    if (type == "Distribution"){
      const targetDate = new Date(date);
      const temp = new Date("06/24/2022")
      return this.deleteEligible && status == 'Processed' && targetDate >= temp 
    }
    return this.deleteEligible && status == 'Processed'
  }
  
  setCurrentTransaction(transaction, type) {
    this.currentTransaction = transaction;
    this.currentType = type;
  }

  deleteTransaction() {
    this.isLoading = true;
    this.fastTransactionService.deleteTransaction(this.currentTransaction, this.currentType, this.companyId).subscribe(
      res => {
        if (res) {
          this.fetchData();
          this.currentTransaction = null;
          this.isLoading = false;
        }
      },
      err => {
        if (err.status === 504) {
          document.getElementById('hiddenButtonEmail').click();
        } else {
          document.getElementById('hiddenButtonFailure').click();
        }
        this.fetchData();
        this.currentTransaction = null;
        this.isLoading = false;
      }
    );
  }
}



