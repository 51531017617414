import { Component, OnInit } from '@angular/core';
import { DetailsState } from '../../store/details-state/details.state';
import { Store } from '@ngrx/store';
import { transactionDateChanged } from '../../store/store.actions';

@Component({
  selector: 'app-transaction-date-calendar',
  templateUrl: './transaction-date-calendar.component.html',
  styleUrls: ['./transaction-date-calendar.component.css']
})
export class TransactionDateCalendarComponent implements OnInit {
  public maxTransactionDate: Date = new Date();
  private _selectedTransactionDate: Date;

  constructor(private store: Store<DetailsState>) { }

  public ngOnInit(): void {
    this.maxTransactionDate.setDate(new Date().getDate() - 1);
    this._selectedTransactionDate = this.maxTransactionDate;
  }

  public get selectedTransactionDate(): Date {
    return this._selectedTransactionDate;
  }

  public set selectedTransactionDate(newDate: Date) {
    this._selectedTransactionDate = newDate;
    this.store.dispatch(transactionDateChanged({
      transactionDate: this._selectedTransactionDate
    }));
  }

}
