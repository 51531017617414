<div class="modal in" id="alert-modal" style="display:block;">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <span><h4> Upload File </h4> </span>
        <p style="color:red">Steps 2 and 3 will not be applied for uploaded deductions.</p>
      </div>
      <div class="modal-body">
        <p><b>Effective Date: </b>{{effectiveDate | date:'MM/dd/yyyy'}}</p>
        <p><b>Transaction Type: </b>{{transactionType}}</p>
         <input type="file" style="display: inline-block;" (change)="incomingFile($event.target.files)"
           placeholder="Upload file" accept=".xls, .xlsx" [disabled]="isLoading">
      </div>
      <ul *ngIf="errors.length !== 0" class="errors">
        <li *ngFor="let error of errors">{{error}}</li>
      </ul>
      <div class="modal-footer justify-content-center">
        <button (click)="notify.emit()" class="btn btn-default" type="button" >Exit</button>
        <button (click)="populateDeductionsAtParticipantLevel()" class="btn btn-primary" type="button"
                *ngIf="errors.length === 0 && file != null"
                [disabled]="isPosting">Submit File</button>
      </div>
    </div>
  </div>
</div>
