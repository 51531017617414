import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// @ts-ignore
import { FastTransactionBreakdownService } from './fast-transaction-breakdown-service';
import { ParticipantData } from '../fast-data/ParticipantData.module';
import { Workbook } from 'exceljs';
import { AccountData } from '../fast-data/AccountData.module';
import { MoneyType } from '../fast-data/MoneyType.module';
import { FundType } from '../fast-data/FundType.module';
import { DatePipe } from '@angular/common';
import * as fs from 'file-saver';
import { FastTransactionService } from '../core/services/fast-transaction.service';

@Component({
  selector: 'app-fast-transaction-breakdown',
  templateUrl: './fast-transaction-breakdown.component.html',
  styleUrls: ['./fast-transaction-breakdown.component.css']
})

export class FastTransactionBreakdown implements OnInit {
  @Input() transactionToView: string;
  @Output() changeBackToTableView = new EventEmitter();
  @Output() submitUpdateTransaction = new EventEmitter();
  @Input() companyName: string;
  @Input() transDate: string;
  @Input() editFlag: boolean = false;
  @Input() approvalStatus: string;
  @Input() hasApproverPrivileges: boolean = true;

  _transType: string;
  get transType(): string {
    return this._transType;
  }
  @Input("transType")
  set transType(transType: string) {
    this._transType = transType
    this.getPropertyName();
    if (this.previousPropertyName != this.propertyName) {
      this.participantDatalistModified = this.populateZeroDeduction(JSON.parse(JSON.stringify(this.participantDatalist)), 0);
      this.totalNewDeductionsAmt = 0
    } else {
      this.participantDatalistModified = JSON.parse(JSON.stringify(this.participantDatalist));
      this.totalNewDeductionsAmt = this.totalDeductionsAmt
    }
  }
  public participantDatalist: ParticipantData[] = [];
  public participantDatalistModified: ParticipantData[] = [];
  public _dataReadytest = false;
  propertyName;
  previousPropertyName: string;
  companyId: string;
  localstore: Storage;
  totalDeductionsAmt: number;
  totalNewDeductionsAmt: number;
  approvalText: string;
  showModal: string;
  qrApproval: string;

  constructor(public datepipe: DatePipe, public fastTransactionBreakdownService: FastTransactionBreakdownService,
    public fastTransactionService: FastTransactionService) {
  }

  public columns = ['', 'Participant Name', 'Cash Value', 'Vested Cash Value', 'Interest Accrued', 'Deductions'];
  public propertyMap = ['accountBalances' as const, 'moneyTypeBalances' as const, 'fundBalances' as const]

  ngOnInit() {
    this.localstore = window.localStorage;
    this.companyId = this.localstore.getItem('companyId');
    this.qrApproval = this.localstore.getItem('qrApproval');
    this.fastTransactionBreakdownService.getFastTransactionBreakdownDetails(this.transactionToView, this.transDate, this._transType, this.approvalStatus)
      .subscribe((details: ParticipantData[]) => {
        this.participantDatalist = details;
        this.populateDeductionAtParticipantAndAcct();
        this.participantDatalistModified = JSON.parse(JSON.stringify(this.participantDatalist));
        this._dataReadytest = true;

      });
    this.getPropertyName();

    if (this.editFlag) this.columns.push(...['New Deductions', 'Input'])
  }

  getPropertyName() {
    if (this.transType == 'Distribution') {
      this.propertyName = "vestedCasBal"
    } else if (this.transType == 'Forfeiture') {
      this.propertyName = "unvestedCashBal"
    } else {
      this.propertyName = "endCashBal"
    }
  }

  populateZeroDeduction(arr, index) {
    if (index <= this.propertyMap.length) {
      for (var node of arr) {
        if (index == this.propertyMap.length) {
          node.interestAccrued = Math.random();
        }
        node.deduction = 0
        node.deductionWithInterest = 0
        node[this.propertyMap[index]] = this.populateZeroDeduction(node[this.propertyMap[index]], index + 1)
      }
    }
    return arr
  }

  populateDeductionAtParticipantAndAcct() {
    let totalDeductions = 0;
    this.participantDatalist.forEach(participant => {
      let totalDeductionAtParticipant = 0;
      participant.accountBalances.forEach(acctData => {
        let totalDeductionAtAccount = 0;
        for (let j = 0; j < acctData.moneyTypeBalances.length; j++) {
          // tslint:disable-next-line:no-unused-expression
          totalDeductionAtAccount += acctData.moneyTypeBalances[j].deduction;
        }
        acctData.deduction = totalDeductionAtAccount;
        acctData.deductionWithInterest = totalDeductionAtAccount;
        totalDeductionAtParticipant += acctData.deduction;
      });
      participant.deduction = totalDeductionAtParticipant;
      participant.deductionWithInterest = totalDeductionAtParticipant;
      totalDeductions += totalDeductionAtParticipant;
    });
    this.totalDeductionsAmt = totalDeductions;
    this.totalNewDeductionsAmt = totalDeductions;
  }

  showModalMessage() {
    if (this.showModal === undefined) {
      return;
    }
    if (this.showModal === 'quietRecord') {
      document.getElementById('hiddenButtonQuietRecord').click();
    } else if (this.showModal === 'quietRecordFailure') {
      document.getElementById('hiddenButtonQuietRecordFailure').click();
    }
    this.showModal = undefined;
  }

  approveQuietRecord() {
    this.approvalText = "Quiet record has been approved."
    this.showModal = "quietRecord";

    this.fastTransactionService.approveQuietRecord(this.transactionToView, this.transDate, this.companyId).subscribe(
      response => {
        this.showModalMessage();
      },
      error => {
        this.showModal = "quietRecordFailure"
        console.error(error);
        this.showModalMessage();
      }
    )
  }

  denyQuietRecord() {
    this.approvalText = "Quiet record has been denied."
    this.showModal = "quietRecord";

    this.fastTransactionService.denyQuietRecord(this.transactionToView, this.transDate, this.companyId).subscribe(
      response => {
        this.showModalMessage();
      },
      error => {
        this.showModal = "quietRecordFailure"
        console.error(error);
        this.showModalMessage();
      }
    )
  }

  exportexcel() {
    this.getPropertyName();

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    worksheet.getColumn(1).width = 110;
    const companyName = 'Company Name : ' + this.companyName;
    const transactionDate = 'Transaction Date : ' + this.datepipe.transform(this.transDate, 'MM/dd/yyyy');
    const transactionType = 'Transaction Type : ' + this._transType;

    const companyNameRow = worksheet.addRow([companyName]);
    companyNameRow.font = { size: 11, bold: true };

    const transactionDateRow = worksheet.addRow([transactionDate]);
    transactionDateRow.font = { size: 11, bold: true };

    const transactionTypeRow = worksheet.addRow([transactionType]);
    transactionTypeRow.font = { size: 11, bold: true };

    worksheet.addRow([]);
    /* let entryMethod = this.selectedEntryMethod == '$ Entry' ? "$" : "%";*/
    const header = ['Participant / Account / Money Type / Fund', '', 'Total Balance', 'Interest Accrued', 'Deduction'];
    /*(' + entryMethod + ")"];*/
    const headerRow = worksheet.addRow(header);

    headerRow.font = { size: 11, bold: true };

    worksheet.getColumn(3).width = 13;
    worksheet.getColumn(3).alignment = { horizontal: 'right' };
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(4).alignment = { horizontal: 'right' };
    worksheet.getColumn(5).width = 13;
    worksheet.getColumn(5).alignment = { horizontal: 'right' };


    this.participantDatalist.forEach(participantData => {
      worksheet.addRow([]);

      const participantLevelInterest: number = this.getInterestByParticipant(participantData);
      const participantLevelRow = [participantData.empName,
        '',
      (participantData[this.propertyName]).toString(),
      (Number(participantData.interestAccrued).toFixed(2)),
      ((Number(participantData.deduction) + participantLevelInterest).toFixed(2))];
      worksheet.addRow(participantLevelRow);

      participantData.accountBalances.forEach(accountData => {
        const accountLevelInterest: number = this.getInterestByAccount(accountData);
        const accountLevelRow = ['                   ' + accountData.planName + '[' + accountData.contractId + ']' +
          '-' + accountData.accountName + '[' + accountData.accountId + ']',
          '',
        (accountData[this.propertyName]).toString(),
        (Number(accountData.interestAccrued).toFixed(2)),
        ((Number(accountData.deduction) + accountLevelInterest).toFixed(2))];
        worksheet.addRow(accountLevelRow);

        accountData.moneyTypeBalances.forEach(moneyTypeData => {
          const fundLevelInterest: number = this.getInterestByMoneyType(moneyTypeData);
          const moneyTypeLevelRow = ['                             ' + moneyTypeData.moneyTypeName, '',
          (moneyTypeData[this.propertyName]).toString(), (Number(moneyTypeData.interestAccrued).toFixed(2)),
          ((Number(moneyTypeData.deduction) + fundLevelInterest).toFixed(2))];
          worksheet.addRow(moneyTypeLevelRow);

          let totalIncludedMoneyTypeInterest = 0;
          moneyTypeData.fundBalances.forEach(fundBalanceData => {
            const includeFundInterest = fundBalanceData.deduction === fundBalanceData.endCashBal;
            const fundBalanceLevelRow = ['                                         ' + fundBalanceData.fundName, '',
            (fundBalanceData[this.propertyName]).toString(), (Number(fundBalanceData.interestAccrued).toFixed(2)),
            includeFundInterest ?
              ((Number(fundBalanceData.deduction) + Number(fundBalanceData.interestAccrued)).toFixed(2)) :
              (Number(fundBalanceData.deduction).toFixed(2)).toString()
            ];
            if (includeFundInterest) {
              totalIncludedMoneyTypeInterest += Number(fundBalanceData.interestAccrued);
            }
            worksheet.addRow(fundBalanceLevelRow);
          });
        });
      });
    });

    workbook.xlsx.writeBuffer().then(data => {
      const fileFormat = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(fileFormat, this.companyName.trim() + ' ' + this.datepipe.transform(this.transDate, 'MM/dd/yyyy') + ' Transaction Sheet.xlsx');
    });
  }

  getInterestByParticipant(participantData: ParticipantData): number {
    let totalParticipantInterest = 0;
    participantData.accountBalances.forEach(accountData => {
      totalParticipantInterest += this.getInterestByAccount(accountData);
    });
    return totalParticipantInterest;
  }

  getInterestByAccount(accountData: AccountData): number {
    let totalAccountInterest = 0;
    accountData.moneyTypeBalances.forEach(moneyTypeData => {
      totalAccountInterest += this.getInterestByMoneyType(moneyTypeData);
    });
    return totalAccountInterest;
  }

  getInterestByMoneyType(moneyTypeData: MoneyType): number {
    let totalMoneyTypeInterest = 0;
    moneyTypeData.fundBalances.forEach(fundBalanceData => {
      totalMoneyTypeInterest += this.getInterestByFund(fundBalanceData);
    });
    return totalMoneyTypeInterest;
  }

  getInterestByFund(fundData: FundType): number {
    if (Number(fundData.endCashBal).toFixed(2) === Number(fundData.deduction).toFixed(2)) {
      return Number(fundData.interestAccrued);
    } else {
      return 0;
    }
  }

  changeDeductionInput(positionArray: number[], oldValue: number, input: number) {
    if (isNaN(input)) return
    const newValue = this.checkNewValue(positionArray, input);
    const change = -newValue - oldValue;
    this.totalNewDeductionsAmt += change;
    var datalist: ParticipantData | AccountData | MoneyType | FundType = this.participantDatalistModified[positionArray[0]]
    var i = 0
    for (; i < positionArray.length; i++) {
      if (i != 0) {
        datalist = datalist[this.propertyMap[i - 1]][positionArray[i]]
      }
      datalist.deduction += change
    }
    const percent = datalist[this.propertyName] == 0 ? 0 : -datalist.deduction / Number(datalist[this.propertyName])
    const subInterest = this.deductionByPercent(datalist[this.propertyMap[i - 1]], i, percent)
    datalist = this.participantDatalistModified[positionArray[0]]
    for (i = 0; i < positionArray.length; i++) {
      if (i != 0) {
        datalist = datalist[this.propertyMap[i - 1]][positionArray[i]]
      }
      datalist.deductionWithInterest = Number(datalist.deduction) + -Number(subInterest)
    }
  }

  checkNewValue(positionArray: number[], input: number) {
    let current = this.participantDatalistModified[positionArray[0]]
    for (let i = 1; i < positionArray.length; i++) {
      current = current[this.propertyMap[i - 1]][positionArray[i]]
    }
    return Math.max(0, Math.min(Number(current[this.propertyName]), input))
  }

  deductionByPercent(arr, index, percent) {
    if (index > this.propertyMap.length) return 0
    var totalInterest = 0
    for (var node of arr) {
      node.deduction = -percent * node[this.propertyName]
      var nodeInterest = 0
      if (index == this.propertyMap.length && Math.abs(Number(node.deduction.toFixed(2))) == Math.abs(Number(node.endCashBal.toFixed(2)))) {
        nodeInterest = node.interestAccrued
      } else {
        nodeInterest = this.deductionByPercent(node[this.propertyMap[index]], index + 1, percent)
      }
      totalInterest += nodeInterest
      node.deductionWithInterest = Number(node.deduction) + -Number(nodeInterest)
    }
    return totalInterest
  }

  filterFinalDeduction(arr, index) {
    var newArr = arr.filter(data => data.deduction != 0)
    for (var node of newArr) {
      node.deduction = Math.abs(node.deduction)
      node.deductionWithInterest = Math.abs(node.deductionWithInterest)
      if (this._transType === 'Distribution' || (this._transType === 'Forfeiture')) {

        if (this.propertyName == 'vestedCasBal' && !node.deductionWithInterest) {
          node.deductionWithInterest = node.deduction
        }
        if (this.propertyName == 'unvestedCashBal' && !node.deductionWithInterest) {
          node.deductionWithInterest = null
        }
        if (this.propertyName == 'endCashBal') {
          node.deductionWithInterest = null
        }
      }
      if (index < this.propertyMap.length) {
        node[this.propertyMap[index]] = this.filterFinalDeduction(node[this.propertyMap[index]], index + 1)
      }
    }
    return newArr
  }

  submitUpdate() {
    this._dataReadytest = false
    this.participantDatalistModified = this.filterFinalDeduction(this.participantDatalistModified, 0)
    this._dataReadytest = true
    const identical = JSON.stringify(this.participantDatalist) === JSON.stringify(this.participantDatalistModified)
    this.submitUpdateTransaction.emit({ data: this.participantDatalistModified, flag: identical })
  }
}

